import { Grid, Typography, Button, Stack, Switch, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import AppBar from "../components/AppBar";
import { theme } from "../global/theme";
import AuthModal from "../components/modals/AuthModal";
import img from "../assets/vizz_pic.png";
import { styled } from "@mui/material/styles";
import SubscriptionCard from "../components/SubscriptionCard";
import SubModal from "../components/modals/SubModal";

const LandingPage = ({
  navigate,
  userData,
  open,
  setOpen,
  subModalOpen,
  setSubModalOpen,
}) => {
  useEffect(() => {
    if (userData) {
      console.log(userData);
      navigate("/dashboard");
      setOpen(false);
    }
  }, [userData]);

  const [annualPlan, setAnnualPlan] = useState(true);

  return (
    <Grid
      item
      container
      xs={12}
      style={{ position: "relative", height: "100vh" }}
    >
      <Grid
        item
        container
        xs={12}
        style={{ height: "90vh", padding: "0px 10%" }}
      >
        <Grid
          item
          container
          xs={12}
          style={{
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexFlow: "column",
              width: "100%",
              height: "fit-content",
              marginTop: "8%",
              containerType: "inline-size",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h1"
              style={{
                fontWeight: "bold",
                textAlign: "center",
                width: "100%",
                fontSize: "8cqw",
              }}
            >
              Data Visualization{" "}
              <span
                style={{
                  background: `-webkit-linear-gradient(52deg, ${theme.primary} 0%, ${theme.accentOne} 20%, ${theme.primary} 43%, ${theme.secondary} 63%, ${theme.primary} 100%)`,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Simplified.
              </span>
            </Typography>
            <Typography
              style={{
                textAlign: "center",
                width: "60%",
                fontSize: "1.5cqw",
              }}
            >
              The art of representing information in a way that is easy to
              understand and interpret. Communicating data, helping you business
              discover how to leverage data to make informed decisions to drive
              growth.
            </Typography>
            <Button
              onClick={() => setOpen(true)}
              variant="contained"
              style={{
                width: "15%",
                height: 70,
                borderRadius: 60,
                backgroundColor: theme.primary,
                color: theme.white,
                marginTop: "5%",
                fontWeight: "bold",
                fontSize: "1.25cqw",
              }}
            >
              Get Started
            </Button>
          </div>
        </Grid>
        {/* {Seciton Two} */}
        <Grid
          item
          container
          xs={12}
          style={{ minHeight: "70vh", justifyContent: "center" }}
        >
          <img
            src={img}
            alt=""
            style={{
              width: "auto",
              height: "80%",
            }}
          />
        </Grid>

        {/* {Seciton three} */}
        <Grid
          item
          container
          xs={12}
          style={{
            position: "relative",
            minHeight: "65vh",
            alignItems: "baseline",
            containerType: "inline-size",
            padding: "5%",
            justifyContent: "space-evenly",
          }}
        >
          <Grid
            item
            container
            xs={12}
            md={8}
            style={{
              textAlign: "center",
              justifyContent: "center",
              padding: "0% 0px 5% 0px",
            }}
          >
            <Typography
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: "2.5cqw",
                fontWeight: "bold",
                color: theme.accentOne,
                marginBottom: 20,
              }}
            >
              Vizz is your all-in-one solution for transforming raw data into
              actionable insights.
            </Typography>
            <Typography
              style={{ width: "100%", textAlign: "center", fontSize: "1.5cqw" }}
            >
              With Vizz, you can upload raw data in various formats, select and
              compare key metrics, and create stunning visualizations in just a
              few clicks.
            </Typography>
          </Grid>

          <Grid
            item
            container
            xs={12}
            md={5}
            style={{
              justifyContent: "center",
              borderRadius: 15,
              boxShadow: "2px 2px 16px grey",
              overflow: "hidden",
              marginBottom: 20,
            }}
          >
            <Grid
              item
              container
              xs={12}
              style={{ justifyContent: "center", flexFlow: "column" }}
            >
              <Grid item container xs={12}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vizz-454f6.appspot.com/o/companyFiles%2FEasy%20Data%20Upload.png?alt=media&token=b1365758-e017-40e2-82d7-2b3ef9125c98"
                  alt=""
                  style={{ width: "100%", height: "auto" }}
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                style={{ padding: "2% 5%", containerType: "inline-size" }}
              >
                <Typography
                  style={{
                    width: "100%",
                    fontSize: "5cqw",
                    fontWeight: "bold",
                  }}
                >
                  Easy Data Upload
                </Typography>
                <Typography style={{ width: "100%", fontSize: "3.5cqw" }}>
                  Upload raw data effortlessly in the form of CSV or XLXS files.
                  Easily fix errors without mutating the original data.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            md={5}
            style={{
              justifyContent: "center",
              borderRadius: 15,
              boxShadow: "2px 2px 16px grey",
              overflow: "hidden",
              marginBottom: 20,
            }}
          >
            <Grid
              item
              container
              xs={12}
              style={{ justifyContent: "center", flexFlow: "column" }}
            >
              <Grid item container xs={12}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vizz-454f6.appspot.com/o/companyFiles%2FCustom%20Presets.png?alt=media&token=6fdc69d9-d44d-4e05-82ab-edfb3dccc084"
                  alt=""
                  style={{ width: "100%", height: "auto" }}
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                style={{ padding: "2% 5%", containerType: "inline-size" }}
              >
                <Typography
                  style={{
                    width: "100%",
                    fontSize: "5cqw",
                    fontWeight: "bold",
                  }}
                >
                  Custom Presets
                </Typography>
                <Typography style={{ width: "100%", fontSize: "3.5cqw" }}>
                  Save your favorite metrics configurations for quick access and
                  analysis.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            md={5}
            style={{
              justifyContent: "center",
              borderRadius: 15,
              boxShadow: "2px 2px 16px grey",
              overflow: "hidden",
              marginBottom: 20,
            }}
          >
            <Grid
              item
              container
              xs={12}
              style={{ justifyContent: "center", flexFlow: "column" }}
            >
              <Grid item container xs={12}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vizz-454f6.appspot.com/o/companyFiles%2FSeamless%20Collaboration.png?alt=media&token=b1334f88-a28b-4e81-b9c0-07cbe31498b9"
                  alt=""
                  style={{ width: "100%", height: "auto" }}
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                style={{ padding: "2% 5%", containerType: "inline-size" }}
              >
                <Typography
                  style={{
                    width: "100%",
                    fontSize: "5cqw",
                    fontWeight: "bold",
                  }}
                >
                  Seamless Collaboration
                </Typography>
                <Typography style={{ width: "100%", fontSize: "3.5cqw" }}>
                  Share visualizations with team members and collaborate in
                  real-time.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            md={5}
            style={{
              justifyContent: "center",
              borderRadius: 15,
              boxShadow: "2px 2px 16px grey",
              overflow: "hidden",
              marginBottom: 20,
            }}
          >
            <Grid
              item
              container
              xs={12}
              style={{ justifyContent: "center", flexFlow: "column" }}
            >
              <Grid item container xs={12}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vizz-454f6.appspot.com/o/companyFiles%2FExport%20and%20Share.png?alt=media&token=5cb8221c-2c63-4d85-ba3d-1efe6f126565"
                  alt=""
                  style={{ width: "100%", height: "auto" }}
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                style={{ padding: "2% 5%", containerType: "inline-size" }}
              >
                <Typography
                  style={{
                    width: "100%",
                    fontSize: "5cqw",
                    fontWeight: "bold",
                  }}
                >
                  Export & Share
                </Typography>
                <Typography style={{ width: "100%", fontSize: "3.5cqw" }}>
                  Export visualizations and share them with stakeholders for
                  enhanced decision-making.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* {Seciton four} */}
        {/* <Grid
          item
          container
          xs={12}
          style={{
            position: "relative",
            minHeight: "100vh",
            background: theme.primary,
          }}
        ></Grid> */}
        <Grid
          item
          container
          xs={12}
          style={{
            position: "relative",
            minHeight: "30vh",
            background: theme.black,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            container
            xs={12}
            md={6}
            style={{
              justifyContent: "space-evenly",
              containerType: "inline-size",
              height: "fit-content",
            }}
          >
            <Typography
              style={{ fontSize: "3.5cqw", color: "white", fontWeight: "bold" }}
            >
              Ready to Get Started?
            </Typography>
            <Typography style={{ fontSize: "2.5cqw", color: "white" }}>
              Leverage the power of Vizz to unlock the potential of your data.
              Sign up for a free trial today and experience the difference Vizz
              can make for your organization.
            </Typography>
            <Button
              variant="contained"
              style={{
                marginTop: 20,
                height: 70,
                borderRadius: 60,
                backgroundColor: theme.primary,
                color: theme.white,
              }}
              onClick={() => setOpen(true)}
            >
              Get Started
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <SubModal
        subModalOpen={subModalOpen}
        setSubModalOpen={setSubModalOpen}
        authModelOpen={open}
        setAuthModalOpen={setOpen}
      />
    </Grid>
  );
};

export default LandingPage;
