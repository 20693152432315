import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/storage'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'


const firebaseConfig = {
    apiKey: "AIzaSyB3BraBhCN1s9tYNZj5kBtRtRl0NK2VgH8",
    authDomain: "vizz-454f6.firebaseapp.com",
    projectId: "vizz-454f6",
    storageBucket: "vizz-454f6.appspot.com",
    messagingSenderId: "339579220724",
    appId: "1:339579220724:web:95d95e6021c755968864a1",
    measurementId: "G-PT1QQ5RR4J"
};

let app;


if (firebase.apps.length === 0) {
    app = firebase.initializeApp(firebaseConfig)
} else {
    app = firebase.app()
}


const db = app.firestore()
const auth = firebase.auth()
const storage = firebase.storage()
const functions = firebase.functions()


export { db, auth, storage, functions }
export default firebase
