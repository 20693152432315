const authTypes = {
  //User
  SET_CURRENT_USER: "SET_CURRENT_USER",
  CLEAR_CURRENT_USER: "CLEAR_CURRENT_USER",
  SET_USER_DATA: "SET_USER_DATA",
  SET_USER_DATASETS: "SET_USER_DATASETS",
  SET_USER_PRESETS: "SET_USER_PRESETS",
};

export default authTypes;
