import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import MetricContainer from "../components/MetricContainer";
import { theme as defaultTheme } from "../global/theme";
import { companyDepartments } from "../global/sampleData";
import {
  Add,
  Addchart,
  Close,
  CloudDone,
  CloudDownload,
  HelpOutline,
  IosShare,
  KeyboardArrowRight,
  NoteAdd,
  PictureAsPdf,
  QuestionMarkOutlined,
  QuestionMarkRounded,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { setUserData, setUserPresets } from "../global/authActions";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import firebase, { db, storage } from "../config/firebaseConfig";
import {
  calculateAverage,
  calculateMedian,
  calculatePercentiles,
  calculateRanges,
  calculateSum,
  ordinalWords,
} from "../Utils";
import SubModal from "../components/modals/SubModal";
import SettingsModal from "../components/modals/SettingsModal";
import CustomBarChart from "../components/CustomBarChart";

const Dashboard = ({
  userData,
  navigate,
  subModalOpen,
  setSubModalOpen,
  open,
  setOpen,
  settingsModalOpen,
  setSettingsModalOpen,
}) => {
  const sheetRef = useRef(null);
  const [wrapperHeight, setWrapperHeight] = useState(0);
  const [wrapperWidth, setWrapperWidth] = useState(0);

  const theme = userData?.theme ? userData.theme : defaultTheme;
  const dispatch = useDispatch();
  const userDataSets = useSelector((state) => state.auth.userDataSets);
  const userPresets = useSelector((state) => state.auth.userPresets);

  const [companyName, setCompanyName] = useState("");
  const [department, setDepartment] = useState("");
  const [availTitles, setAvailTitles] = useState([]);
  const fileInputRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [primaryColor, setPrimaryColor] = useState(theme.primary);
  const [secondaryColor, setSecondaryColor] = useState(theme.secondary);
  const [accentColor, setAccentColor] = useState(theme.accentOne);
  const [departmentOther, setDepartmentOther] = useState(false);
  const [title, setTitle] = useState("");
  const [titleOther, setTitleOther] = useState(false);
  const [addMetricModal, setAddMetricModal] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);

  const [selectedDataSet, setSelectedDataSet] = useState(null);
  const [selectedDataSetData, setSelectedDataSetData] = useState(null);

  const [showAddDataModal, setShowAddDataModal] = useState(false);
  const [showAddPresetModal, setShowAddPresetModal] = useState(false);
  const [newPresetName, setNewPresetName] = useState("");
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [dataArr, setDataArr] = useState([]);
  const [metrics, setMetrics] = useState([]);

  const [selectedMetric, setSelectedMetric] = useState(null);
  const [selectedWithinMetric, setSelectedWithinMetric] = useState(null);
  const [selectedByMetric, setSelectedByMetric] = useState(null);
  const [numericalCalculation, setNumericalCalculation] = useState("");
  const [loading, setLoading] = useState(false);

  const availDepartments = companyDepartments.map((cd) => cd.department);
  //   let availPositions =
  //     availDepartments.find((dept) => dept.department === department)?.titles ||
  //     companyDepartments.map((cd) => cd.titles.map((title) => title));

  console.log("USER PRESETS, DATASETS", userPresets, userDataSets);

  useEffect(() => {
    const resizeHandler = () => {
      const parentWidth = sheetRef.current.parentNode.offsetWidth;
      const parentHeight = sheetRef.current.parentNode.offsetHeight;
      const aspectRatio = 11.5 / 8.5; // 11 X 8.5 aspect ratio

      // Calculate width based on height and aspect ratio
      const calculatedWidth = parentHeight * aspectRatio;

      // If calculatedWidth is greater than parentWidth, adjust width and height based on width
      if (calculatedWidth > parentWidth) {
        setWrapperWidth(parentWidth);
        setWrapperHeight(parentWidth / aspectRatio);
      } else {
        setWrapperWidth(calculatedWidth);
        setWrapperHeight(parentHeight);
      }
    };

    resizeHandler(); // Call it initially
    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  useEffect(() => {
    if (userData?.logo?.length !== undefined && !userData?.subscriptionTier) {
      setSubModalOpen(true);
    }
  }, [userData]);

  useEffect(() => {
    if (department.length > 3) {
      let availableTitles = companyDepartments.find(
        (cd) => cd.department === department
      )?.titles;
      if (availableTitles) {
        setAvailTitles(availableTitles);
      } else {
        setAvailTitles(["Other"]);
      }
    } else {
      setAvailTitles([]);
    }
  }, [department]);

  useEffect(() => {
    if (userDataSets && userDataSets.length > 0) {
      console.log("user data sets", userDataSets);
      //   setSelectedDataSet(userDataSets[0]);
    }
  }, [userDataSets]);

  useEffect(() => {
    console.log("Selected data set", selectedDataSet);
    if (selectedDataSet) {
      setMetrics(selectedDataSet.metrics);
    }
    if (selectedDataSet) {
      console.log("Selected data set FILES", selectedDataSet);
      db.collection("users")
        .doc(userData.uid)
        .collection("DataSets")
        .doc(selectedDataSet.name)
        .collection("files")
        .orderBy("uploadedAt", "desc")
        .limit(1)
        .get()
        .then(async (querySnapshot) => {
          if (querySnapshot.empty) {
            console.log("No files found");
          } else {
            let document = await querySnapshot.docs.map((doc) => {
              let data = doc.data();
              console.log(doc.id, data);
              return { ...data, id: doc.id };
            })[0];
            console.log(document);
            setSelectedDataSetData({
              rows: document.rows,
              headers: document.headers,
            });
          }
        });
    } else {
      // setPreviewCSVData("");
    }
  }, [selectedDataSet]);

  useEffect(() => {
    if (selectedPreset) {
      setDataArr(selectedPreset.selectedMetrics);
    }
  }, [selectedPreset]);

  useEffect(() => {
    console.log("DataArr", dataArr);
    if (dataArr) {
    }
  }, [dataArr]);

  const handleImageSelect = async (e) => {
    console.log("this is the e", e);
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      //set file to state for upload
      setImageFile(file);
      const reader = await new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setImageSrc(reader.result);
      };
    }
  };

  const handleAddWorkInfo = () => {
    setLoading(true);
    if ((!userData.companyName, !userData.department, !userData.title)) {
      db.collection("users")
        .doc(userData.uid)
        .set({ companyName, department, title }, { merge: true })
        .then(() => {
          console.log("Document successfully written!");
          db.collection("users")
            .doc(userData.uid)
            .get()
            .then((doc) => {
              let data = doc.data();
              dispatch(setUserData(data));
            })
            .then(() => {
              setLoading(false);
            })
            .then(() => {
              navigate("/dashboard");
              setSubModalOpen(!subModalOpen);
            });
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    } else {
      if (!imageFile) {
        db.collection("users")
          .doc(userData.uid)
          .set(
            {
              logo: "",
              theme: {
                primary: primaryColor,
                secondary: secondaryColor,
                accentOne: accentColor,
                success: theme.success,
                info: theme.info,
                warning: theme.warning,
                danger: theme.danger,
                black: theme.black,
                white: theme.white,
              },
            },
            { merge: true }
          )
          .then(() => {
            setLoading(false);
          })
          .then(() => {
            db.collection("users")
              .doc(userData.uid)
              .get()
              .then((doc) => {
                let data = doc.data();
                dispatch(setUserData(data));
              })
              .then(() => {
                console.log("Document successfully written!");
                navigate("/dashboard");
                setSubModalOpen(!subModalOpen);
              });
          });
      } else {
        //Upload imageFile to storage, then get the downloadUrl
        console.log("Image File", imageFile);
        let imagePath = storage.ref(
          `users/${userData.uid}/images/${imageFile.name}`
        );
        let uploadTask = imagePath.put(imageFile);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGE, (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          console.log("Upload is " + progress + "% done");
        });
        uploadTask
          .then((snapshot) => {
            console.log("Uploaded a file! Image URL will be here.");
            return imagePath.getDownloadURL();
          })
          .then((downloadURL) => {
            db.collection("users")
              .doc(userData.uid)
              .set(
                {
                  logo: downloadURL,
                  theme: {
                    primary: primaryColor,
                    secondary: secondaryColor,
                    accentOne: accentColor,
                    success: theme.success,
                    info: theme.info,
                    warning: theme.warning,
                    danger: theme.danger,
                    black: theme.black,
                    white: theme.white,
                  },
                },
                { merge: true }
              )
              .then(() => {
                setLoading(false);
              })
              .then(() => {
                db.collection("users")
                  .doc(userData.uid)
                  .get()
                  .then((doc) => {
                    let data = doc.data();
                    dispatch(setUserData(data));
                  })
                  .then(() => {
                    console.log("Document successfully written!");
                    navigate("/dashboard");
                    setSubModalOpen(!subModalOpen);
                  });
              });
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
      }
    }
  };

  function calculate(array, calculator, comparedBy) {
    let comparedWithin = selectedWithinMetric != null ? true : false;
    switch (calculator) {
      case "Average":
        let average = calculateAverage(array, comparedWithin, comparedBy);
        console.log("Average", average);
        debugger;
        return average;
      case "Median":
        let median = calculateMedian(array, comparedWithin, comparedBy);
        console.log("Median", median);
        debugger;
        return median;
      case "Percentiles":
        let percentiles = calculatePercentiles(
          array,
          comparedWithin,
          comparedBy
        );
        console.log("Percentiles", percentiles);
        debugger;
        return percentiles;
      case "Ranges":
        let ranges = calculateRanges(array, comparedWithin, comparedBy);
        console.log("Ranges", ranges);
        debugger;
        return ranges;
      case "Total":
        let total = calculateSum(array, comparedWithin, comparedBy);
        console.log("Total", total);
        debugger;
        return total;
      // case "Standard Deviation":
      // 	return calculateStandardDeviation(array);
      default:
        return "Invalid calculator type";
    }
  }

  const addMetricVisualization = () => {
    //! Should set the data and also the type i.e standard, within, calculation, calculationWithin, etc.
    //Check to see if there is a selected metric
    if (selectedMetric) {
      if (selectedMetric.type === "Number") {
        if (selectedWithinMetric) {
          if (numericalCalculation) {
            //Loop through rows.
            const rows = [...selectedDataSetData.rows];
            let values = [];
            for (let i = 0; i < rows.length; i++) {
              let row = rows[i];

              values.push({
                value: row[selectedMetric.field],
                amt: 1,
                camparedWithin: row[selectedWithinMetric.field],
              });
            }

            let finalResult = calculate(values, numericalCalculation); //! Put this back in...
            // console.log("DEBUGGER FINAL RESULT", finalResult);
            console.log("DEBUGGER VALUES", values);

            if (numericalCalculation === "Total") {
              //   let finalResult = calculate(values, numericalCalculation);
              console.log(finalResult);
              setDataArr((prev) => [
                ...prev,
                {
                  metric: {
                    ...selectedMetric,
                    comparedWithin: true,
                    calculationMethod: numericalCalculation,
                    isCalculation: true,
                    secondMetric: selectedWithinMetric.headerName,
                  },
                  values: finalResult.map((val) => ({
                    value:
                      val.comparedWithin === undefined ||
                      val.comparedWithin === null ||
                      val.comparedWithin === ""
                        ? "N/A"
                        : val.comparedWithin,
                    amt: val.value,
                  })),
                },
              ]);
            } else if (numericalCalculation === "Average") {
              //   let finalResult = calculate(values, numericalCalculation);
              setDataArr((prev) => [
                ...prev,
                {
                  metric: {
                    ...selectedMetric,
                    comparedWithin: true,
                    calculationMethod: numericalCalculation,
                    isCalculation: true,
                    secondMetric: selectedWithinMetric.headerName,
                  },
                  values: finalResult.map((val) => ({
                    value:
                      val.value === undefined ||
                      val.value === null ||
                      val.value === ""
                        ? "N/A"
                        : val.value,
                    amt: val.amt,
                  })),
                },
              ]);
            } else if (numericalCalculation === "Median") {
              //   let finalResult = calculate(values, numericalCalculation);
              setDataArr((prev) => [
                ...prev,
                {
                  metric: {
                    ...selectedMetric,
                    comparedWithin: true,
                    calculationMethod: numericalCalculation,
                    isCalculation: true,
                    secondMetric: selectedWithinMetric.headerName,
                  },
                  values: finalResult.map((val) => ({
                    value:
                      val.comparedWithin === undefined ||
                      val.comparedWithin === null ||
                      val.comparedWithin === ""
                        ? "N/A"
                        : val.comparedWithin,
                    amt: val.value,
                  })),
                },
              ]);
            } else if (numericalCalculation === "Percentiles") {
              setDataArr((prev) => [
                ...prev,
                {
                  metric: {
                    ...selectedMetric,
                    comparedWithin: true,
                    calculationMethod: numericalCalculation,
                    isCalculation: true,
                    secondMetric: selectedWithinMetric.headerName,
                  },
                  values: finalResult.map((val) => ({
                    ...val,
                    value:
                      val.value === undefined ||
                      val.value === null ||
                      val.value === ""
                        ? "N/A"
                        : val.value,
                    amt: val.amt,
                  })),
                },
              ]);
            } else {
              for (let i = 0; i < values.length; i++) {
                let element = values[i];
                element.camparedWithin = [];
              }

              //Loop over selectedWthinMetric to find the index of the selectedMetric in that list.
              for (let idx = 0; idx < values.length; idx++) {
                let currentValue = values[idx];
                let indecies = currentValue.index;

                for (let jdx = 0; jdx < indecies.length; jdx++) {
                  let index = indecies[jdx];
                  values[idx].camparedWithin.push(
                    rows[index][selectedWithinMetric.field]
                  );
                }
              }
              console.log("THE VALUES", values);
              let result = calculate(values, numericalCalculation, true);
              console.log("THE RESULT", result);
              setDataArr((prev) => [
                ...prev,
                {
                  metric: {
                    ...selectedMetric,
                    comparedWithin: true,
                    calculationMethod: numericalCalculation,
                    isCalculation: true,
                    secondMetric: selectedWithinMetric.headerName,
                  },
                  values: result.map((val) => ({
                    ...val,
                    value:
                      val.value === undefined ||
                      val.value === null ||
                      val.value === ""
                        ? "N/A"
                        : val.value,
                  })),
                },
              ]);
            }
          } else {
            //Loop through rows.
            const rows = [...selectedDataSetData.rows];
            let values = [];
            for (let i = 0; i < rows.length; i++) {
              let row = rows[i];
              if (
                !values.find(
                  (value) => value.value === row[selectedMetric.field]
                )
              ) {
                values.push({
                  value: row[selectedMetric.field],
                  amt: 1,
                  index: [i],
                });
              } else {
                let index = values.findIndex(
                  (value) => value.value === row[selectedMetric.field]
                );
                values[index].amt += 1;
                values[index].index.push(i);
              }
            }

            for (let i = 0; i < values.length; i++) {
              let element = values[i];
              element.camparedWithin = [];
            }

            //Loop over selectedWthinMetric to find the index of the selectedMetric in that list.
            for (let idx = 0; idx < values.length; idx++) {
              let currentValue = values[idx];
              let indecies = currentValue.index;

              for (let jdx = 0; jdx < indecies.length; jdx++) {
                let index = indecies[jdx];
                values[idx].camparedWithin.push(
                  rows[index][selectedWithinMetric.field]
                );
              }
            }

            console.log("THE VALUES", values);
            setDataArr((prev) => [
              ...prev,
              {
                metric: {
                  ...selectedMetric,
                  comparedWithin: true,
                  secondMetric: selectedWithinMetric.headerName,
                },
                values: values.map((val) => ({
                  ...val,
                  value:
                    val.value === undefined ||
                    val.value === null ||
                    val.value === ""
                      ? "N/A"
                      : val.value,
                })),
              },
            ]);
          }
        } else {
          if (numericalCalculation) {
            //!=============================================
            const rows = [...selectedDataSetData.rows];
            console.log("Array length before function", rows.length);
            let values = [];
            for (let i = 0; i < rows.length; i++) {
              let row = rows[i];
              if (numericalCalculation === "Total") {
                values.push({ value: row[selectedMetric.field], amt: 1 });
              } else {
                if (
                  !values.find(
                    (value) => value.value === row[selectedMetric.field]
                  )
                ) {
                  values.push({ value: row[selectedMetric.field], amt: 1 });
                } else {
                  let index = values.findIndex(
                    (value) => value.value === row[selectedMetric.field]
                  );
                  values[index].amt += 1;
                }
              }
            }
            console.log("THE VALUES", values);
            let result = calculate(values, numericalCalculation, false);
            console.log("THE RESULT", result);
            setDataArr((prev) => [
              ...prev,
              {
                metric: {
                  ...selectedMetric,
                  comparedWithin: false,
                  calculationMethod: numericalCalculation,
                  isCalculation: true,
                },
                values: result.map((val) => ({
                  ...val,
                  value:
                    val.value === undefined ||
                    val.value === null ||
                    val.value === ""
                      ? "N/A"
                      : val.value,
                })),
              },
            ]);
            //!=============================================
          } else {
            const rows = [...selectedDataSetData.rows];
            let values = [];
            for (let i = 0; i < rows.length; i++) {
              let row = rows[i];
              if (
                !values.find(
                  (value) => value.value === row[selectedMetric.field]
                )
              ) {
                values.push({ value: row[selectedMetric.field], amt: 1 });
              } else {
                let index = values.findIndex(
                  (value) => value.value === row[selectedMetric.field]
                );
                values[index].amt += 1;
              }
            }
            console.log("THE VALUES", values);
            setDataArr((prev) => [
              ...prev,
              {
                metric: selectedMetric,
                values: values.map((val) => ({
                  ...val,
                  value:
                    val.value === undefined ||
                    val.value === null ||
                    val.value === ""
                      ? "N/A"
                      : val.value,
                })),
              },
            ]);
          }
        }
      } else {
        if (selectedWithinMetric) {
          //Loop through rows.
          const rows = [...selectedDataSetData.rows];
          let values = [];
          for (let i = 0; i < rows.length; i++) {
            let row = rows[i];
            if (
              !values.find((value) => value.value === row[selectedMetric.field])
            ) {
              values.push({
                value: row[selectedMetric.field],
                amt: 1,
                index: [i],
              });
            } else {
              let index = values.findIndex(
                (value) => value.value === row[selectedMetric.field]
              );
              values[index].amt += 1;
              values[index].index.push(i);
            }
          }

          for (let i = 0; i < values.length; i++) {
            let element = values[i];
            element.camparedWithin = [];
          }

          //Loop over selectedWthinMetric to find the index of the selectedMetric in that list.
          for (let idx = 0; idx < values.length; idx++) {
            let currentValue = values[idx];
            let indecies = currentValue.index;

            for (let jdx = 0; jdx < indecies.length; jdx++) {
              let index = indecies[jdx];
              values[idx].camparedWithin.push(
                rows[index][selectedWithinMetric.field]
              );
            }
          }

          console.log("THE VALUES", values);
          setDataArr((prev) => [
            ...prev,
            {
              metric: {
                ...selectedMetric,
                comparedWithin: true,
                secondMetric: selectedWithinMetric.headerName,
              },
              values: values.map((val) => ({
                ...val,
                value:
                  val.value === undefined ||
                  val.value === null ||
                  val.value === ""
                    ? "N/A"
                    : val.value,
              })),
            },
          ]);
        } else {
          //Loop through rows.
          const rows = [...selectedDataSetData.rows];
          let values = [];
          for (let i = 0; i < rows.length; i++) {
            let row = rows[i];

            //for every unique value, at rows[i][values[0]] add it to values array as a key, vith a value in the amt found
            //if alread found in values, add to the value
            if (
              !values.find((value) => value.value === row[selectedMetric.field])
            ) {
              values.push({ value: row[selectedMetric.field], amt: 1 });
            } else {
              let index = values.findIndex(
                (value) => value.value === row[selectedMetric.field]
              );
              values[index].amt += 1;
            }
          }
          console.log("THE VALUES", values);
          setDataArr((prev) => [
            ...prev,
            {
              metric: selectedMetric,
              values: values.map((val) => ({
                ...val,
                value:
                  val.value === undefined ||
                  val.value === null ||
                  val.value === ""
                    ? "N/A"
                    : val.value,
              })),
            },
          ]);
        }
        // alert("Please add a metric to visualize");
      }
    }

    setSelectedMetric(null);
    setSelectedWithinMetric(null);
    setNumericalCalculation("");
    setAddMetricModal(false);
  };

  const handleCreatePdf = () => {
    if (sheetRef.current) {
      const logoImg = new Image();
      logoImg.src = userData.logo;
      logoImg.onload = () => {
        html2canvas(sheetRef.current, { scale: 2 }).then((canvas) => {
          // Adjust the scale as needed
          const imgData = canvas.toDataURL("image/png");
          // Create a new jsPDF instance with letter size and landscape orientation
          const doc = new jsPDF({
            orientation: "landscape",
            unit: "in", // You can use 'pt' for points or 'mm' for millimeters
            format: "letter", // This sets the format to letter size
          });

          // Calculate the dimensions of the image and the PDF page
          const imgWidth = canvas.width;
          const imgHeight = canvas.height;
          const pdfWidth = doc.internal.pageSize.getWidth();
          const pdfHeight = doc.internal.pageSize.getHeight();

          // Calculate the ratio to fit the image inside the PDF page
          let ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

          // Calculate the dimensions to maintain aspect ratio
          const newWidth = imgWidth * ratio;
          const newHeight = imgHeight * ratio;

          // Calculate position to center the image on the page
          const xOffset = (pdfWidth - newWidth) / 2;
          const yOffset = (pdfHeight - newHeight) / 2;

          // Add the image to the PDF with scaling to fit
          doc.addImage(imgData, "PNG", xOffset, yOffset, newWidth, newHeight);

          // Save the PDF
          doc.save(
            `${userData.companyName}_${selectedDataSet?.name}_${
              selectedPreset?.name
            }_${new Date().toLocaleDateString()}.pdf`
          );
        });
      };
      logoImg.onerror = (error) => {
        console.error("Error loading logo image:", error);
      };
    } else {
      console.error("The element to capture was not found");
    }
  };

  const handleSavePreset = () => {
    if (selectedDataSetData && dataArr.length > 0) {
      const preset = {
        name: newPresetName,
        dataSet: selectedDataSet.name,
        // data: selectedDataSetData,
        // numericalCalculation: numericalCalculation,
        selectedMetrics: dataArr,
        createdAt: new Date(),
      };
      console.log(preset, userData);
      db.collection("users")
        .doc(userData.uid)
        .collection("Presets")
        .doc(newPresetName)
        .set(preset)
        .then(() => {
          db.collection("users")
            .doc(userData.uid)
            .collection("Presets")
            .get()
            .then((querySnapshot) => {
              const presets = querySnapshot.docs.map((doc) => {
                return {
                  ...doc.data(),
                  id: doc.id,
                };
              });
              dispatch(setUserPresets(presets));
            })
            .then(() => {
              setShowAddPresetModal(false);
              setNewPresetName("");
            });
        });
    } else {
      alert("Please select a dataset to save");
    }
  };

  const handleChangeDataSet = (e) => {
    setDataArr([]);
    setSelectedPreset(null);
    setSelectedMetric(null);

    const available = [
      ...userPresets.filter(
        (preset) => preset.dataSet.trim() === e.target.value
      ),
    ];
    console.log(
      "THIS IS THE SELECTED DATASET",
      userDataSets.find((dset) => dset.name === e.target.value)
    );
    console.log(
      `THIS IS THE AVAILABLE PRESETS for => ${e.target.value}`,
      userPresets,
      available
    );
    setSelectedDataSet(
      userDataSets.find((dset) => dset.name === e.target.value)
    );
  };

  const handleClearSheet = () => {
    setSelectedDataSet(null);
    setSelectedPreset(null);
    setSelectedMetric(null);
    setSelectedWithinMetric(null);
    setSelectedByMetric(null);
    setMetrics([]);
    setDataArr([]);
  };

  let date = new Date().toDateString();

  return (
    <Container style={{ maxWidth: "100vw" }}>
      <Grid
        item
        container
        xs={12}
        style={{
          height: "calc(100vh - 96px)",
        }}
      >
        <Grid
          item
          container
          xs={12}
          style={{
            height: "100%",
            justifyContent: "center",
            marginTop: "10px",
            position: "relative",
            padding: "0 10%",
          }}
        >
          <div
            div
            ref={sheetRef}
            style={{ height: "100%", position: "relative" }}
          >
            <div style={{ height: wrapperHeight, width: wrapperWidth }}>
              <Grid
                // ref={sheetRef}
                item
                container
                style={{
                  height: "100%",
                  border: "1px solid lightgrey",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    height: "fit-content",
                    width: "50px",
                    boxShadow: "2px 2px 6px lightgrey",
                    position: "absolute",
                    right: -60,
                    top: 0,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    borderRadius: 50,
                    zIndex: 10,
                    padding: "10px 0",
                  }}
                >
                  {!selectedDataSet ? (
                    <Tooltip title="Add Data" arrow placement="left">
                      <motion.div
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <IconButton
                          style={{
                            background: theme.secondary,
                            color: theme.primary,
                          }}
                          onClick={() => setShowAddDataModal(true)}
                        >
                          <Addchart />
                        </IconButton>
                      </motion.div>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Clear Sheet & Data" arrow placement="left">
                      <motion.div
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <IconButton
                          style={{
                            background: `${theme.danger}35`,
                            color: theme.danger,
                          }}
                          onClick={handleClearSheet}
                        >
                          <Close />
                        </IconButton>
                      </motion.div>
                    </Tooltip>
                  )}
                  <Tooltip title="Add Metric" arrow placement="left">
                    <motion.div
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <IconButton
                        style={{
                          marginTop: 10,
                          background: selectedDataSet && theme.secondary,
                          color: selectedDataSet && theme.primary,
                        }}
                        onClick={() => setAddMetricModal(true)}
                        disabled={!selectedDataSet}
                      >
                        <Add />
                      </IconButton>
                    </motion.div>
                  </Tooltip>
                  <Tooltip title="Save Data As Preset" arrow placement="left">
                    <motion.div
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <IconButton
                        disabled={!dataArr || dataArr.length === 0}
                        style={{
                          marginTop: 10,
                          background:
                            dataArr && dataArr.length != 0 && theme.primary,
                          color: dataArr && dataArr.length != 0 && "white",
                        }}
                        onClick={() =>
                          setShowAddPresetModal(!showAddPresetModal)
                        }
                      >
                        <CloudDownload />
                      </IconButton>
                    </motion.div>
                  </Tooltip>
                  {/* <Tooltip title='Share' onClick={() => setShowAddPresetModal(true)}>
				<IconButton disabled={!dataArr || dataArr.length === 0} style={{marginTop:10}} onClick={()=>setShowAddPresetModal(!showAddPresetModal)}>
					<IosShare />
				</IconButton>
			</Tooltip> */}
                  <Tooltip title="Downlaod as PDF" arrow placement="left">
                    <motion.div
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <IconButton
                        disabled={!dataArr || dataArr.length === 0}
                        style={{
                          marginTop: 10,
                          background:
                            dataArr && dataArr.length != 0 && theme.primary,
                          color: dataArr && dataArr.length != 0 && "white",
                        }}
                        onClick={handleCreatePdf}
                      >
                        <PictureAsPdf />
                      </IconButton>
                    </motion.div>
                  </Tooltip>
                  {selectedDataSet && (
                    <Tooltip
                      title={!hideHeader ? "Hide Header" : "Display Header"}
                      arrow
                      placement="left"
                    >
                      <motion.div
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <IconButton
                          style={{
                            marginTop: 10,
                            background: theme.secondary,
                            color: theme.primary,
                          }}
                          onClick={() => setHideHeader(!hideHeader)}
                        >
                          {hideHeader ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </motion.div>
                    </Tooltip>
                  )}
                </div>
                {!hideHeader && (
                  <Grid
                    item
                    container
                    xs={12}
                    style={{
                      height: "60px",
                      alignItems: "center",
                      padding: "10px 3%",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src={userData?.logo}
                      alt="logo"
                      style={{ height: "90%", width: "auto" }}
                    />
                    <Typography
                      style={{
                        color: theme.primary,
                        fontWeight: "bold",
                        fontSize: 20,
                        marginLeft: 20,
                      }}
                    >
                      {userData?.companyName}
                    </Typography>
                    <Typography
                      style={{
                        color: theme.primary,
                        fontWeight: "bold",
                        fontSize: 16,
                        marginLeft: 20,
                      }}
                    >
                      {date}
                    </Typography>
                  </Grid>
                )}

                {/* //! TESTING START */}
                {/* <CustomBarChart /> */}

                {/* //! TESTING END */}

                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    height: hideHeader ? "100%" : "calc(100% - 40px)",
                    padding: "0% 1% 3% 1%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {selectedDataSetData && dataArr && dataArr.length > 0 && (
                    <MetricContainer
                      dataAmt={dataArr.length}
                      rawData={selectedDataSetData}
                      metrics={dataArr}
                      setMetrics={setDataArr}
                      userData={userData}
                      selectedPreset={selectedPreset}
                    />
                  )}
                  {!userDataSets ||
                    (userDataSets.length === 0 && (
                      <div
                        style={{
                          display: "flex",
                          flexFlow: "column",
                          width: "fit-content",
                          height: "fit-content",
                        }}
                      >
                        <Typography>
                          You don't have any data, lets change that!
                        </Typography>
                        <Button
                          variant="contained"
                          style={{
                            color: theme.secondary,
                            background: theme.primary,
                          }}
                          onClick={() => navigate("/my-data")}
                        >
                          Add Data
                        </Button>
                      </div>
                    ))}
                </Grid>
              </Grid>
            </div>

            <div
              style={{
                boxShadow: "2px 2px 6px lightgrey",
                position: "absolute",
                right: -60,
                bottom: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: 50,
                zIndex: 10,
                padding: "5px",
              }}
            >
              <Tooltip title="Add Sheet" arrow placement="left">
                <IconButton style={{ opacity: 0.5 }}>
                  <NoteAdd />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </Grid>
      </Grid>

      <Modal
        open={!!showAddDataModal}
        onClose={() => setShowAddDataModal(false)}
      >
        <Grid
          item
          container
          xs={11}
          md={6}
          lg={5}
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            background: theme.white,
            padding: 20,
            outline: "none",
            borderRadius: 14,
          }}
        >
          <Typography
            variant="h5"
            style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}
          >
            Select your data set
          </Typography>
          <Grid
            item
            container
            xs={12}
            style={{ flexFlow: "column", padding: 10 }}
          >
            {userDataSets && userDataSets.length > 0 && (
              <FormControl fullWidth style={{ marginTop: 20 }}>
                <InputLabel id="data-sets">Data Set</InputLabel>
                <Select
                  label="Data Set"
                  labelId="data-sets"
                  id="data-sets"
                  value={selectedDataSet?.name}
                  onChange={(e) => handleChangeDataSet(e)}
                >
                  {userDataSets.map((dataSet, i) => (
                    <MenuItem key={dataSet.name + `${i}`} value={dataSet.name}>
                      {dataSet.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {selectedDataSet &&
              userPresets &&
              userPresets.length > 0 &&
              [...userPresets].filter(
                (pre) => pre.dataSet === selectedDataSet.name
              ).length > 0 && (
                <FormControl fullWidth style={{ marginTop: 10 }}>
                  <InputLabel id="data-sets">Preset</InputLabel>
                  <Select
                    label="Preset"
                    labelId="data-sets"
                    id="data-sets"
                    value={selectedPreset?.name || ""}
                    onChange={(e) =>
                      setSelectedPreset(
                        userPresets.find(
                          (preset) => preset.name === e.target.value
                        )
                      )
                    }
                  >
                    {[...userPresets]
                      .filter((pre) => pre.dataSet === selectedDataSet.name)
                      .map((preset, i) => (
                        <MenuItem
                          key={preset.name + `${i}`}
                          value={preset.name}
                        >
                          {preset.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            <Grid
              item
              container
              xs={12}
              style={{ marginTop: 10, justifyContent: "flex-end" }}
            >
              <Button
                style={{
                  background: theme.primary,
                  opacity: selectedDataSet ? 1 : 0.5,
                  color: theme.white,
                }}
                onClick={() => setShowAddDataModal(!showAddDataModal)}
                disabled={!selectedDataSet}
              >
                Done
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>

      <Modal open={!!addMetricModal} onClose={() => setAddMetricModal(false)}>
        <Grid
          item
          container
          xs={11}
          md={6}
          lg={5}
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            background: theme.white,
            padding: 20,
            outline: "none",
            borderRadius: 14,
          }}
        >
          <Typography
            variant="h5"
            style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}
          >
            Add your {ordinalWords[dataArr.length]} metric
          </Typography>
          <Grid
            item
            container
            xs={7}
            style={{ flexFlow: "column", padding: 10 }}
          >
            <Typography>I would like to see:</Typography>
            <Select
              fullWidth
              value={selectedMetric?.headerName || ""}
              onChange={(e) => {
                setSelectedMetric(
                  metrics.find((x) => x.headerName === e.target.value)
                );
              }}
            >
              {metrics &&
                metrics.length > 0 &&
                [...metrics]
                  .filter(
                    (x) =>
                      x.headerName !== "ID" &&
                      x.type !== "DateTime" &&
                      x.type !== "Location"
                  )
                  .map((metric) => {
                    return (
                      <MenuItem
                        key={metric.headerName}
                        value={metric.headerName}
                      >
                        <motion.div
                          whileHover={{
                            scale: 1.05,
                          }}
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography>{metric.headerName}</Typography>
                          <Typography variant="caption">
                            {metric.type}
                          </Typography>
                        </motion.div>
                      </MenuItem>
                    );
                  })}
            </Select>
          </Grid>
          {selectedMetric?.type === "Number" && (
            <Grid
              item
              container
              xs={5}
              style={{ flexFlow: "column", padding: 10 }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>Calculation Type:</Typography>
                <Tooltip title="Leave empty if you don't want to display any calculations">
                  <HelpOutline style={{ color: "grey" }} />
                </Tooltip>
              </div>
              <Select
                fullWidth
                value={numericalCalculation || ""}
                onChange={(e) => {
                  setNumericalCalculation(e.target.value);
                }}
              >
                {[
                  "None",
                  "Total",
                  "Average",
                  "Median",
                  "Percentiles",
                  //   "Ranges",
                ].map((calc) => {
                  return (
                    <MenuItem key={calc} value={calc === "None" ? "" : calc}>
                      {calc}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          )}
          <Grid
            item
            container
            xs={10}
            style={{ flexFlow: "column", padding: 10 }}
          >
            {(dataArr.length === 0 || dataArr.length === 1) && (
              <>
                <Typography style={{ opacity: !selectedMetric ? 0.5 : 1 }}>
                  Across/Within: <i style={{ fontSize: 12 }}>(optional)</i>
                </Typography>
                <Select
                  fullWidth
                  disabled={!selectedMetric}
                  value={selectedWithinMetric?.headerName || ""}
                  onChange={(e) => {
                    setSelectedWithinMetric(
                      metrics.find(
                        (x) =>
                          x.headerName === e.target.value &&
                          x.headerName !== selectedMetric?.headerName
                      )
                    );
                  }}
                >
                  {[...metrics]
                    .filter(
                      (x) =>
                        x.type !== "Number" &&
                        x.headerName !== "ID" &&
                        x.type !== "DateTime" &&
                        x.headerName !== selectedMetric?.headerName
                    )
                    .map((metric) => {
                      return (
                        <MenuItem
                          key={metric.headerName}
                          value={metric.headerName}
                        >
                          <motion.div
                            whileHover={{
                              scale: 1.05,
                            }}
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography>{metric.headerName}</Typography>
                            <Typography variant="caption">
                              {metric.type}
                            </Typography>
                          </motion.div>
                        </MenuItem>
                      );
                    })}
                </Select>
              </>
            )}
          </Grid>
          {selectedMetric && !selectedWithinMetric && (
            <Grid
              item
              container
              xs={2}
              style={{
                flexFlow: "column",
                padding: 10,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Tooltip title="Add Metric">
                <IconButton
                  style={{
                    marginTop: 10,
                    background: theme.secondary,
                    color: theme.primary,
                  }}
                  onClick={addMetricVisualization}
                >
                  <Add />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          {selectedMetric && selectedWithinMetric && (
            <>
              <Grid
                item
                container
                xs={10}
                style={{ flexFlow: "column", padding: 10 }}
              >
                {/* <>
                {(dataArr.length === 0 || dataArr.length === 1) && (
                  <>
                    <Typography style={{}}>
                      By: <i style={{ fontSize: 12 }}>(optional)</i>
                    </Typography>
                    <Select
                      fullWidth
                      value={selectedByMetric?.headerName || ""}
                      onChange={(e) => {
                        setSelectedByMetric(
                          metrics.find(
                            (x) =>
                              x.headerName === e.target.value &&
                              x.headerName !== selectedMetric.headerName &&
                              x.headerName !== selectedWithinMetric.headerName
                          )
                        );
                      }}
                    >
                      {[...metrics]
                        .filter(
                          (x) =>
                            x.type !== "Number" &&
                            x.headerName !== "ID" &&
                            x.headerName !== selectedMetric.headerName &&
                            x.headerName !== selectedWithinMetric.headerName
                        )
                        .map((metric) => {
                          return (
                            <MenuItem
                              key={metric.headerName}
                              value={metric.headerName}
                            >
                              <motion.div
                                whileHover={{
                                  scale: 1.05,
                                }}
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography>{metric.headerName}</Typography>
                                <Typography variant="caption">
                                  {metric.type}
                                </Typography>
                              </motion.div>
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </>
                )}
				</> */}
              </Grid>
              <Grid
                item
                container
                xs={2}
                style={{
                  flexFlow: "column",
                  padding: 10,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Add Metric">
                  <IconButton
                    style={{
                      marginTop: 10,
                      background: theme.secondary,
                      color: theme.primary,
                    }}
                    onClick={addMetricVisualization}
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
              </Grid>
            </>
          )}
        </Grid>
      </Modal>

      <Modal
        open={!!showAddPresetModal}
        onClose={() => setShowAddPresetModal(false)}
      >
        <Grid
          item
          container
          xs={11}
          md={6}
          lg={5}
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            background: theme.white,
            padding: 20,
            outline: "none",
            borderRadius: 14,
            justifyContent: "flex-end",
          }}
        >
          <Typography
            style={{ width: "100%", marginBottom: 20, textAlign: "center" }}
          >
            Save Preset
          </Typography>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Preset Name"
            variant="outlined"
            value={newPresetName}
            onChange={(e) => setNewPresetName(e.target.value)}
            style={{ marginBottom: 20 }}
          />
          <Button
            variant="contained"
            onClick={() => {
              setNewPresetName("");
              setShowAddPresetModal(!showAddPresetModal);
            }}
            style={{
              backgroundColor: theme.secondary,
              color: theme.primary,
              marginRight: 10,
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleSavePreset()}
            style={{ backgroundColor: theme.primary, color: theme.white }}
          >
            Save
          </Button>
        </Grid>
      </Modal>

      {userData &&
        (!userData?.companyName || userData?.logo?.length === undefined) && (
          <Modal open={true} style={{ padding: 10 }}>
            <Grid
              item
              container
              xs={11}
              md={6}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: 14,
                background: theme.white,
                padding: 20,
                outline: "none",
              }}
            >
              <Typography
                variant={"h4"}
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Additional Info
              </Typography>
              <Typography style={{ width: "100%", textAlign: "center" }}>
                Before we get started, let's gather a bit of info, it'll only
                help!
              </Typography>
              {userData.companyName ||
              userData.companyName ||
              userData.companyName ? (
                <Grid Grid item container xs={12} style={{ marginTop: 10 }}>
                  <Grid item container xs={6}>
                    <Typography variant="caption" style={{ width: "100%" }}>
                      Add a Company Logo?
                    </Typography>
                    <motion.div
                      whileHover={{
                        scale: 0.97,
                        cursor: "pointer",
                        opacity: 0.9,
                      }}
                      style={{
                        height: 100,
                        width: 100,
                        border: "3px dashed grey",
                        background: "lightgrey",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 14,
                      }}
                      onDrop={handleImageSelect}
                      onClick={() => fileInputRef.current.click()}
                    >
                      {!imageSrc && (
                        <Typography
                          variant="caption"
                          style={{
                            width: "100%",
                            padding: "0px 10px",
                            textAlign: "center",
                            color: "grey",
                          }}
                        >
                          Drag and drop / click
                        </Typography>
                      )}
                      {imageSrc && (
                        <img
                          src={imageSrc}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                      )}
                    </motion.div>
                    <input
                      ref={fileInputRef}
                      onChange={handleImageSelect}
                      type="file"
                      //accept png, jpg, jpeg, gif only
                      accept="image/png, image/jpeg, image/jpg, image/gif"
                      style={{ display: "none" }}
                    />
                  </Grid>

                  <Grid item container xs={6}>
                    {/* 3 color input feilds for primaryColor, secondaryColor, and accentColor */}
                    <Typography variant="caption" style={{ width: "100%" }}>
                      Company Colors:
                    </Typography>
                    <Grid item container xs={12} style={{ marginTop: 10 }}>
                      <Grid
                        item
                        xs={4}
                        style={{
                          displat: "flex",
                          flexFlow: "column",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="caption" style={{ width: "100%" }}>
                          Primary Color:
                        </Typography>
                        <input
                          style={{ width: 50, height: 50 }}
                          type="color"
                          value={primaryColor}
                          onChange={(e) => setPrimaryColor(e.target.value)}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        style={{
                          displat: "flex",
                          flexFlow: "column",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="caption" style={{ width: "100%" }}>
                          Secondary Color:
                        </Typography>
                        <input
                          style={{ width: 50, height: 50 }}
                          type="color"
                          value={secondaryColor}
                          onChange={(e) => setSecondaryColor(e.target.value)}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        style={{
                          displat: "flex",
                          flexFlow: "column",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="caption" style={{ width: "100%" }}>
                          Accent Color:
                        </Typography>
                        <input
                          style={{ width: 50, height: 50 }}
                          type="color"
                          value={accentColor}
                          onChange={(e) => setAccentColor(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item container xs={12} style={{ marginTop: 10 }}>
                  <Typography variant="caption">
                    Who do you work for?
                  </Typography>
                  <TextField
                    sx={{ m: 1 }}
                    label="Company Name"
                    fullWidth
                    value={companyName}
                    onChange={(event) => setCompanyName(event.target.value)}
                  />
                  {!departmentOther ? (
                    <>
                      <Typography variant="caption">
                        Team player? Who do you support?
                      </Typography>
                      <FormControl sx={{ m: 1, maxHeight: 300, width: "100%" }}>
                        <InputLabel id="dept">Department</InputLabel>
                        <Select
                          label="Department"
                          fullWidth
                          value={department}
                          onChange={(event) => {
                            event.target.value === "Other"
                              ? setDepartmentOther(true)
                              : setDepartment(event.target.value);
                          }}
                        >
                          <MenuItem disabled value="">
                            - Select a Department -
                          </MenuItem>
                          {availDepartments.map((department) => (
                            <MenuItem value={department}>{department}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  ) : (
                    <TextField
                      label="Enter your Department"
                      fullWidth
                      value={department}
                      onChange={(event) => setDepartment(event.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setDepartmentOther(false)}
                            >
                              <Close />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        m: 1,
                        display: "flex",
                        flexWrap: "wrap",
                        // "& > *": { margin: 1 },
                      }}
                    />
                  )}

                  {!titleOther ? (
                    <>
                      <Typography variant="caption">
                        Got a special job, how do you contribute?
                      </Typography>
                      <FormControl sx={{ m: 1, maxHeight: 300, width: "100%" }}>
                        <InputLabel id="role">Your Role</InputLabel>
                        <Select
                          ma
                          label="Your Role"
                          fullWidth
                          value={title}
                          onChange={(event) => {
                            event.target.value === "Other"
                              ? setTitleOther(true)
                              : setTitle(event.target.value);
                          }}
                        >
                          <MenuItem disabled value="">
                            - Select Your Role -
                          </MenuItem>
                          {availTitles.map((title) => (
                            <MenuItem value={title}>{title}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  ) : (
                    <TextField
                      label="Enter your Job Title"
                      fullWidth
                      value={title}
                      onChange={(event) => setTitle(event.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setTitleOther(false)}>
                              <Close />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        m: 1,
                        display: "flex",
                        flexWrap: "wrap",
                        // "& > *": { margin: 1 },
                      }}
                    />
                  )}
                </Grid>
              )}
              <Grid
                item
                container
                xs={12}
                style={{ justifyContent: "flex-end", marginTop: 10 }}
              >
                <Button
                  // disabled={
                  //   (companyName === "" &&
                  //     department === "" &&
                  //     title === "" &&
                  //     !imageFile) ||
                  //   !!loading
                  // }
                  variant="contained"
                  style={{ marginTop: 10, backgroundColor: theme.primary }}
                  onClick={handleAddWorkInfo}
                >
                  {userData.companyName ||
                  userData.companyName ||
                  userData.companyName
                    ? "Continue"
                    : "Let's Go!"}
                  <KeyboardArrowRight />
                </Button>
              </Grid>
            </Grid>
          </Modal>
        )}

      <SubModal subModalOpen={subModalOpen} setOpen={setSubModalOpen} />
      <SettingsModal
        settingsModalOpen={settingsModalOpen}
        setSettingsModalOpen={setSettingsModalOpen}
      />
    </Container>
  );
};

export default Dashboard;
