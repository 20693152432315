export const ordinalWords = [
  "Primary",
  "Seconday",
  "3rd",
  "4th",
  "5th",
  "6th",
  "Last",
];

export function extractJsonContent(inputString) {
  const startIndex = inputString.indexOf("[");
  const endIndex = inputString.lastIndexOf("]");

  if (startIndex === -1 || endIndex === -1 || endIndex <= startIndex) {
    console.error("Invalid input: Unable to find valid JSON content.");
    return null;
  }

  const extractedContent = inputString.substring(startIndex, endIndex + 1);
  return extractedContent;
}

export function getColorContrast(color) {
  // Convert hex color to RGB
  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    return {
      r: (bigint >> 16) & 255,
      g: (bigint >> 8) & 255,
      b: bigint & 255,
    };
  };

  // Calculate luminance based on the sRGB color model
  const calculateLuminance = (r, g, b) => {
    const srgb = (c) => {
      c /= 255;
      return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
    };

    r = srgb(r);
    g = srgb(g);
    b = srgb(b);

    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  };

  // Get RGB values from hex color
  const rgb = hexToRgb(color);

  // Calculate luminance
  const luminance = calculateLuminance(rgb.r, rgb.g, rgb.b);

  // Choose black or white based on luminance
  return luminance < 0.5 ? "#fff" : "#000";
}

export function median(arr) {
  // Sort the array in ascending order
  arr.sort((a, b) => a - b);

  const n = arr.length;
  const middle = Math.floor(n / 2);

  if (n % 2 === 0) {
    // If the array length is even, return the average of the two middle numbers
    return (arr[middle - 1] + arr[middle]) / 2;
  } else {
    // If the array length is odd, return the middle number
    return arr[middle];
  }
}

function percentile(arr, p) {
  if (arr.length === 0) return null;
  if (p === 0) return arr[0];
  if (p === 100) return arr[arr.length - 1];
  arr.sort((a, b) => a - b);
  var index = (p / 100) * (arr.length - 1);
  var lower = Math.floor(index);
  var upper = Math.ceil(index);
  if (lower === upper) return arr[lower];
  var d0 = arr[lower] * (upper - index);
  var d1 = arr[upper] * (index - lower);
  return Math.round(d0 + d1);
}

function getPercentiles(data, percentiles) {
  let result = {};
  for (let obj of data) {
    let values = obj.value;
    let percentilesObj = {};
    for (let p of percentiles) {
      percentilesObj[p] = percentile(values, p);
    }
    result[obj.comparedWithin] = percentilesObj;
  }
  return result;
}

// function getPercentiles(data, percentiles) {
//   let result = {};

//   for (let obj of data) {
//     let values = obj.value;
//     let percentilesObj = {};
//     let counts = {}; // Object to store counts for each percentile

//     // Sorting the values array
//     values.sort((a, b) => a - b);

//     // Calculating percentile indices
//     let n = values.length;
//     for (let p of percentiles) {
//       let percentileIndex = Math.ceil((p / 100) * n) - 1;
//       percentilesObj[p] = values[percentileIndex];

//       // Counting items falling within the percentile range
//       let count = 0;
//       for (let val of values) {
//         if (values[percentileIndex - 1]) {
//           if (
//             val >= values[percentileIndex - 1] &&
//             val <= values[percentileIndex]
//           ) {
//             count++;
//           } else {
//             break;
//           }
//         } else {
//           if (val <= values[percentileIndex]) {
//             count++;
//           } else {
//             break;
//           }
//         }
//         // if (val <= values[percentileIndex]) {
//         //   count++;
//         // } else {
//         //   break;
//         // }
//       }
//       counts[p] = count;
//     }

//     result[obj.comparedWithin] = {
//       percentiles: percentilesObj,
//       counts: counts,
//     };
//   }

//   console.log("PERCENTILES", result);
//   debugger;
//   return result;
// }

export function calculateAverage(values, comparedWithin, comparedBy) {
  let result;
  let finalResult;

  if (comparedWithin) {
    if (comparedBy) {
    } else {
      result = values.reduce((acc, curr) => {
        if (!acc[curr.camparedWithin]) {
          acc[curr.camparedWithin] = {
            comparedWithin: curr.camparedWithin,
            value: 0,
            amt: 0,
          };
        }
        acc[curr.camparedWithin].value += curr.value;
        acc[curr.camparedWithin].amt += curr.amt;
        return acc;
      }, {});
      let reducedResult = Object.values(result);

      finalResult = reducedResult.map((val) => ({
        value:
          val.comparedWithin === undefined ||
          val.comparedWithin === null ||
          val.comparedWithin === ""
            ? "N/A"
            : val.comparedWithin,
        amt: val.value / val.amt,
      }));
      console.log(finalResult);
      return finalResult;
    }
  } else {
    console.log("Array", values);
    const metrics = comparedWithin
      ? values.filter((x) => !isNaN(x.value)).flatMap((obj) => obj.value)
      : values.filter((x) => !isNaN(x.value)).map((obj) => obj.value);
    const total = metrics.reduce((acc, value) => acc + value, 0);
    return total / metrics.length;
  }
}

export function calculateMedian(values, comparedWithin, comparedBy) {
  let result;
  let finalResult;

  if (comparedWithin) {
    if (comparedBy) {
    } else {
      result = values.reduce((acc, curr) => {
        if (!acc[curr.camparedWithin]) {
          acc[curr.camparedWithin] = {
            comparedWithin: curr.camparedWithin,
            value: [],
            amt: 0,
          };
        }
        acc[curr.camparedWithin].value.push(curr.value);
        acc[curr.camparedWithin].amt += curr.amt;
        return acc;
      }, {});
      let resultsArray = Object.values(result);
      console.log("REDUCED RESULT", resultsArray);

      finalResult = resultsArray.map((val) => {
        return {
          comparedWithin: val.comparedWithin,
          value: median(val.value),
          amt: val.amt,
        };
      });
      console.log("FINAL RESULT", finalResult);
      return finalResult;
    }
  } else {
    const metrics = comparedWithin
      ? values.filter((x) => !isNaN(x.value)).flatMap((obj) => obj.value)
      : values.filter((x) => !isNaN(x.value)).map((obj) => obj.value);
    const sorted = metrics.sort((a, b) => a - b);
    const mid = Math.floor(sorted.length / 2);
    if (sorted.length % 2 === 0) {
      return (sorted[mid - 1] + sorted[mid]) / 2;
    } else {
      return sorted[mid];
    }
  }
}

export function calculatePercentiles(values, comparedWithin, comparedBy) {
  const percentiles = [10, 25, 50, 75, 90];
  let result;
  let finalResult = [];
  if (comparedWithin) {
    if (comparedBy) {
    } else {
      result = values.reduce((acc, curr) => {
        if (!acc[curr.camparedWithin]) {
          acc[curr.camparedWithin] = {
            comparedWithin: curr.camparedWithin,
            value: [],
            amt: 0,
          };
        }
        acc[curr.camparedWithin].value.push(curr.value);
        acc[curr.camparedWithin].amt += curr.amt;
        return acc;
      }, {});
      let resultsArray = Object.values(result);

      resultsArray.forEach((result) => {
        result.value.sort((a, b) => a - b);
      });

      console.log("REDUCED RESULT", resultsArray);

      let percentileResults = getPercentiles(resultsArray, percentiles);
      let finalResultStepOne = Object.entries(percentileResults);
      for (let i = 0; i < finalResultStepOne.length; i++) {
        let sortedPercentileValues = resultsArray[i].value.sort(
          (a, b) => a - b
        );
        let sortedValues = resultsArray[i].value.sort((a, b) => a - b);
        let max = sortedPercentileValues[sortedValues.length - 1];
        finalResult.push({
          amt: max,
          value: finalResultStepOne[i][0],
          combinedValues: Object.entries(finalResultStepOne[i][1]).map(
            (val) => ({
              [`${val[0]}th Percentile`]: val[1],
            })
          ),
        });
      }
      //   let finalResult = Object.values(percentileResults);
      console.log("FINAL RESULT", finalResult);
      return finalResult;
    }
  }
  const metrics = comparedWithin
    ? values.filter((x) => !isNaN(x.value)).flatMap((obj) => obj.value)
    : values.filter((x) => !isNaN(x.value)).map((obj) => obj.value);
  const sorted = metrics.sort((a, b) => a - b);

  return percentiles.map((percentile) => {
    const index = Math.ceil((percentile / 100) * sorted.length);
    return sorted[index - 1];
  });
}

export function calculateRanges(values, comparedWithin, comparedBy) {
  const metrics = values.filter((x) => !isNaN(x.value)).map((obj) => obj.value);
  return {
    min: Math.min(...metrics),
    max: Math.max(...metrics),
  };
}

export function calculateSum(values, comparedWithin, comparedBy) {
  let result;
  let finalResult;

  if (comparedWithin) {
    if (comparedBy) {
    } else {
      result = values.reduce((acc, curr) => {
        if (!acc[curr.camparedWithin]) {
          acc[curr.camparedWithin] = {
            comparedWithin: curr.camparedWithin,
            value: 0,
          };
        }
        acc[curr.camparedWithin].value += curr.value;
        return acc;
      }, {});

      finalResult = Object.values(result);
      return finalResult;
    }
  } else {
    console.log("Array length before", values.length);
    const metrics = values
      .filter((x) => !isNaN(parseFloat(x.value)))
      .map((obj) => parseFloat(obj.value));

    console.log("Array length after", metrics.length);
    debugger;
    return {
      sum: metrics.reduce((acc, value) => acc + value, 0),
      count: metrics.length,
    };
  }
}
