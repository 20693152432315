import {
  Grid,
  IconButton,
  Modal,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import SubscriptionCard from "../SubscriptionCard";
import { useSelector } from "react-redux";
import { Close } from "@mui/icons-material";

const SubModal = ({
  subModalOpen,
  setSubModalOpen,
  authModelOpen,
  setAuthModalOpen,
}) => {
  const userData = useSelector((state) => state.auth.userData);
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const [annualPlan, setAnnualPlan] = useState(false);
  return (
    <div>
      <Modal open={!!subModalOpen}>
        <Grid
          item
          container
          xs={11}
          md={10}
          lg={9}
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            padding: 30,
          }}
        >
          <Grid
            item
            container
            xs={12}
            style={{
              position: "relative",
              justifyContent: "space-evenly",
              alignItems: "center",
              borderRadius: 10,
              padding: 10,
              background: "white",
              outline: "none",
              maxHeight: "90vh",
              //   overflowY: "scroll",
            }}
          >
            {!userData && (
              <IconButton
                style={{
                  position: "absolute",
                  top: -20,
                  right: -20,
                  background: "white",
                  color: "grey",
                }}
                onClick={() => setSubModalOpen(false)}
              >
                <Close />
              </IconButton>
            )}
            <img
              style={{ width: "60px", height: "auto" }}
              alt="logo"
              src="https://firebasestorage.googleapis.com/v0/b/vizz-454f6.appspot.com/o/companyFiles%2Fvizz-logo.png?alt=media&token=eca0a95e-9b4b-4d05-af69-9bcea2539ed1"
            />

            <Typography
              variant="h5"
              style={{ fontWeight: "bold", width: "100%", textAlign: "center" }}
            >
              Choose your subscription tier.
            </Typography>

            {/* <Grid
              item
              container
              xs={12}
              justifyContent={"center"}
              style={{ padding: 20 }}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <AntSwitch
                  checked={annualPlan}
                  onChange={() => setAnnualPlan(!annualPlan)}
                  inputProps={{ "aria-label": "ant design" }}
                />
                <Typography style={{ fontWeight: "bold" }}>
                  {annualPlan ? "ANNUAL" : "MONTHLY"}
                </Typography>
              </Stack>
            </Grid> */}

            <Grid
              item
              container
              xs={12}
              md={6}
              lg={3}
              style={{ borderRadius: "25px", height: 400, margin: 10 }}
            >
              <SubscriptionCard
                tier="free"
                setSubModalOpen={setSubModalOpen}
                setAuthModalOpen={setAuthModalOpen}
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={6}
              lg={3}
              style={{ borderRadius: "25px", height: 400, margin: 10 }}
            >
              <SubscriptionCard
                tier="basic"
                setSubModalOpen={setSubModalOpen}
                annualPlan={annualPlan}
                setAuthModalOpen={setAuthModalOpen}
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={6}
              lg={3}
              style={{ borderRadius: "25px", height: 400, margin: 10 }}
            >
              <SubscriptionCard
                tier="influencer"
                setSubModalOpen={setSubModalOpen}
                // isUnavail={true}
                annualPlan={annualPlan}
                setAuthModalOpen={setAuthModalOpen}
              />
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default SubModal;
