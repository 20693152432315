import {
  Avatar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { theme as defaultTheme } from "../global/theme";
import { auth } from "../config/firebaseConfig";
import {
  clearCurrentUser,
  setUserData,
  setUserDataSets,
  setUserPresets,
} from "../global/authActions";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Menu } from "@mui/icons-material";

const AppBar = ({
  open,
  setOpen,
  setSubModalOpen,
  subModalOpen,
  settingsModalOpen,
  setSettingsModalOpen,
  setDrawerOpen,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.userData);
  const theme = userData?.theme ? userData.theme : defaultTheme;
  const logo =
    "https://firebasestorage.googleapis.com/v0/b/vizz-454f6.appspot.com/o/companyFiles%2Fvizz-logo.png?alt=media&token=eca0a95e-9b4b-4d05-af69-9bcea2539ed1";

  const handleLogout = async () => {
    await auth
      .signOut()
      .then(() => {
        dispatch(clearCurrentUser(null));
        dispatch(setUserData(null));
        dispatch(setUserDataSets(null));
        dispatch(setUserPresets(null));
      })
      .then(() => {
        navigate("/");
      });
  };

  useEffect(() => {}, [userData]);

  const handleNav = () => {
    //if url path is /dashboard.. nav to /my-data and vice versa
    if (window.location.pathname === "/dashboard") {
      navigate("/my-data");
    } else {
      navigate("/dashboard");
    }
  };

  return (
    <Box
      sx={{ flexGrow: 1 }}
      style={{
        position: "sticky",
        top: 0,
        backgroundColor: theme.white,
        zIndex: 10,
      }}
    >
      <Grid
        item
        container
        xs={12}
        sx={{ padding: { xs: 0, md: "0% 10%" } }}
        style={{ backgroundColor: theme.white }}
      >
        <Toolbar
          style={{
            backgroundColor: theme.white,
            width: "100%",
            borderBottom: `2px solid ${theme.primary}`,
          }}
        >
          {userData ? (
            <IconButton
              // onClick={handleNav}
              onClick={() => setDrawerOpen(true)}
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              style={{ color: theme.primary }}
              sx={{ mr: 2 }}
            >
              <Menu />
            </IconButton>
          ) : (
            <div
              style={{ display: "flex", flexFlow: "row", alignItems: "center" }}
            >
              <img src={logo} style={{ height: 40, width: "auto" }} />
              <Button style={{ color: theme.accentOne }}>Why Vizz</Button>
              <Button
                style={{ color: theme.accentOne }}
                onClick={() => setSubModalOpen(true)}
              >
                Plans & Pricing
              </Button>
            </div>
          )}
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          ></Typography>
          {userData && (
            <Avatar
              onClick={() => setSettingsModalOpen(!settingsModalOpen)}
              style={{ marginRight: 10 }}
            />
          )}
          {userData ? (
            <Button
              variant="contained"
              onClick={handleLogout}
              style={{ color: theme.primary, backgroundColor: theme.secondary }}
            >
              Logout
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                onClick={() => {
                  setOpen(!open);
                }}
                style={{
                  color: theme.accentOne,
                  backgroundColor: theme.secondary,
                  marginRight: 10,
                  borderRadius: 40,
                }}
              >
                Sign Up
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setOpen(!open);
                }}
                style={{
                  color: theme.accentOne,
                  backgroundColor: theme.primary,
                  borderRadius: 40,
                }}
              >
                Login
              </Button>
            </>
          )}
        </Toolbar>
      </Grid>
    </Box>
  );
};

export default AppBar;
