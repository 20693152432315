import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { useSelector, useReducer } from "react-redux";
import { theme } from "../global/theme";
import { Functions } from "@mui/icons-material";
import firebase, { db, functions, storage } from "../config/firebaseConfig";

const SubscriptionCard = ({
  tier,
  setSubModalOpen,
  annualPlan,
  setAuthModalOpen,
}) => {
  const userData = useSelector((state) => state.auth.userData);

  const handleSubscribe = async (e, priceId) => {
    e.preventDefault();

    if (userData?.name) {
      ////console.log('we have a user')
      if (priceId) {
        console.log("initializing");
        //const uid = userData.uid;
        //call the callable function, and pass in the priceId
        const checkout = functions.httpsCallable("stripeCheckout");
        const result = await checkout({
          priceId: priceId,
          uid: userData.uid,
          companyName: userData.companyName,
          theme: userData.theme,
        });
        if (result.data.url) {
          console.log("results", result);
          window.location.replace(result.data.url);
          setSubModalOpen(false);
        }
      } else {
        console.log("no priceId");
        window.location.reload();
        // db.collection('users').doc(userData.uid).update({
        //     subscriptionTier: 0
        // }).then(()=> {window.location.reload()})
      }
    } else {
      ////console.log('we have no result')
      if (setAuthModalOpen) {
        setAuthModalOpen(true);
      }
    }
  };

  const tiers = {
    free: {
      title: "Vizz Basic",
      price: "$9.99",
      details: (
        <Typography style={{ fontSize: "11px" }}>
          {" "}
          <b>
            Ideal for: Individual professionals, startups with limited data
            needs.
          </b>{" "}
          <br /> <br /> • One User License <br /> • Data upload from CSV or
          Excel files
          <br /> • Selection of metrics to visualize <br /> •Basic comparison of
          metrics <br /> • Ability to create and save presets
          <br /> • Export visualizations
        </Typography>
      ),
      buttonText: "Get Started",
      priceIdMonthly: "price_1OoPojJOpsxdxVe2FI4ODQDN",
      //   priceIdAnnual: "price_1Nnp1MJOpsxdxVe28fwXFvFb",
    },
    basic: {
      fill: (
        <Box
          sx={{
            width: "100%", // Fill the card's width
            height: "8px", // Adjust as per your design
            backgroundColor: theme.primary, // Yellow color
          }}
        />
      ),
      title: "Vizz Pro",
      price: annualPlan ? "$?.??/month" : "$19.99/month",
      details: (
        <Typography style={{ fontSize: "11px" }}>
          {" "}
          <b>
            Ideal for: Mid-sized businesses with moderate data requirements.
          </b>{" "}
          <br /> <br />• 3 User Licenses <br />• Compare metrics within/across
          other metrics <br /> • AI-assisted reports based on visualizations
          <br /> • Export visualizations
          <br /> • Collaboration features (sending visualizations to team
          members)
        </Typography>
      ),
      buttonText: "Upgrade",
      priceIdMonthly: "price_1OoPocJOpsxdxVe2y4M3TUcD",
      //   priceIdAnnual: "price_1Nnp1MJOpsxdxVe28fwXFvFb",
    },
    influencer: {
      title: "Vizz Enterprise",
      price: annualPlan ? "$?.??/month " : "$249.99/month",
      details: (
        <Typography style={{ fontSize: "11px" }}>
          {" "}
          <b>
            Ideal for: Large corporations, enterprises with extensive data
            needs, organizations requiring tailored solutions and support.
          </b>
          <br /> <br /> • 35 User Licenses <br />• All Pro Tier features
          <br /> • Dedicated account manager <br /> • Priority support
          <br /> • Custom integrations <br /> • Scalability options (e.g.,
          higher data upload limits)
        </Typography>
      ),
      buttonText: "Go Premium",
      priceIdMonthly: "price_1OoPoQJOpsxdxVe2d5ZXF0TD",
      // priceIdAnnual: "price_1Nnp1EJOpsxdxVe2lVu0GnHO",
    },
  };

  const currentTier = tiers[tier];

  return (
    <Card
      sx={{
        minWidth: 275,
        transition: "transform 0.3s, boxShadow 0.3s, background-color 0.3s",
        borderRadius: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
          transform: "scale(1.05)", // Makes card grow slightly
          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)", // Enhanced shadow
          backgroundColor: alpha(theme.primary, 0.2), // Blue tint, adjust as needed
        },
      }}
    >
      <CardContent sx={{}}>
        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
          Subscription Tier
        </Typography>
        <Typography variant="h5" component="div">
          {currentTier.title}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {currentTier.price}
        </Typography>
        <Typography variant="caption" style={{ fontSize: "10px" }}>
          {currentTier.details}
        </Typography>
      </CardContent>
      <CardActions style={{ justifyContent: "center" }}>
        {
          currentTier.title !== "Free" ? (
            <form
              onClick={(e) => {
                handleSubscribe(e, currentTier.priceIdMonthly);
                console.log("clicked", e);
              }}
            >
              <input
                type="hidden"
                name="priceId"
                value={currentTier.priceIdMonthly}
                style={{ display: "none" }}
              />
              <Button
                type="submit"
                size="small"
                variant="contained"
                color="primary"
                onClick={(e) => {
                  handleSubscribe(e, currentTier.priceIdMonthly);
                  console.log("clicked", e);
                  console.log("current tier =>", currentTier);
                }}
                style={{
                  borderRadius: 40,
                  color: "white",
                  background: "black",
                  fontWeight: "bold",
                  minWidth: 120,
                }}
              >
                Checkout
              </Button>
            </form>
          ) : (
            <Button
              onClick={(e) => {
                handleSubscribe(e, currentTier.priceIdMonthly);
                console.log("clicked", e);
              }}
              size="small"
              variant="contained"
              color="primary"
              style={{
                borderRadius: 40,
                color: "white",
                background: "black",
                fontWeight: "bold",
                minWidth: 120,
              }}
            >
              {currentTier.buttonText}
            </Button>
          )
          // :
          // <Button
          //     onClick={handleSubscribe}
          // size="small" variant="contained" color="primary" style={{borderRadius:40, color:'white', background:'black', fontWeight:'bold', minWidth:120}}>
          //     {currentTier.buttonText}
          // </Button>
        }
      </CardActions>
    </Card>
  );
};

export default SubscriptionCard;
