import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import MetricContainer from "../components/MetricContainer";
// import { theme } from "../global/theme";
import { sampleMetricData as SampleMetricData } from "../global/sampleData";
import { getColorContrast } from "../Utils";

function roundNumber(num, base) {
  if (base === 0) return num; // No rounding for base 0
  return Math.ceil(num / base) * base;
}

// Function to calculate the base dynamically based on the input number
function calculateBase(num) {
  if (num < 10) return 10;
  const length = num.toString().length - 1;
  return Math.pow(10, length);
}

const CustomBarChart = ({ metricData, theme, amtOfDisplayedMetrics }) => {
  let sampleMetricData = metricData ? metricData : SampleMetricData;
  const startingHeaders =
    amtOfDisplayedMetrics <= 3
      ? [...sampleMetricData.values[0].comparedValues.map((x) => x.value)]
      : [
          ...sampleMetricData.values[0].comparedValues.map((x) => x.value),
        ].slice(0, 25);
  const hasMoreThan25Comparisons = startingHeaders.length > 25;
  const [yHeaders, setYHeaders] = useState(startingHeaders);
  const [xHeaders, setXHeaders] = useState(null);
  const [keys, setKeys] = useState(null);
  //   const [xHeaders, setXHeaders] = useState([
  //     0,
  //     // roundNumber(
  //     //   sampleMetricData.values[0].amt,
  //     //   calculateBase(sampleMetricData.values[0].amt)
  //     // ),
  //     500,
  //   ]);
  const [xValues, setXValues] = useState(null);
  const [loading, setLoading] = useState(true);

  console.log("CustomBarChart Data x Headers", sampleMetricData, yHeaders);

  const yHeaderFontSize =
    yHeaders.length > 50
      ? "4cqw"
      : yHeaders.length > 40
      ? "4.5cqw"
      : yHeaders.length > 30
      ? "5cqw"
      : "6cqw";

  const parentRef = useRef(null);
  const numChildDivs = yHeaders.length;

  const colors = [
    theme.primary,
    theme.secondary,
    theme.accentOne,
    theme.warning,
    theme.success,
    theme.danger,
    theme.info,
  ];

  useEffect(() => {
    if (yHeaders?.length && xHeaders && xValues && parentRef) {
      //   setLoading(true);
      const parentHeight = parentRef.current.clientHeight;
      const childHeight = parentHeight / numChildDivs;

      // Set the height of each child div
      const childDivs = Array.from(parentRef.current.children);
      childDivs.forEach((childDiv) => {
        childDiv.style.height = `${childHeight}px`;
      });
    }
  }, [parentRef, yHeaders, xHeaders]);

  useEffect(() => {
    if (yHeaders?.length && !xValues) {
      let xMetricValues = [];
      for (let i = 0; i < yHeaders.length; i++) {
        let header = yHeaders[i];
        let xMetric = {};
        for (let j = 0; j < sampleMetricData.values.length; j++) {
          let metric = sampleMetricData.values[j];

          if (metric.comparedValues.find((x) => x.value === header)) {
            let metricName = metric.value;
            let metriccomparedValue = metric.comparedValues.find(
              (x) => x.value === header
            );
            if (metriccomparedValue) {
              let metricAmt = metriccomparedValue.amt;
              xMetric[metricName] = metricAmt;
            }
          }
        }
        xMetricValues.push(xMetric);
      }

      let valueResults = xMetricValues.map((x, i) => {
        return {
          metric: yHeaders[i],
          values: Object.entries(x).map((y, j) => {
            return { metric: y[0], amt: y[1], color: colors[j] };
          }),
        };
      });

      //sort valueResults by the cumulative values of the x.values.amts  in descending order
      const sortByCumulativeAmount = [...valueResults].sort((a, b) => {
        let cumulativeA = a.values.reduce(function (acc, curr) {
          return acc + parseFloat(curr.amt);
        }, 0);
        let cumulativeB = b.values.reduce(function (acc, curr) {
          return acc + parseFloat(curr.amt);
        }, 0);
        if (cumulativeA === cumulativeB) {
          // if b.values.length > a.values.length return b first and vice versa
          return b.values.length - a.values.length;
        } else {
          return cumulativeB - cumulativeA;
        }
      });

      let reOrderedYHeaders = sortByCumulativeAmount.map((x) => x.metric);
      console.log("X METRIC VALUES", valueResults);

      setYHeaders(reOrderedYHeaders);
      setXValues(sortByCumulativeAmount);
    }

    if (xValues && !!loading && !xHeaders) {
      let xHeaderMax = 0;
      let keys = [];
      for (const entry of xValues) {
        let max = 0;
        entry.values.forEach((x, i) => {
          if (!keys.find((y) => x.metric === y.metric)) {
            keys.push({ metric: x.metric, color: colors[i] });
          }
          if (x.amt) {
            max += x.amt;
          }
        });
        if (max > xHeaderMax) {
          xHeaderMax = max;
        }
      }
      console.log("X HEADER MAX", xHeaderMax, keys);

      setXHeaders([0, roundNumber(xHeaderMax, calculateBase(xHeaderMax))]);
      setKeys(keys);
      setLoading(false);
    }
  }, [xValues, xHeaders, loading]);

  return (
    <>
      {xHeaders && (
        <Grid item container xs={12} style={{ height: "100%" }}>
          <Grid
            item
            container
            xs={12}
            style={{
              height: "92%",
              padding: 2,
            }}
          >
            <Grid item container xs={12} style={{ justifyContent: "center" }}>
              <Grid
                item
                container
                xs={10}
                style={{
                  justifyContent: "space-between",
                  containerType: "inline-size",
                }}
              >
                <Typography
                  variant="h5"
                  style={{ fontWeight: "bold", fontSize: "2cqw" }}
                >
                  {sampleMetricData.metric.headerName} /{" "}
                  {sampleMetricData.metric.secondMetric}
                </Typography>
                <Typography
                  variant="h5"
                  style={{ fontWeight: "bold", fontSize: "1.25cqw" }}
                >
                  {sampleMetricData.metric.type}
                </Typography>
              </Grid>
            </Grid>
            {/* Y AXIS HEADERS */}
            <Grid
              item
              container
              xs={2}
              style={{
                height: "94%",
                borderRight: "1px solid grey",
                paddingRight: "1%",
                containerType: "inline-size",
                alignItems: "center",
                textWrap: "nowrap",
                overflowX: "hidden",
              }}
            >
              {yHeaders?.map((header, i) => {
                return (
                  // <div style={{width:'100%', height:``, containerType: "inline-size",}}>
                  <Typography
                    variant="caption"
                    style={{
                      width: "100%",
                      textAlign: "right",
                      fontSize: ".85cqh",
                      lineHeight: ".85cqh",
                    }}
                  >
                    {header.length > 19 ? header.slice(0, 17) + "..." : header}
                  </Typography>
                  // </div>
                );
              })}
            </Grid>

            {/* BARS */}
            <Grid
              item
              comtainer
              xs={9}
              style={{ height: "94%", borderBottom: "1px solid grey" }}
              ref={parentRef}
            >
              {[...Array(numChildDivs)].map((_, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexFlow: "row",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      height: "80%",
                      width: "100%",
                      display: "flex",
                      flexFlow: "row",
                      background: "#eeeeee",
                    }}
                  >
                    {xValues &&
                      xValues.length > 0 &&
                      xValues[index].values.map((x, i) => {
                        let color = x.color;
                        let amt = x.amt;
                        return (
                          <div
                            key={i}
                            style={{
                              height: "100%",
                              width: `${(amt / xHeaders[1]) * 100}%`,
                              backgroundColor: color,
                              containerType: "inline-size",
                              padding: "0px 3px",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: ".85cqh",
                                color: getColorContrast(color),
                                fontWeight: "bold",
                              }}
                            >
                              {x.amt}
                            </Typography>
                          </div>
                        );
                      })}
                  </div>
                </div>
              ))}
            </Grid>

            <Grid item container xs={2} style={{ height: "3%" }}></Grid>
            <Grid
              item
              container
              xs={9}
              style={{ height: "3%", justifyContent: "space-between" }}
            >
              <Typography variant="caption" style={{ textAlign: "left" }}>
                {xHeaders[0]}
              </Typography>

              {amtOfDisplayedMetrics > 3 && (
                <Typography variant="caption" style={{ fontSize: ".75cqh" }}>
                  <i>
                    (
                    {[
                      ...sampleMetricData.values[0].comparedValues.map(
                        (x) => x.value
                      ),
                    ].length - startingHeaders.length}
                    ) more unseen items
                  </i>
                </Typography>
              )}

              <Typography variant="caption" style={{ textAlign: "right" }}>
                {xHeaders[1]}
              </Typography>
            </Grid>
          </Grid>

          {/* KEY */}
          <Grid
            item
            container
            xs={12}
            style={{
              height: "8%",

              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {keys &&
              keys.length > 0 &&
              keys.map((key, i) => {
                let metric = key.metric;
                let color = colors[i];
                return (
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      padding: "0px 5px",
                    }}
                  >
                    <div
                      style={{
                        height: 10,
                        width: 10,
                        background: color,
                        marginRight: 10,
                      }}
                    ></div>
                    <Typography style={{ fontSize: 10 }}>{metric}</Typography>
                  </div>
                );
              })}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CustomBarChart;
