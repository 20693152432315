import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { getColorContrast } from "../Utils";

const ChartTwo = ({ theme, parentRefHeight, parentRefWidth, data, metric }) => {
  let height =
    parentRefHeight > parentRefWidth
      ? Math.floor(parentRefWidth * 0.8)
      : Math.floor(parentRefHeight * 0.8);
  let width =
    parentRefHeight > parentRefWidth
      ? Math.floor(parentRefWidth * 0.8)
      : Math.floor(parentRefHeight * 0.8);

  const [csvData, setCSVData] = useState(null);
  const [convertedData, setConvertedData] = useState(null);
  const colors = [theme.primary, theme.secondary, theme.accentOne];

  useEffect(() => {}, [width, height]);

  useEffect(() => {
    if (metric && !convertedData) {
      let values = [...metric.values.sort((a, b) => b.amt - a.amt)]
        .slice(0, 10)
        .map((v, i) => {
          return {
            ...v,
            fill: getCellColor(`${i}`),
          };
        });

      setConvertedData(values);
    }

    console.log("CONVERTED DATA", convertedData);
  }, [metric, convertedData]);

  const renderCustomizedTop = ({ x, y, width, value }) => {
    console.log("props from rct", x, width);
    return value ? (
      <text
        fill={getColorContrast(theme.primary)}
        style={{
          textAnchor: "middle",
          fontWeight: "bold",
        }}
        x={x + width / 2}
        y={y + 20}
      >
        {value}%
      </text>
    ) : null;
  };

  const value = ({ value }) => {
    if (!value || value === "") {
      return "N/A";
    }
    return value;
  };

  function getCellColor(graphIndex) {
    let color = "";
    //map through eack color in the colors array. If the graphIndex matches the colors index return that color. If the graphIndex is greater than the length of the colors array, start over from he first index. so if the graphIndex is 3, return the color at index 0, if the graphIndex is 4, return the color at index 1, and so on.
    if (graphIndex < colors.length) {
      color = colors[graphIndex];
    } else {
      color = colors[graphIndex % colors.length];
    }
    return color;
  }

  return (
    <>
      {/* <ResponsiveContainer width="100%" height="100%"> */}
      <PieChart width={width} height={height} data={convertedData}>
        <Pie
          data={convertedData}
          dataKey="amt"
          nameKey="value"
          cx="50%"
          cy="50%"
          innerRadius={(width * 0.8) / 6}
          outerRadius={(width * 0.8) / 3}
          fill={theme.primary}
          label
        ></Pie>
      </PieChart>
      {/* </ResponsiveContainer> */}
    </>
  );
};

export default ChartTwo;
