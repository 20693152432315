import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getColorContrast } from "../Utils";

const ChartOne = ({
  theme,
  parentRefHeight,
  parentRefWidth,
  data,
  metric,
  comparedWithin,
}) => {
  let height = Math.floor(parentRefHeight * 0.9);
  let width = Math.floor(parentRefWidth * 0.95);
  const [csvData, setCSVData] = useState(null);
  const [convertedData, setConvertedData] = useState(null);
  const [highlightedMetric, setHighlightedMetric] = useState(false); // Set highlighted metric onClick

  const colors = [
    theme.primary,
    theme.secondary,
    theme.accentOne,
    theme.warning,
    theme.success,
    theme.danger,
    theme.info,
  ];

  //   console.log("METRIC", metric, data, theme);
  //   console.log("DATA HEADERS", data.headers);
  //   console.log("DATA ROWS", data.rows);

  useEffect(() => {
    if (metric && !convertedData) {
      // Function to merge values with similar names
      const mergeValues = (values) => {
        const mergedValues = [];
        const processedNames = new Set();

        values.forEach((value) => {
          const lowercasedName = value.value.toLowerCase().replace(/\s/g, "");
          if (!processedNames.has(lowercasedName.trim())) {
            const similarValues = values.filter(
              (v) =>
                v.value.toLowerCase().replace(/\s/g, "").trim() ===
                lowercasedName.trim()
            );
            const maxValue = similarValues.reduce(
              (max, v) => {
                const capitalLettersCount = v.value.replace(
                  /[^A-Z]/g,
                  ""
                ).length;
                if (capitalLettersCount > max.capitalLettersCount) {
                  if (comparedWithin) {
                    return {
                      value: v.value,
                      amt: max.amt + v.amt,
                      comparedValues: max.comparedValues
                        ? max.comparedValues.concat(v.comparedValues)
                        : v.comparedValues,
                    };
                  }
                  return {
                    value: v.value,
                    amt: max.amt + v.amt,
                  };
                }
                if (comparedWithin) {
                  return {
                    value: v.value,
                    amt: max.amt + v.amt,
                    comparedValues: max.comparedValues
                      ? max.comparedValues.concat(v.comparedValues)
                      : v.comparedValues,
                  };
                }
                return { ...max, amt: max.amt + v.amt };
              },
              { value: "", capitalLettersCount: -1, amt: 0 }
            );

            mergedValues.push(maxValue);
            processedNames.add(lowercasedName);
          }
        });

        return mergedValues;
      };

      let values = [...metric.values.sort((a, b) => b.amt - a.amt)].slice(
        0,
        10
      );
      //   console.log("COMPARING WITHIN", comparedWithin, metric, values);
      let mergedValues = values;
      if (metric.metric.type === "Category") {
        mergedValues = mergeValues(values);
      }

      if (comparedWithin) {
        function combineComparedValues(mergedValues) {
          return mergedValues.map((entry) => {
            // Create an object to store combined comparedValues
            if (
              entry.combinedValues &&
              metric?.metric?.calculationMethod === "Percentiles"
            ) {
              let newObj = { ...entry }; // Create a copy of the original object
              entry.combinedValues.forEach((entry) => {
                newObj = { ...newObj, ...entry }; // Merge each entry in combinedValues into newObj
              });
              return newObj;
            }
            let combinedValues = {};
            // Loop through comparedValues array
            entry.comparedValues.forEach((comparedValue) => {
              // If value already exists, add the amount
              if (combinedValues.hasOwnProperty(comparedValue.value)) {
                combinedValues[comparedValue.value] += comparedValue.amt;
              } else {
                // Otherwise, create a new entry
                combinedValues[comparedValue.value] = comparedValue.amt;
              }
            });
            // Replace comparedValues array with combinedValues object
            entry.combinedValues = combinedValues;
            return entry;
          });
        }

        let mergedValuesWithCombined = combineComparedValues(mergedValues);

        mergedValues = mergedValuesWithCombined.map((obj) => {
          // Create an object with the initial value and amount
          let newObj;

          if (
            obj.combinedValues &&
            metric?.metric?.calculationMethod === "Percentiles"
          ) {
            newObj = obj;
            delete newObj.combinedValues; // Remove the combinedValues property from newObj
            delete newObj.comparedValues; // Remove the combinedValues property from newObj
            console.log(newObj);
            // debugger;
          } else {
            newObj = {
              value: obj.value,
              amt: obj.amt,
              ...obj.combinedValues,
              // ...obj,
            };
          }

          return newObj;
        });
      }

      mergedValues = mergedValues.sort((a, b) => b.amt - a.amt);

      console.log("MERGED VALUES", mergedValues);

      setConvertedData(mergedValues);
    }

    console.log("CONVERTED DATA", convertedData);
  }, [metric, convertedData]);

  useEffect(() => {}, [width, height]);

  //   console.log(convertedData);

  const renderCustomizedTop = (props, barColor, index) => {
    const { x, y, width, height, value } = props;
    // console.log("RENDER CUSTOMIZED TOP", width, height, value);
    console.log("RENDER CUSTOMIZED TOP EXTRAS", barColor);
    let colorOfBar = barColor ? barColor : colors[0];

    let color = "#000000";
    let yValue = y + 20;
    let xValue = x + width / 2;
    //if value is less than 10% of highest value, text fill will be black
    if (!comparedWithin) {
      let sortedData = [...convertedData].sort((a, b) => b.amt - a.amt);
      let highest = sortedData[0].amt;
      // console.log("VALUE", value);

      var matchingIndex = -1;

      // Iterate through the array
      for (var i = 0; i < convertedData.length; i++) {
        // Check if the current object's name matches the target value
        if (convertedData[i].amt === value) {
          // If a match is found, store the index and break out of the loop
          matchingIndex = i;
          break;
        }
      }

      //if value is less than 10% of highest value, text fill will be black
      color = getColorContrast(getCellColor(matchingIndex));
      yValue = y + 20;

      if (value < highest * 0.25) {
        color = "#000000";
        yValue = y - 20;
      }
    } else {
      color = getColorContrast(colorOfBar);
      if (width + 10 < height) {
        let buffer = index === 0 ? 5 : index * 4 + 10;
        // yValue = y - buffer - height;
        color = "#000000";
        xValue = index === 0 ? x + width + 10 : x + width + index * 10 + 10;
      }
    }

    function formatValue(value) {
      if (!isNaN(value) && !isNaN(parseFloat(value))) {
        // Check if value is a number or a string that can be converted to a number
        let floatValue = parseFloat(value);
        if (Number.isInteger(floatValue)) {
          // If the number is an integer, use toLocaleString()
          return floatValue.toLocaleString();
        } else {
          // If the number is a float, use toFixed(2)
          return floatValue.toFixed(2).toLocaleString();
        }
      } else {
        // If value is not a number, return it as it is
        return value;
      }
    }

    return value ? (
      <text
        fill={color}
        style={{
          textAnchor: "middle",
          fontWeight: "bold",
          fontSize: "12px",
        }}
        x={xValue}
        y={yValue}
      >
        {formatValue(value)}
      </text>
    ) : null;
  };

  const value = (value) => {
    if (!value || value === "") {
      return "N/A";
    }

    if (convertedData.length <= 5) {
      return value;
    } else {
      if (value.length > 9) {
        const words = value.split(" ");
        let shortenedValue = "";
        let totalChars = 0;
        for (let i = 0; i < words.length; i++) {
          const word = words[i];
          const wordChars = Math.min(
            Math.floor((9 - shortenedValue.length) / (words.length - i)),
            word.length
          );
          shortenedValue += word.substr(0, wordChars);
          totalChars += wordChars;
          if (shortenedValue.length >= 9) {
            break;
          }
        }
        while (shortenedValue.length < 9) {
          shortenedValue += " ";
        }
        return shortenedValue;
      } else {
        return value;
      }
    }
  };

  function getCellColor(graphIndex) {
    let color = "";
    let availColors = comparedWithin ? colors : colors.slice(0, 3);
    //map through eack color in the colors array. If the graphIndex matches the colors index return that color. If the graphIndex is greater than the length of the colors array, start over from he first index. so if the graphIndex is 3, return the color at index 0, if the graphIndex is 4, return the color at index 1, and so on.
    if (graphIndex < availColors.length) {
      color = availColors[graphIndex];
    } else {
      color = availColors[graphIndex % availColors.length];
    }
    return color;
  }

  return (
    <BarChart
      width={width}
      height={height}
      data={convertedData}
      layout={comparedWithin ? "vertical" : "horizontal"}
      onClick={() => setHighlightedMetric(!highlightedMetric)}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip active={highlightedMetric} />
      {comparedWithin && <Legend />}
      {!comparedWithin ? (
        <>
          <XAxis
            dataKey="value"
            tick={{ fontSize: convertedData?.length > 5 ? 12 : 14 }}
            tickFormatter={value}
          />
          <YAxis tick={{ fontSize: 8 }} />
        </>
      ) : (
        <>
          <YAxis
            dataKey="value"
            type="category"
            tick={{ fontSize: convertedData?.length > 5 ? 12 : 14 }}
            tickFormatter={value}
          />
          <XAxis
            tick={{ fontSize: 8 }}
            dataKey="amt"
            type="number"
            // tickFormatter={value}
            // domain={[0, 1000]}
          />
          {convertedData && (
            <>
              {Object.entries(convertedData[0])[2] !== null &&
              Object.entries(convertedData[0])[2] !== undefined ? (
                Object.entries(convertedData[0]) // Assuming all objects in data have the same keys
                  .filter((entry) => entry[0] !== "value" && entry[0] !== "amt") // Exclude "value" and "amt" keys
                  .map((entry, idx) => (
                    <Bar
                      key={idx}
                      dataKey={entry[0]}
                      stackId="a"
                      fill={getCellColor(idx)}
                    >
                      <LabelList
                        dataKey={entry[0]}
                        content={(e) =>
                          renderCustomizedTop(e, getCellColor(idx), idx)
                        }
                      ></LabelList>
                    </Bar>
                  ))
              ) : (
                <Bar dataKey="amt" stackId="a" fill={getCellColor(0)}>
                  <LabelList
                    dataKey={"amt"}
                    content={(e) => renderCustomizedTop(e, false, 0)}
                  ></LabelList>
                </Bar>
              )}
            </>
          )}
        </>
      )}
      {!comparedWithin ? (
        <Bar dataKey="amt">
          <LabelList dataKey={"amt"} content={renderCustomizedTop}></LabelList>
          {convertedData?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={getCellColor(index)} />
          ))}
        </Bar>
      ) : null}
    </BarChart>

    // <BarChart width={width} height={height} data={convertedData} >
    //   <CartesianGrid strokeDasharray="3 3" />
    //   <XAxis
    //     dataKey="value"
    //     tick={{ fontSize: convertedData?.length > 5 ? 12 : 14 }}
    //     tickFormatter={value}
    //   />
    //   <YAxis tick={{ fontSize: 8 }} />
    //   <Tooltip />
    //   {comparedWithin && <Legend />}
    //   {!comparedWithin ? (
    //     <Bar dataKey="amt">
    //       <LabelList dataKey={"amt"} content={renderCustomizedTop}></LabelList>
    //       {convertedData?.map((entry, index) => (
    //         <Cell key={`cell-${index}`} fill={getCellColor(index)} />
    //       ))}
    //     </Bar>
    //   ) : (
    //     <>
    //       {convertedData &&
    //         Object.keys(convertedData[0]) // Assuming all objects in data have the same keys
    //           .filter((key) => key !== "value" && key !== "amt") // Exclude "value" and "amt" keys
    //           .map((key, idx) => (
    //             <Bar
    //               key={idx}
    //               dataKey={key}
    //               stackId="a"
    //               fill={getCellColor(idx)}
    //             >
    //               <LabelList
    //                 dataKey={key}
    //                 content={(e) =>
    //                   renderCustomizedTop(e, getCellColor(idx), idx)
    //                 }
    //               ></LabelList>
    //             </Bar>
    //           ))}
    //     </>
    //   )}
    // </BarChart>
  );
};

export default ChartOne;
