// const primary = "#009688";
// const secondary = "#e7f7f9";
// const accentOne = "#0D62AD";
const primary = "#38a50d";
const secondary = "#afec5f";
const accentOne = "#094c61";
const success = "#2e7d32";
const info = "#1c5a96";
const warning = "#ffca28";
const danger = "#c20d00";
const black = "#000000";
const white = "#ffffff";

export const theme = {
  primary,
  secondary,
  accentOne,
  success,
  info,
  warning,
  danger,
  black,
  white,
};
