import React, { useState } from "react";
import firebase, { db } from "../../config/firebaseConfig";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Modal,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";

const SupportForm = ({ supportModalOpen, setSupportModalOpen }) => {
  const user = useSelector((state) => state.auth.userData);
  const [formData, setFormData] = useState({
    name: user?.name || "",
    email: user?.email || "",
    title: "",
    description: "",
    ticketNumber: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const issueTypes = [
    "General Inquery",
    "Technical Issue",
    "Billing and Payment",
    "Account Management",
    "Other",
  ];

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    setSupportModalOpen(false);
    setIsSubmitted(false);
    setFormData({
      name: user.name,
      email: user.email,
      title: "",
      description: "",
      ticketNumber: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let docRef = db.collection("supportTickets").doc();
    formData.ticketNumber = docRef.id;

    // Adding timestamp to the formData
    formData.timestamp = firebase.firestore.FieldValue.serverTimestamp();

    docRef
      .set(formData)
      .then(() => {
        setIsSubmitted(true);
      })
      .catch((error) => {
        // console.error("Error submitting support ticket:", error);
      });
  };

  if (!supportModalOpen) return null;

  return (
    <Modal
      open={!!supportModalOpen}
      onClose={() => setSupportModalOpen(!setSupportModalOpen)}
    >
      {isSubmitted ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Support Ticket Submitted
          </Typography>
          <Typography variant="body1" gutterBottom>
            Thank you, {formData.name}. Your ticket number is{" "}
            {formData.ticketNumber}. Our support team will get back to you soon.
          </Typography>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" align="center" gutterBottom>
            Submit a Ticket
          </Typography>
          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              alignItems: "center",
              flexFlow: "column",
            }}
          >
            <TextField
              fullWidth
              margin="normal"
              label="Full Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="issue-type-label">Issue Type</InputLabel>
              <Select
                labelId="issue-type-label"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
              >
                {issueTypes.map((type, index) => (
                  <MenuItem key={index} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              margin="normal"
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              multiline
              rows={4}
              required
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              small
              style={{
                width: "80%",
                borderRadius: 40,
                color: "white",
                background: "black",
                fontWeight: "bold",
              }}
            >
              Submit
            </Button>
          </form>
        </Box>
      )}
    </Modal>
  );
};

export default SupportForm;
