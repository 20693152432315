import React, { useEffect, useMemo, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Funnel,
  FunnelChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getColorContrast } from "../Utils";

const ChartFour = ({
  metric,
  data,
  dataAmt,
  theme,
  racePercentageEntries,
  parentRefHeight,
  parentRefWidth,
}) => {
  let height = Math.floor(parentRefHeight * 0.95);
  let width = Math.floor(parentRefWidth * 0.95);

  useEffect(() => {}, [width, height]);

  const [csvData, setCSVData] = useState(null);
  const [convertedData, setConvertedData] = useState(null);
  const colors = [theme.primary, theme.secondary, theme.accentOne];

  console.log("METRIC", metric, data, theme);
  console.log("DATA HEADERS", data.headers);
  console.log("DATA ROWS", data.rows);

  useEffect(() => {
    if (metric && !convertedData) {
      let values = [
        ...metric.values
          .filter((x) => !isNaN(x.amt))
          .sort((a, b) => b.amt - a.amt)
          .map((v, i) => {
            return {
              ...v,
              fill: getCellColor(`${i}`),
            };
          }),
      ];
      for (let index = 0; index < values.length; index++) {
        let value = values[index];
        let totalValue = values.reduce(function (acc, value) {
          acc += value.amt;
          return acc;
        }, 0);
        value.percentage = (value.amt / totalValue) * 100;
      }
      setConvertedData(values.slice(0, 10));
    }

    console.log("CONVERTED DATA", convertedData);
  }, [metric, convertedData]);

  useEffect(() => {}, [width, height]);

  console.log(convertedData);

  function getCellColor(graphIndex) {
    let color = "";
    //map through eack color in the colors array. If the graphIndex matches the colors index return that color. If the graphIndex is greater than the length of the colors array, start over from he first index. so if the graphIndex is 3, return the color at index 0, if the graphIndex is 4, return the color at index 1, and so on.
    if (graphIndex < colors.length) {
      color = colors[graphIndex];
    } else {
      color = colors[graphIndex % colors.length];
    }
    return color;
  }

  const renderCustomizedTop = ({ x, y, width, value }) => {
    console.log("props from rct", x, width);
    return value ? (
      <text
        fill={getColorContrast(theme.primary)}
        style={{
          textAnchor: "middle",
          fontWeight: "bold",
        }}
        x={x + width / 2}
        y={y + 20}
      >
        {value}%
      </text>
    ) : null;
  };

  return (
    <>
      <ResponsiveContainer width={height} height={width}>
        <FunnelChart layout="horizontal">
          {/* <Tooltip /> */}
          <Funnel
            data={convertedData}
            dataKey="amt"
            stroke="#fff"
            isAnimationActive
            labelLine
            orientation="horizontal"
          >
            <LabelList
              dataKey="value"
              fill="#000"
              labelLine
              position="right"
              stroke="none"
            />
          </Funnel>
        </FunnelChart>
      </ResponsiveContainer>
    </>
  );
};

export default ChartFour;
