import {
  CardMembership,
  Close,
  Edit,
  PhonelinkLockOutlined,
} from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../../config/firebaseConfig";

const SettingsModal = ({
  settingsModalOpen,
  setSettingsModalOpen,
  setSubModalOpen,
  subModalOpen,
}) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.userData);

  const [settingsClicked, setSettingsClicked] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const [subscriptionClicked, setSubscriptionClicked] = useState(false);
  const [passwordVerifyClicked, setPasswordVerifyClicked] = useState(false);
  const [disconnectAccountClicked, setDisconnectAccountClicked] =
    useState(false);
  const [editNameClicked, setEditNameClicked] = useState(false);
  const [editEmailClicked, setEditEmailClicked] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [editedEmail, setEditedEmail] = useState("");
  const [settingsName, setSettingsName] = useState(userData?.name);
  const [settingsEmail, setSettingsEmail] = useState(userData?.email);
  const [settingsSubscriptionTier, setSettingsSubscriptionTier] = useState("");

  useEffect(() => {}, [userData]);

  const handleSettingsModalClose = () => {
    console.log("close clicked");
    setSettingsClicked(false);
    setEditClicked(false);
    setSubscriptionClicked(false);
    setPasswordVerifyClicked(false);
    setDisconnectAccountClicked(false);
    setSettingsModalOpen(false);
  };

  const handleUpdateName = () => {
    setSettingsName(editedName);
    let userRef = db.collection(`users`).doc(userData.uid);

    userRef
      .update({ name: editedName })
      .then(setEditNameClicked(!editNameClicked));
  };

  const handleEditName = () => {
    console.log("Edit Name Clicked");
    setEditNameClicked(!editNameClicked);
  };

  const handleSubscriptionClicked = () => {
    console.log("Subscription Clicked");
    setSettingsClicked(true);
    // setSubscriptionClicked(true)
    setSubModalOpen(true);
  };

  const handlePasswordVerifyClicked = () => {
    console.log("Password Verify Clicked");
    // setLinkModalOpen(!linkModalOpen);
    // setPasswordVerifyClicked(!passwordVerifyClicked);
  };

  return (
    <div>
      <Modal open={!!settingsModalOpen}>
        <Grid
          item
          container
          xs={11}
          md={6}
          lg={4}
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            padding: 30,
            background: "white",
            outline: "none",
            // maxWidth: '30%',
          }}
          sx={{ maxWidth: { xs: "75%", md: "50%" } }}
        >
          <Grid item container style={{}}>
            <IconButton
              onClick={handleSettingsModalClose}
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                background: "black",
                color: "white",
              }}
            >
              <Close />{" "}
            </IconButton>
            {/* <img
          style={{ width: "25%", height: "auto" }}
          alt="logo"
          src="https://firebasestorage.googleapis.com/v0/b/bzzzrrrt-d8008.appspot.com/o/Bzzzrrrt-black-bg.png?alt=media&token=aff71c40-458c-4540-88a8-32da8ce3fd77"
        /> */}

            <Typography
              xs={12}
              variant="h5"
              style={{ marginBottom: 10, fontWeight: "bold" }}
            >
              Edit Account
            </Typography>
          </Grid>
          <hr
            style={{
              borderBottom: "1px solid black",
              margin: "10px 0",
              width: "inherit",
            }}
          />
          <Grid
            item
            container
            xs={6}
            style={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "center",
            }}
          >
            <img
              src={userData?.bzrtData?.profileImg}
              alt="profile_pic"
              style={{
                width: "60%",
                height: "auto",
                borderRadius: "50%",
                marginLeft: "20px",
                marginTop: 10,
                marginBottom: 10,
              }}
            />
            <Grid item container style={{ alignItems: "end" }}>
              {/* <Button variant='contained' small style={{maxHeight:25, width:'80%', borderRadius:40, boxShadow:'none', background:'#fdd001', color:'black', fontWeight:'bold', marginBottom:10}}>
              Change
            </Button> */}
            </Grid>
          </Grid>
          {editNameClicked ? (
            <Grid item container style={{ justifyContent: "start" }}>
              <Grid item container xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  label={settingsName}
                  variant="outlined"
                  style={{ marginBottom: 10, marginTop: 15 }}
                  onChange={(e) => setEditedName(e.target.value)}
                >
                  {" "}
                </TextField>
              </Grid>
              <Grid item container style={{ justifyContent: "center" }}>
                <Button
                  variant="contained"
                  small
                  style={{
                    maxHeight: 25,
                    marginRight: 10,
                    width: "40%",
                    borderRadius: 40,
                    boxShadow: "none",
                    background: "#fdd001",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  onClick={handleUpdateName}
                >
                  Confirm
                </Button>
                <Button
                  variant="contained"
                  small
                  style={{
                    maxHeight: 25,
                    width: "40%",
                    borderRadius: 40,
                    boxShadow: "none",
                    background: "lightgrey",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    setEditNameClicked(!editNameClicked);
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid item container style={{ justifyContent: "space-between" }}>
              <Typography
                xs={12}
                variant="body1"
                style={{ marginBottom: 10, marginTop: 15 }}
              >
                <b>Name:</b> {settingsName}{" "}
              </Typography>
              <Edit
                style={{ marginTop: 12, marginLeft: 8 }}
                onClick={handleEditName}
              />
            </Grid>
          )}

          <Grid item container style={{ justifyContent: "space-between" }}>
            <Typography xs={12} variant="body1" style={{ marginTop: 10 }}>
              <b>Email:</b> {settingsEmail}{" "}
            </Typography>
            {/* <Edit style={{ marginTop: 7, marginLeft: 8}} onClick={() => {setEditEmailClicked(!editEmailClicked)}}/> */}
          </Grid>

          <Grid item container>
            <Grid xs={10} item container style={{ justifyContent: "start" }}>
              <Typography
                xs={12}
                variant="body1"
                style={{ marginBottom: 10, marginTop: 15 }}
              >
                {" "}
                Subscription Tier:{" "}
              </Typography>
              <Typography
                xs={12}
                variant="body1"
                style={{
                  marginBottom: 10,
                  marginTop: 15,
                  marginLeft: 5,
                  fontWeight: "bold",
                  color: "#fdd001",
                  textShadow: "1px 1px 1px black",
                }}
              >
                {" "}
                {settingsSubscriptionTier}{" "}
              </Typography>
            </Grid>
            <Grid xs={2} item container style={{ justifyContent: "end" }}>
              {userData?.subscriptionTier <= 0 && (
                <CardMembership
                  style={{ marginTop: 15, marginLeft: 8 }}
                  onClick={handleSubscriptionClicked}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default SettingsModal;
