export const sampleData = [
  {
    name: "John Smith",
    age: 25,
    gender: "Male",
    race: "White",
    description: "John is a good person.",
    jobTitle: "CEO",
    salary: "250,000",
    veteranStatus: "Yes",
  },
  {
    name: "Jane Doe",
    age: 30,
    gender: "Female",
    race: "Black",
    description: "Jane is a good person.",
    jobTitle: "CTO",
    salary: "200,000",
    veteranStatus: "No",
  },
  {
    name: "Ryan Joe",
    age: 28,
    gender: "Male",
    race: "Black",
    description: "Joe is a good person too.",
    jobTitle: "CFO",
    salary: "$150,000",
    veteranStatus: "No",
  },
  {
    name: "Jill Mack",
    age: 44,
    gender: "Female",
    race: "White",
    description: "Jill is a good person. She has long hair and blue eyes.",
    jobTitle: "CMO",
    salary: "100,000",
    veteranStatus: "No",
  },
  {
    name: "Jake Hill",
    age: 26,
    gender: "Male",
    race: "Latino",
    description: "Jake is a good person. He has no name.",
    jobTitle: "SDM",
    salary: "150,000",
    veteranStatus: "No",
  },
  {
    name: "Jessica Hall",
    age: 25,
    gender: "Female",
    race: "Latino",
    description: "Jessica is a skilled engineer with a passion for building.",
    jobTitle: "Nurse",
    salary: "100,000",
    veteranStatus: "No",
  },
  {
    name: "Albert Smith",
    age: 45,
    gender: "Female",
    race: "White",
    description: "Jessica is a skilled chef with a passion for cooking.",
    jobTitle: "Nurse",
    salary: "100,000",
    veteranStatus: "No",
  },
  {
    name: "Jessica Smith",
    age: 31,
    gender: "Female",
    race: "Indian",
    description: "Jessica is a skilled nurse with a passion for healing.",
    jobTitle: "Nurse",
    salary: "100,000",
    veteranStatus: "No",
  },
];
export const companyDepartments = [
  {
    department: "Executive Management",
    titles: ["CEO", "COO", "CFO", "CIO", "CHRO", "Founder"],
  },
  {
    department: "Administration",
    titles: [
      "Administrative Services",
      "Office Management",
      "Facilities Management",
      "Reception",
    ],
  },
  {
    department: "Finance and Accounting",
    titles: ["Accounting", "Finance", "Treasury", "Audit", "Taxation"],
  },
  {
    department: "Human Resources",
    titles: [
      "Recruitment",
      "Employee Relations",
      "Training and Development",
      "Compensation and Benefits",
      "HR Operations",
    ],
  },
  {
    department: "Marketing",
    titles: [
      "Marketing Strategy",
      "Brand Management",
      "Digital Marketing",
      "Product Marketing",
      "Public Relations",
    ],
  },
  {
    department: "Sales",
    titles: [
      "Sales Operations",
      "Business Development",
      "Account Management",
      "Sales Enablement",
    ],
  },
  {
    department: "Information Technology (IT)",
    titles: [
      "IT Management",
      "Systems Administration",
      "Network Administration",
      "IT Security",
      "Help Desk/Technical Support",
    ],
  },
  {
    department: "Research and Development (R&D)",
    titles: ["Product Development", "Research", "Innovation", "Design"],
  },
  {
    department: "Customer Support",
    titles: ["Customer Service", "Customer Success", "Technical Support"],
  },
  {
    department: "Operations",
    titles: [
      "Supply Chain Management",
      "Logistics",
      "Manufacturing",
      "Quality Assurance",
    ],
  },
  {
    department: "Legal",
    titles: ["Legal Counsel", "Compliance", "Contracts"],
  },
  {
    department: "Public Relations (PR) and Communications",
    titles: [
      "Media Relations",
      "Internal Communications",
      "External Communications",
    ],
  },
  {
    department: "Quality Assurance (QA) and Testing",
    titles: ["Quality Control", "Testing and QA"],
  },
  {
    department: "Health and Safety",
    titles: ["Occupational Health and Safety"],
  },
  {
    department: "Procurement and Purchasing",
    titles: ["Procurement", "Purchasing"],
  },
  {
    department: "Project Management",
    titles: ["Project Planning", "Project Management Office (PMO)"],
  },
  {
    department: "Training and Development",
    titles: ["Employee Training", "Leadership Development"],
  },
  {
    department: "Strategic Planning",
    titles: ["Corporate Strategy", "Business Development"],
  },
  {
    department: "Data Analytics",
    titles: ["Business Intelligence", "Data Science"],
  },
  {
    department: "Social Responsibility and Sustainability",
    titles: ["Corporate Social Responsibility (CSR)", "Sustainability"],
  },
  { department: "Other", titles: ["Other"] },
];

export const diversityMetrics = [
  {
    name: "Gender Representation",
    description: "Percentage of employees of each gender across the company.",
    function: `function calculateGenderRepresentation(data) {
        let genderCounts = { Male: 0, Female: 0 };
        data.forEach(employee => {
          if (employee.Gender) {
              genderCounts[employee.Gender] += 1;
          }
        });
        return genderCounts;
      }`,
  },
  {
    name: "Race Representation",
    description: "Percentage of employees of each race across the company.",
    function: `function calculateRaceRepresentation(data) {
        let raceCounts = {};
        data.forEach(employee => {
          if (employee.Race && employee.Race in raceCounts) {
              raceCounts[employee.Race] += 1;
          } else if (employee.Race) {
              raceCounts[employee.Race] = 1;
          }
        });
        return raceCounts;
      }`,
  },
  {
    name: "Veteran Representation",
    description: "Percentage of employees who are veterans.",
    function: `function calculateVeteranRepresentation(data) {
        let veteranCount = 0;
        data.forEach(employee => {
          if (employee.Veteran === "Yes") {
              veteranCount += 1;
          }
        });
        return (veteranCount / data.length) * 100;
      }`,
  },
  {
    name: "Disability Representation",
    description: "Percentage of employees who have disabilities.",
    function: `function calculateDisabilityRepresentation(data) {
        let disabilityCount = 0;
        data.forEach(employee => {
          if (employee.Disability === "Yes") {
              disabilityCount += 1;
          }
        });
        return (disabilityCount / data.length) * 100;
      }`,
  },
  {
    name: "Department & Location Diversity Index",
    description:
      "The diversity index for Departments and Locations gives an idea of how diverse those areas are.",
    function: `function calculateDiversityIndex(data, key) {
        let diversityScores = {};
        data.forEach(employee => {
          if (!(employee[key] in diversityScores)) {
              diversityScores[employee[key]] = { races: {}, genders: {} };
          }
          let entry = diversityScores[employee[key]];
          entry.races[employee.Race] = (entry.races[employee.Race] || 0) + 1;
          entry.genders[employee.Gender] = (entry.genders[employee.Gender] || 0) + 1;
        });
        Object.keys(diversityScores).forEach(key => {
          diversityScores[key].score = Object.keys(diversityScores[key].races).length + Object.keys(diversityScores[key].genders).length;
        });
        return diversityScores;
      }`,
  },
  {
    name: "Average Salary by Gender and Race",
    description:
      "Calculated as the mean salary of employees, separated by Gender and then by Race.",
    function: `function calculateAverageSalaryByGenderAndRace(data) {
        let salaries = {};
        data.forEach(employee => {
          let genderRaceKey = \`\${employee.Gender}_\${employee.Race}\`;
          if (!salaries[genderRaceKey]) {
              salaries[genderRaceKey] = { totalSalary: 0, count: 0 };
          }
          salaries[genderRaceKey].totalSalary += parseFloat(employee.BaseSalary);
          salaries[genderRaceKey].count += 1;
        });
        Object.keys(salaries).forEach(key => {
          salaries[key].averageSalary = (salaries[key].totalSalary / salaries[key].count).toFixed(2);
        });
        return salaries;
      }`,
  },
];

export const sampleMetricData = {
  metric: {
    type: "Category",
    headerName: "Race",
    width: 140,
    field: "race",
    comparedWithin: true,
    secondMetric: "Location Name",
  },
  values: [
    {
      value: "White",
      amt: 692,
      index: [
        0, 1, 2, 4, 9, 10, 11, 12, 14, 15, 20, 26, 27, 29, 30, 32, 33, 34, 36,
        39, 40, 41, 43, 44, 46, 47, 48, 50, 51, 52, 54, 212, 213, 214, 215, 216,
        217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230,
        231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244,
        245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258,
        259, 260, 261, 262, 263, 264, 265, 266, 267, 268, 269, 270, 271, 272,
        273, 274, 275, 276, 277, 278, 279, 280, 281, 282, 283, 284, 294, 295,
        296, 297, 298, 299, 300, 301, 302, 315, 316, 350, 351, 357, 364, 367,
        368, 369, 370, 371, 372, 373, 374, 375, 376, 377, 378, 379, 380, 381,
        382, 383, 384, 385, 386, 387, 388, 389, 390, 391, 392, 393, 394, 395,
        396, 397, 418, 445, 447, 451, 457, 458, 459, 460, 461, 462, 463, 464,
        465, 466, 467, 468, 469, 470, 471, 472, 473, 474, 475, 476, 477, 478,
        479, 480, 481, 482, 483, 484, 485, 486, 487, 488, 489, 490, 491, 492,
        493, 494, 495, 497, 498, 499, 500, 503, 504, 505, 506, 507, 508, 509,
        510, 513, 514, 515, 516, 517, 524, 525, 526, 527, 528, 529, 530, 531,
        532, 533, 534, 535, 536, 537, 538, 539, 540, 541, 542, 543, 545, 546,
        550, 551, 553, 554, 558, 560, 561, 562, 565, 572, 574, 575, 577, 579,
        581, 582, 583, 584, 587, 588, 589, 590, 592, 594, 596, 598, 599, 600,
        601, 602, 606, 607, 608, 611, 613, 614, 616, 618, 620, 621, 622, 623,
        626, 629, 630, 634, 635, 636, 638, 725, 726, 727, 728, 729, 730, 731,
        732, 733, 734, 735, 736, 737, 738, 739, 740, 741, 742, 743, 744, 745,
        746, 747, 748, 749, 750, 751, 752, 753, 754, 755, 756, 757, 758, 759,
        760, 761, 762, 763, 764, 765, 766, 767, 768, 769, 770, 771, 772, 773,
        774, 777, 778, 780, 781, 783, 791, 799, 800, 801, 802, 803, 804, 806,
        809, 812, 820, 821, 822, 824, 829, 830, 831, 832, 834, 835, 840, 846,
        847, 849, 850, 852, 853, 854, 856, 859, 860, 861, 863, 864, 866, 867,
        868, 870, 871, 872, 874, 1032, 1033, 1034, 1035, 1036, 1037, 1038, 1039,
        1040, 1041, 1042, 1043, 1044, 1045, 1046, 1047, 1048, 1049, 1050, 1051,
        1052, 1053, 1054, 1055, 1056, 1057, 1058, 1059, 1060, 1061, 1062, 1063,
        1064, 1065, 1066, 1067, 1068, 1069, 1070, 1071, 1072, 1073, 1074, 1075,
        1076, 1077, 1078, 1079, 1080, 1081, 1082, 1083, 1084, 1085, 1086, 1087,
        1088, 1089, 1090, 1091, 1092, 1093, 1094, 1095, 1096, 1097, 1098, 1099,
        1100, 1101, 1102, 1103, 1104, 1114, 1115, 1116, 1117, 1118, 1119, 1120,
        1121, 1122, 1135, 1136, 1170, 1171, 1177, 1184, 1187, 1188, 1189, 1190,
        1191, 1192, 1193, 1194, 1195, 1196, 1197, 1198, 1199, 1200, 1201, 1202,
        1203, 1204, 1205, 1206, 1207, 1208, 1209, 1210, 1211, 1212, 1213, 1214,
        1215, 1216, 1217, 1238, 1265, 1267, 1271, 1277, 1278, 1279, 1280, 1281,
        1282, 1283, 1284, 1285, 1286, 1287, 1288, 1289, 1290, 1291, 1292, 1293,
        1294, 1295, 1296, 1297, 1298, 1299, 1300, 1301, 1302, 1303, 1304, 1305,
        1306, 1307, 1308, 1309, 1310, 1311, 1312, 1313, 1314, 1315, 1317, 1318,
        1319, 1320, 1323, 1324, 1325, 1326, 1327, 1328, 1329, 1330, 1333, 1334,
        1335, 1336, 1337, 1344, 1345, 1346, 1347, 1348, 1349, 1350, 1351, 1352,
        1353, 1354, 1355, 1356, 1357, 1358, 1359, 1360, 1361, 1362, 1363, 1365,
        1366, 1370, 1371, 1373, 1374, 1378, 1380, 1381, 1382, 1385, 1392, 1394,
        1395, 1397, 1399, 1401, 1402, 1403, 1404, 1407, 1408, 1409, 1410, 1412,
        1414, 1416, 1418, 1419, 1420, 1421, 1422, 1426, 1427, 1428, 1431, 1433,
        1434, 1436, 1438, 1440, 1441, 1442, 1443, 1446, 1449, 1450, 1454, 1455,
        1456, 1458, 1545, 1546, 1547, 1548, 1549, 1550, 1551, 1552, 1553, 1554,
        1555, 1556, 1557, 1558, 1559, 1560, 1561, 1562, 1563, 1564, 1565, 1566,
        1567, 1568, 1569, 1570, 1571, 1572, 1573, 1574, 1575, 1576, 1577, 1578,
        1579, 1580, 1581, 1582, 1583, 1584, 1585, 1586, 1587, 1588, 1589, 1590,
        1591, 1592, 1593, 1594, 1597, 1598, 1600, 1601, 1603, 1611, 1619, 1620,
        1621, 1622, 1623, 1624, 1626, 1629, 1632,
      ],
      camparedWithin: [
        "New Jersey Retail",
        "Illinois Retail",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Boston, MA Corporate Office",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Ohio Retail",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "North Carolina Home Based",
        "South Carolina Home Based",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Wisconsin Home Based",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Pennsylvania Retail",
        "North Carolina Retail",
        "North Carolina Retail",
        "Minnesota Retail",
        "Texas Retail",
        "New York Retail",
        "Virginia Retail",
        "Pennsylvania Retail",
        "Minnesota Retail",
        "South Carolina Retail",
        "North Carolina Retail",
        "New Jersey Retail",
        "Maryland Retail",
        "Ohio Retail",
        "California Retail",
        "Arizona Retail",
        "Virginia Retail",
        "Pennsylvania Retail",
        "Iowa Retail",
        "Oklahoma Retail",
        "South Carolina Retail",
        "New York Retail",
        "Kentucky Retail",
        "Florida Retail",
        "Connecticut Retail",
        "Kentucky Retail",
        "Iowa Retail",
        "Washington State  Retail",
        "New Jersey Retail",
        "Washington State  Retail",
        "Missouri Retail",
        "Florida Retail",
        "Arizona Retail",
        "New Jersey Retail",
        "North Carolina Retail",
        "California Retail",
        "New York Retail",
        "Pennsylvania Retail",
        "New York Retail",
        "Delaware Retail",
        "Minnesota Retail",
        "Washington State  Retail",
        "California Retail",
        "Missouri Retail",
        "Pennsylvania Retail",
        "Massachusetts Retail",
        "New Jersey Retail",
        "Colorado Retail",
        "Pennsylvania Retail",
        "Virginia Retail",
        "Maryland Retail",
        "Maryland Retail",
        "Pennsylvania Retail",
        "New Jersey Retail",
        "Massachusetts Retail",
        "Iowa Retail",
        "California Retail",
        "Connecticut Retail",
        "Kentucky Retail",
        "South Carolina Retail",
        "New Hampshire Retail",
        "Florida Retail",
        "Minnesota Retail",
        "Washington State  Retail",
        "Texas Retail",
        "Pennsylvania Retail",
        "Arizona Retail",
        "Florida Retail",
        "New Hampshire Retail",
        "Missouri Retail",
        "New York Retail",
        "New York Retail",
        "Georgia Retail",
        "Illinois Retail",
        "Pennsylvania Retail",
        "South Carolina Retail",
        "Florida Retail",
        "Missouri Retail",
        "Florida Retail",
        "Texas Retail",
        "Ohio Retail",
        "Kentucky Retail",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Utah Home Based",
        "California Home Based",
        "Texas Home Based",
        "Pennsylvania Home Based",
        "California Home Based",
        "Ohio Home Based",
        "South Carolina Home Based",
        "California Home Based",
        "Ohio Home Based",
        "New Jersey Home Based",
        "Colorado Home Based",
        "Michigan Home Based",
        "Massachusetts Home Based",
        "Colorado Home Based",
        "Virginia Home Based",
        "Georgia Home Based",
        "Washington State Home Based",
        "Ohio Home Based",
        "Ohio Home Based",
        "Michigan Home Based",
        "New Jersey Home Based",
        "Kentucky Home Based",
        "California Home Based",
        "Minnesota Home-based",
        "Minnesota Home-based",
        "Illinois Home Based",
        "Washington State Home Based",
        "Minnesota Home-based",
        "Florida Home Based",
        "Indiana Home Based",
        "Tennessee Home Based",
        "North Carolina Home Based",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "South Carolina Home Based",
        "Massachusetts Home Based",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Texas Home Based",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Maryland Home Base",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "Washington State Home Based",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Boston, MA Corporate Office",
        "New York Home Based",
        "Irvine, CA Corporate Office",
        "Pennsylvania Home Based",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "New York Home Based",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Illinois Home Based",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Florida Home Based",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Illinois Home Based",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "Colorado Retail",
        "California Retail",
        "South Carolina Retail",
        "New York Retail",
        "North Carolina Retail",
        "Ohio Retail",
        "Florida Retail",
        "Virginia Retail",
        "Delaware Retail",
        "Delaware Retail",
        "Oregon Retail",
        "New Hampshire Retail",
        "New Jersey Retail",
        "California Retail",
        "New Jersey Retail",
        "New Jersey Retail",
        "Pennsylvania Retail",
        "Virginia Retail",
        "New York Retail",
        "Missouri Retail",
        "New Jersey Retail",
        "South Carolina Retail",
        "Missouri Retail",
        "New Jersey Retail",
        "California Retail",
        "Pennsylvania Retail",
        "Texas Retail",
        "Washington State  Retail",
        "South Carolina Retail",
        "New York Retail",
        "Illinois Retail",
        "Virginia Retail",
        "Utah Retail",
        "New Jersey Retail",
        "New Jersey Retail",
        "Missouri Retail",
        "New York Retail",
        "Virginia Retail",
        "Iowa Retail",
        "New York Retail",
        "Texas Retail",
        "Missouri Retail",
        "Iowa Retail",
        "Texas Retail",
        "California Retail",
        "New Hampshire Retail",
        "Minnesota Retail",
        "Texas Retail",
        "Georgia Retail",
        "Georgia Retail",
        "Massachusetts Retail",
        "New Hampshire Retail",
        "Florida Retail",
        "Illinois Retail",
        "Colorado Retail",
        "California Home Based",
        "Boston, MA Corporate Office",
        "Illinois Home Based",
        "Oregon Home based",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Illinois Home Based",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "New Jersey Retail",
        "Illinois Retail",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Boston, MA Corporate Office",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Ohio Retail",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "North Carolina Home Based",
        "South Carolina Home Based",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Wisconsin Home Based",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Pennsylvania Retail",
        "North Carolina Retail",
        "North Carolina Retail",
        "Minnesota Retail",
        "Texas Retail",
        "New York Retail",
        "Virginia Retail",
        "Pennsylvania Retail",
        "Minnesota Retail",
        "South Carolina Retail",
        "North Carolina Retail",
        "New Jersey Retail",
        "Maryland Retail",
        "Ohio Retail",
        "California Retail",
        "Arizona Retail",
        "Virginia Retail",
        "Pennsylvania Retail",
        "Iowa Retail",
        "Oklahoma Retail",
        "South Carolina Retail",
        "New York Retail",
        "Kentucky Retail",
        "Florida Retail",
        "Connecticut Retail",
        "Kentucky Retail",
        "Iowa Retail",
        "Washington State  Retail",
        "New Jersey Retail",
        "Washington State  Retail",
        "Missouri Retail",
        "Florida Retail",
        "Arizona Retail",
        "New Jersey Retail",
        "North Carolina Retail",
        "California Retail",
        "New York Retail",
        "Pennsylvania Retail",
        "New York Retail",
        "Delaware Retail",
        "Minnesota Retail",
        "Washington State  Retail",
        "California Retail",
        "Missouri Retail",
        "Pennsylvania Retail",
        "Massachusetts Retail",
        "New Jersey Retail",
        "Colorado Retail",
        "Pennsylvania Retail",
        "Virginia Retail",
        "Maryland Retail",
        "Maryland Retail",
        "Pennsylvania Retail",
        "New Jersey Retail",
        "Massachusetts Retail",
        "Iowa Retail",
        "California Retail",
        "Connecticut Retail",
        "Kentucky Retail",
        "South Carolina Retail",
        "New Hampshire Retail",
        "Florida Retail",
        "Minnesota Retail",
        "Washington State  Retail",
        "Texas Retail",
        "Pennsylvania Retail",
        "Arizona Retail",
        "Florida Retail",
        "New Hampshire Retail",
        "Missouri Retail",
        "New York Retail",
        "New York Retail",
        "Georgia Retail",
        "Illinois Retail",
        "Pennsylvania Retail",
        "South Carolina Retail",
        "Florida Retail",
        "Missouri Retail",
        "Florida Retail",
        "Texas Retail",
        "Ohio Retail",
        "Kentucky Retail",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Utah Home Based",
        "California Home Based",
        "Texas Home Based",
        "Pennsylvania Home Based",
        "California Home Based",
        "Ohio Home Based",
        "South Carolina Home Based",
        "California Home Based",
        "Ohio Home Based",
        "New Jersey Home Based",
        "Colorado Home Based",
        "Michigan Home Based",
        "Massachusetts Home Based",
        "Colorado Home Based",
        "Virginia Home Based",
        "Georgia Home Based",
        "Washington State Home Based",
        "Ohio Home Based",
        "Ohio Home Based",
        "Michigan Home Based",
        "New Jersey Home Based",
        "Kentucky Home Based",
        "California Home Based",
        "Minnesota Home-based",
        "Minnesota Home-based",
        "Illinois Home Based",
        "Washington State Home Based",
        "Minnesota Home-based",
        "Florida Home Based",
        "Indiana Home Based",
        "Tennessee Home Based",
        "North Carolina Home Based",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "South Carolina Home Based",
        "Massachusetts Home Based",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Texas Home Based",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Maryland Home Base",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "Washington State Home Based",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Boston, MA Corporate Office",
        "New York Home Based",
        "Irvine, CA Corporate Office",
        "Pennsylvania Home Based",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "New York Home Based",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Illinois Home Based",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Florida Home Based",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Illinois Home Based",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "Colorado Retail",
        "California Retail",
        "South Carolina Retail",
        "New York Retail",
        "North Carolina Retail",
        "Ohio Retail",
        "Florida Retail",
        "Virginia Retail",
        "Delaware Retail",
        "Delaware Retail",
        "Oregon Retail",
        "New Hampshire Retail",
        "New Jersey Retail",
        "California Retail",
        "New Jersey Retail",
        "New Jersey Retail",
        "Pennsylvania Retail",
        "Virginia Retail",
        "New York Retail",
        "Missouri Retail",
        "New Jersey Retail",
        "South Carolina Retail",
        "Missouri Retail",
        "New Jersey Retail",
        "California Retail",
        "Pennsylvania Retail",
        "Texas Retail",
        "Washington State  Retail",
        "South Carolina Retail",
        "New York Retail",
        "Illinois Retail",
        "Virginia Retail",
        "Utah Retail",
        "New Jersey Retail",
        "New Jersey Retail",
        "Missouri Retail",
        "New York Retail",
        "Virginia Retail",
        "Iowa Retail",
        "New York Retail",
        "Texas Retail",
        "Missouri Retail",
        "Iowa Retail",
        "Texas Retail",
        "California Retail",
        "New Hampshire Retail",
        "Minnesota Retail",
        "Texas Retail",
        "Georgia Retail",
        "Georgia Retail",
        "Massachusetts Retail",
        "New Hampshire Retail",
        "Florida Retail",
        "Illinois Retail",
        "Colorado Retail",
        "California Home Based",
        "Boston, MA Corporate Office",
        "Illinois Home Based",
        "Oregon Home based",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Illinois Home Based",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
      ],
      comparedValues: [
        {
          value: "New Jersey Retail",
          amt: 26,
        },
        {
          value: "Illinois Retail",
          amt: 8,
        },
        {
          value: "Byhalia, Ms DC",
          amt: 54,
        },
        {
          value: "Boston, MA Corporate Office",
          amt: 172,
        },
        {
          value: "Ohio Retail",
          amt: 8,
        },
        {
          value: "North Carolina Home Based",
          amt: 4,
        },
        {
          value: "South Carolina Home Based",
          amt: 6,
        },
        {
          value: "Wisconsin Home Based",
          amt: 2,
        },
        {
          value: "Pennsylvania Retail",
          amt: 22,
        },
        {
          value: "North Carolina Retail",
          amt: 10,
        },
        {
          value: "Minnesota Retail",
          amt: 10,
        },
        {
          value: "Texas Retail",
          amt: 14,
        },
        {
          value: "New York Retail",
          amt: 22,
        },
        {
          value: "Virginia Retail",
          amt: 14,
        },
        {
          value: "South Carolina Retail",
          amt: 14,
        },
        {
          value: "Maryland Retail",
          amt: 6,
        },
        {
          value: "California Retail",
          amt: 16,
        },
        {
          value: "Arizona Retail",
          amt: 6,
        },
        {
          value: "Iowa Retail",
          amt: 10,
        },
        {
          value: "Oklahoma Retail",
          amt: 2,
        },
        {
          value: "Kentucky Retail",
          amt: 8,
        },
        {
          value: "Florida Retail",
          amt: 16,
        },
        {
          value: "Connecticut Retail",
          amt: 4,
        },
        {
          value: "Washington State  Retail",
          amt: 10,
        },
        {
          value: "Missouri Retail",
          amt: 16,
        },
        {
          value: "Delaware Retail",
          amt: 6,
        },
        {
          value: "Massachusetts Retail",
          amt: 6,
        },
        {
          value: "Colorado Retail",
          amt: 6,
        },
        {
          value: "New Hampshire Retail",
          amt: 10,
        },
        {
          value: "Georgia Retail",
          amt: 6,
        },
        {
          value: "Utah Home Based",
          amt: 2,
        },
        {
          value: "California Home Based",
          amt: 10,
        },
        {
          value: "Texas Home Based",
          amt: 4,
        },
        {
          value: "Pennsylvania Home Based",
          amt: 4,
        },
        {
          value: "Ohio Home Based",
          amt: 8,
        },
        {
          value: "New Jersey Home Based",
          amt: 4,
        },
        {
          value: "Colorado Home Based",
          amt: 4,
        },
        {
          value: "Michigan Home Based",
          amt: 4,
        },
        {
          value: "Massachusetts Home Based",
          amt: 4,
        },
        {
          value: "Virginia Home Based",
          amt: 2,
        },
        {
          value: "Georgia Home Based",
          amt: 2,
        },
        {
          value: "Washington State Home Based",
          amt: 6,
        },
        {
          value: "Kentucky Home Based",
          amt: 2,
        },
        {
          value: "Minnesota Home-based",
          amt: 6,
        },
        {
          value: "Illinois Home Based",
          amt: 10,
        },
        {
          value: "Florida Home Based",
          amt: 4,
        },
        {
          value: "Indiana Home Based",
          amt: 2,
        },
        {
          value: "Tennessee Home Based",
          amt: 2,
        },
        {
          value: "Irvine, CA Corporate Office",
          amt: 86,
        },
        {
          value: "Maryland Home Base",
          amt: 2,
        },
        {
          value: "New York Home Based",
          amt: 4,
        },
        {
          value: "Oregon Retail",
          amt: 2,
        },
        {
          value: "Utah Retail",
          amt: 2,
        },
        {
          value: "Oregon Home based",
          amt: 2,
        },
      ],
    },
    {
      value: "Hispanic or Latino",
      amt: 428,
      index: [
        37, 42, 53, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69,
        70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87,
        88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104,
        105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118,
        119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132,
        133, 134, 135, 136, 137, 138, 139, 140, 197, 198, 287, 288, 289, 290,
        291, 292, 293, 303, 304, 309, 314, 318, 321, 322, 323, 324, 325, 326,
        327, 328, 329, 330, 331, 332, 333, 334, 355, 359, 360, 361, 362, 398,
        399, 400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412,
        413, 414, 415, 416, 417, 518, 520, 521, 555, 567, 578, 593, 609, 610,
        615, 627, 628, 631, 632, 641, 642, 643, 644, 645, 646, 647, 648, 649,
        650, 651, 652, 653, 654, 655, 656, 657, 658, 659, 660, 661, 662, 663,
        664, 665, 666, 667, 668, 669, 670, 671, 672, 673, 674, 675, 676, 677,
        678, 679, 680, 681, 682, 683, 684, 685, 686, 687, 688, 784, 785, 786,
        787, 788, 793, 794, 795, 796, 807, 857, 862, 873, 875, 876, 877, 878,
        879, 880, 881, 882, 883, 884, 885, 886, 887, 888, 889, 890, 891, 892,
        893, 894, 895, 896, 897, 898, 899, 900, 901, 902, 903, 904, 905, 906,
        907, 908, 909, 910, 911, 912, 913, 914, 915, 916, 917, 918, 919, 920,
        921, 922, 923, 924, 925, 926, 927, 928, 929, 930, 931, 932, 933, 934,
        935, 936, 937, 938, 939, 940, 941, 942, 943, 944, 945, 946, 947, 948,
        949, 950, 951, 952, 953, 954, 955, 956, 957, 958, 959, 960, 1017, 1018,
        1107, 1108, 1109, 1110, 1111, 1112, 1113, 1123, 1124, 1129, 1134, 1138,
        1141, 1142, 1143, 1144, 1145, 1146, 1147, 1148, 1149, 1150, 1151, 1152,
        1153, 1154, 1175, 1179, 1180, 1181, 1182, 1218, 1219, 1220, 1221, 1222,
        1223, 1224, 1225, 1226, 1227, 1228, 1229, 1230, 1231, 1232, 1233, 1234,
        1235, 1236, 1237, 1338, 1340, 1341, 1375, 1387, 1398, 1413, 1429, 1430,
        1435, 1447, 1448, 1451, 1452, 1461, 1462, 1463, 1464, 1465, 1466, 1467,
        1468, 1469, 1470, 1471, 1472, 1473, 1474, 1475, 1476, 1477, 1478, 1479,
        1480, 1481, 1482, 1483, 1484, 1485, 1486, 1487, 1488, 1489, 1490, 1491,
        1492, 1493, 1494, 1495, 1496, 1497, 1498, 1499, 1500, 1501, 1502, 1503,
        1504, 1505, 1506, 1507, 1508, 1604, 1605, 1606, 1607, 1608, 1613, 1614,
        1615, 1616, 1627,
      ],
      camparedWithin: [
        "Florida Retail",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "California Retail",
        "Florida Retail",
        "California Retail",
        "Illinois Retail",
        "California Retail",
        "California Retail",
        "California Retail",
        "Florida Retail",
        "California Retail",
        "California Retail",
        "Arizona Retail",
        "Texas Retail",
        "Florida Retail",
        "Florida Retail",
        "Virginia Retail",
        "Texas Retail",
        "Florida Retail",
        "Florida Retail",
        "California Retail",
        "New York Retail",
        "California Retail",
        "California Retail",
        "Maryland Retail",
        "Oregon Retail",
        "North Carolina Retail",
        "California Retail",
        "New York Retail",
        "New York Retail",
        "Arizona Retail",
        "California Retail",
        "Colorado Retail",
        "Virginia Retail",
        "California Retail",
        "Oklahoma Retail",
        "California Retail",
        "Florida Retail",
        "Arizona Retail",
        "Virginia Retail",
        "California Retail",
        "California Retail",
        "Florida Retail",
        "Georgia Retail",
        "California Retail",
        "California Retail",
        "Virginia Retail",
        "Maryland Retail",
        "California Retail",
        "California Retail",
        "California Retail",
        "California Retail",
        "California Retail",
        "Florida Retail",
        "California Retail",
        "Texas Retail",
        "Virginia Retail",
        "Pennsylvania Retail",
        "California Retail",
        "Florida Retail",
        "Florida Retail",
        "Delaware Retail",
        "California Retail",
        "Florida Retail",
        "Arizona Retail",
        "California Retail",
        "Massachusetts Retail",
        "Florida Retail",
        "California Retail",
        "Florida Retail",
        "California Retail",
        "New Jersey Retail",
        "North Carolina Retail",
        "Oregon Retail",
        "Nevada Retail",
        "California Retail",
        "California Retail",
        "New York Retail",
        "New York Retail",
        "California Retail",
        "Texas Retail",
        "California Retail",
        "North Carolina Retail",
        "Connecticut Retail",
        "Texas Retail",
        "Florida Retail",
        "Illinois Retail",
        "Florida Retail",
        "California Retail",
        "California Retail",
        "California Retail",
        "Illinois Retail",
        "Florida Retail",
        "Nevada Retail",
        "Arizona Retail",
        "California Retail",
        "California Retail",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "California Home Based",
        "Iowa Home Based",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "New York Showroom",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "New York Showroom",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "California Home Based",
        "New York Showroom",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "California Retail",
        "North Carolina Retail",
        "Illinois Retail",
        "Arizona Retail",
        "Virginia Retail",
        "California Retail",
        "New York Retail",
        "California Retail",
        "Oregon Retail",
        "Texas Retail",
        "Oregon Retail",
        "Virginia Retail",
        "Florida Retail",
        "California Retail",
        "Florida Retail",
        "California Retail",
        "New Jersey Retail",
        "California Retail",
        "California Retail",
        "Arizona Retail",
        "North Carolina Retail",
        "Texas Retail",
        "Illinois Retail",
        "California Retail",
        "California Retail",
        "Illinois Retail",
        "Massachusetts Retail",
        "California Retail",
        "California Retail",
        "Florida Retail",
        "Texas Retail",
        "Texas Retail",
        "California Retail",
        "New Jersey Retail",
        "Illinois Retail",
        "California Retail",
        "Arizona Retail",
        "Florida Retail",
        "Arizona Retail",
        "Florida Retail",
        "California Retail",
        "California Retail",
        "Texas Retail",
        "Texas Retail",
        "Nevada Retail",
        "Oregon Retail",
        "California Retail",
        "Texas Retail",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "New York Home Based",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Texas Home Based",
        "Florida Retail",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "California Retail",
        "Florida Retail",
        "California Retail",
        "Illinois Retail",
        "California Retail",
        "California Retail",
        "California Retail",
        "Florida Retail",
        "California Retail",
        "California Retail",
        "Arizona Retail",
        "Texas Retail",
        "Florida Retail",
        "Florida Retail",
        "Virginia Retail",
        "Texas Retail",
        "Florida Retail",
        "Florida Retail",
        "California Retail",
        "New York Retail",
        "California Retail",
        "California Retail",
        "Maryland Retail",
        "Oregon Retail",
        "North Carolina Retail",
        "California Retail",
        "New York Retail",
        "New York Retail",
        "Arizona Retail",
        "California Retail",
        "Colorado Retail",
        "Virginia Retail",
        "California Retail",
        "Oklahoma Retail",
        "California Retail",
        "Florida Retail",
        "Arizona Retail",
        "Virginia Retail",
        "California Retail",
        "California Retail",
        "Florida Retail",
        "Georgia Retail",
        "California Retail",
        "California Retail",
        "Virginia Retail",
        "Maryland Retail",
        "California Retail",
        "California Retail",
        "California Retail",
        "California Retail",
        "California Retail",
        "Florida Retail",
        "California Retail",
        "Texas Retail",
        "Virginia Retail",
        "Pennsylvania Retail",
        "California Retail",
        "Florida Retail",
        "Florida Retail",
        "Delaware Retail",
        "California Retail",
        "Florida Retail",
        "Arizona Retail",
        "California Retail",
        "Massachusetts Retail",
        "Florida Retail",
        "California Retail",
        "Florida Retail",
        "California Retail",
        "New Jersey Retail",
        "North Carolina Retail",
        "Oregon Retail",
        "Nevada Retail",
        "California Retail",
        "California Retail",
        "New York Retail",
        "New York Retail",
        "California Retail",
        "Texas Retail",
        "California Retail",
        "North Carolina Retail",
        "Connecticut Retail",
        "Texas Retail",
        "Florida Retail",
        "Illinois Retail",
        "Florida Retail",
        "California Retail",
        "California Retail",
        "California Retail",
        "Illinois Retail",
        "Florida Retail",
        "Nevada Retail",
        "Arizona Retail",
        "California Retail",
        "California Retail",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "California Home Based",
        "Iowa Home Based",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "New York Showroom",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "New York Showroom",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "California Home Based",
        "New York Showroom",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "California Retail",
        "North Carolina Retail",
        "Illinois Retail",
        "Arizona Retail",
        "Virginia Retail",
        "California Retail",
        "New York Retail",
        "California Retail",
        "Oregon Retail",
        "Texas Retail",
        "Oregon Retail",
        "Virginia Retail",
        "Florida Retail",
        "California Retail",
        "Florida Retail",
        "California Retail",
        "New Jersey Retail",
        "California Retail",
        "California Retail",
        "Arizona Retail",
        "North Carolina Retail",
        "Texas Retail",
        "Illinois Retail",
        "California Retail",
        "California Retail",
        "Illinois Retail",
        "Massachusetts Retail",
        "California Retail",
        "California Retail",
        "Florida Retail",
        "Texas Retail",
        "Texas Retail",
        "California Retail",
        "New Jersey Retail",
        "Illinois Retail",
        "California Retail",
        "Arizona Retail",
        "Florida Retail",
        "Arizona Retail",
        "Florida Retail",
        "California Retail",
        "California Retail",
        "Texas Retail",
        "Texas Retail",
        "Nevada Retail",
        "Oregon Retail",
        "California Retail",
        "Texas Retail",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "New York Home Based",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Texas Home Based",
      ],
      comparedValues: [
        {
          value: "Florida Retail",
          amt: 46,
        },
        {
          value: "Boston, MA Corporate Office",
          amt: 10,
        },
        {
          value: "California Retail",
          amt: 108,
        },
        {
          value: "Illinois Retail",
          amt: 14,
        },
        {
          value: "Arizona Retail",
          amt: 18,
        },
        {
          value: "Texas Retail",
          amt: 24,
        },
        {
          value: "Virginia Retail",
          amt: 14,
        },
        {
          value: "New York Retail",
          amt: 12,
        },
        {
          value: "Maryland Retail",
          amt: 4,
        },
        {
          value: "Oregon Retail",
          amt: 10,
        },
        {
          value: "North Carolina Retail",
          amt: 10,
        },
        {
          value: "Colorado Retail",
          amt: 2,
        },
        {
          value: "Oklahoma Retail",
          amt: 2,
        },
        {
          value: "Georgia Retail",
          amt: 2,
        },
        {
          value: "Pennsylvania Retail",
          amt: 2,
        },
        {
          value: "Delaware Retail",
          amt: 2,
        },
        {
          value: "Massachusetts Retail",
          amt: 4,
        },
        {
          value: "New Jersey Retail",
          amt: 6,
        },
        {
          value: "Nevada Retail",
          amt: 6,
        },
        {
          value: "Connecticut Retail",
          amt: 2,
        },
        {
          value: "Byhalia, Ms DC",
          amt: 56,
        },
        {
          value: "Irvine, CA Corporate Office",
          amt: 58,
        },
        {
          value: "California Home Based",
          amt: 4,
        },
        {
          value: "Iowa Home Based",
          amt: 2,
        },
        {
          value: "New York Showroom",
          amt: 6,
        },
        {
          value: "New York Home Based",
          amt: 2,
        },
        {
          value: "Texas Home Based",
          amt: 2,
        },
      ],
    },
    {
      value: "Black or African American",
      amt: 260,
      index: [
        3, 5, 6, 7, 8, 13, 16, 17, 18, 19, 21, 22, 23, 24, 25, 28, 49, 162, 163,
        164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177,
        178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191,
        192, 193, 194, 195, 196, 285, 286, 305, 306, 307, 308, 310, 311, 312,
        313, 317, 319, 320, 335, 336, 337, 338, 339, 340, 341, 342, 343, 344,
        345, 346, 347, 348, 349, 352, 353, 354, 356, 358, 363, 442, 443, 444,
        448, 449, 450, 452, 496, 501, 502, 511, 512, 563, 564, 566, 569, 570,
        571, 625, 637, 698, 699, 700, 701, 702, 703, 704, 705, 706, 707, 708,
        709, 710, 711, 712, 713, 714, 715, 716, 717, 776, 779, 792, 798, 823,
        825, 826, 827, 828, 833, 836, 837, 838, 839, 841, 842, 843, 844, 845,
        848, 869, 982, 983, 984, 985, 986, 987, 988, 989, 990, 991, 992, 993,
        994, 995, 996, 997, 998, 999, 1000, 1001, 1002, 1003, 1004, 1005, 1006,
        1007, 1008, 1009, 1010, 1011, 1012, 1013, 1014, 1015, 1016, 1105, 1106,
        1125, 1126, 1127, 1128, 1130, 1131, 1132, 1133, 1137, 1139, 1140, 1155,
        1156, 1157, 1158, 1159, 1160, 1161, 1162, 1163, 1164, 1165, 1166, 1167,
        1168, 1169, 1172, 1173, 1174, 1176, 1178, 1183, 1262, 1263, 1264, 1268,
        1269, 1270, 1272, 1316, 1321, 1322, 1331, 1332, 1383, 1384, 1386, 1389,
        1390, 1391, 1445, 1457, 1518, 1519, 1520, 1521, 1522, 1523, 1524, 1525,
        1526, 1527, 1528, 1529, 1530, 1531, 1532, 1533, 1534, 1535, 1536, 1537,
        1596, 1599, 1612, 1618,
      ],
      camparedWithin: [
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Maryland Retail",
        "Utah Retail",
        "Virginia Retail",
        "New Jersey Retail",
        "North Carolina Retail",
        "Virginia Retail",
        "Virginia Retail",
        "New York Retail",
        "Virginia Retail",
        "New Jersey Retail",
        "Virginia Retail",
        "Pennsylvania Retail",
        "Florida Retail",
        "Maryland Retail",
        "North Carolina Retail",
        "Pennsylvania Retail",
        "Virginia Retail",
        "Pennsylvania Retail",
        "Connecticut Retail",
        "Virginia Retail",
        "Texas Retail",
        "Virginia Retail",
        "Pennsylvania Retail",
        "Pennsylvania Retail",
        "Ohio Retail",
        "Virginia Retail",
        "California Retail",
        "California Retail",
        "Arizona Retail",
        "Maryland Retail",
        "New York Retail",
        "Texas Retail",
        "Virginia Retail",
        "North Carolina Retail",
        "Nevada Retail",
        "North Carolina Retail",
        "Florida Retail",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "New York Home Based",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "New York Retail",
        "Maryland Retail",
        "Ohio Retail",
        "North Carolina Retail",
        "Florida Retail",
        "North Carolina Retail",
        "North Carolina Retail",
        "Maryland Retail",
        "New Jersey Retail",
        "Virginia Retail",
        "New Jersey Retail",
        "Minnesota Retail",
        "California Retail",
        "Nevada Retail",
        "Virginia Retail",
        "Virginia Retail",
        "Florida Retail",
        "New Jersey Retail",
        "New York Retail",
        "Texas Retail",
        "Nevada Retail",
        "Texas Retail",
        "Washington DC Home Base",
        "Boston, MA Corporate Office",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Maryland Retail",
        "Utah Retail",
        "Virginia Retail",
        "New Jersey Retail",
        "North Carolina Retail",
        "Virginia Retail",
        "Virginia Retail",
        "New York Retail",
        "Virginia Retail",
        "New Jersey Retail",
        "Virginia Retail",
        "Pennsylvania Retail",
        "Florida Retail",
        "Maryland Retail",
        "North Carolina Retail",
        "Pennsylvania Retail",
        "Virginia Retail",
        "Pennsylvania Retail",
        "Connecticut Retail",
        "Virginia Retail",
        "Texas Retail",
        "Virginia Retail",
        "Pennsylvania Retail",
        "Pennsylvania Retail",
        "Ohio Retail",
        "Virginia Retail",
        "California Retail",
        "California Retail",
        "Arizona Retail",
        "Maryland Retail",
        "New York Retail",
        "Texas Retail",
        "Virginia Retail",
        "North Carolina Retail",
        "Nevada Retail",
        "North Carolina Retail",
        "Florida Retail",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "New York Home Based",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Byhalia, Ms DC",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "New York Retail",
        "Maryland Retail",
        "Ohio Retail",
        "North Carolina Retail",
        "Florida Retail",
        "North Carolina Retail",
        "North Carolina Retail",
        "Maryland Retail",
        "New Jersey Retail",
        "Virginia Retail",
        "New Jersey Retail",
        "Minnesota Retail",
        "California Retail",
        "Nevada Retail",
        "Virginia Retail",
        "Virginia Retail",
        "Florida Retail",
        "New Jersey Retail",
        "New York Retail",
        "Texas Retail",
        "Nevada Retail",
        "Texas Retail",
        "Washington DC Home Base",
        "Boston, MA Corporate Office",
      ],
      comparedValues: [
        {
          value: "Byhalia, Ms DC",
          amt: 132,
        },
        {
          value: "Maryland Retail",
          amt: 10,
        },
        {
          value: "Utah Retail",
          amt: 2,
        },
        {
          value: "Virginia Retail",
          amt: 26,
        },
        {
          value: "New Jersey Retail",
          amt: 10,
        },
        {
          value: "North Carolina Retail",
          amt: 14,
        },
        {
          value: "New York Retail",
          amt: 8,
        },
        {
          value: "Pennsylvania Retail",
          amt: 10,
        },
        {
          value: "Florida Retail",
          amt: 8,
        },
        {
          value: "Connecticut Retail",
          amt: 2,
        },
        {
          value: "Texas Retail",
          amt: 8,
        },
        {
          value: "Ohio Retail",
          amt: 4,
        },
        {
          value: "California Retail",
          amt: 6,
        },
        {
          value: "Arizona Retail",
          amt: 2,
        },
        {
          value: "Nevada Retail",
          amt: 6,
        },
        {
          value: "New York Home Based",
          amt: 2,
        },
        {
          value: "Irvine, CA Corporate Office",
          amt: 4,
        },
        {
          value: "Minnesota Retail",
          amt: 2,
        },
        {
          value: "Washington DC Home Base",
          amt: 2,
        },
        {
          value: "Boston, MA Corporate Office",
          amt: 2,
        },
      ],
    },
    {
      value: "Asian",
      amt: 176,
      index: [
        31, 45, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156,
        157, 158, 159, 160, 161, 365, 419, 420, 421, 422, 423, 424, 425, 426,
        427, 428, 429, 430, 431, 432, 433, 434, 435, 436, 437, 438, 439, 440,
        441, 522, 523, 549, 559, 568, 573, 576, 580, 585, 586, 591, 595, 597,
        603, 604, 605, 612, 617, 619, 624, 633, 639, 640, 690, 691, 692, 693,
        694, 695, 696, 697, 775, 797, 805, 808, 810, 811, 813, 814, 815, 816,
        817, 818, 819, 851, 865, 964, 965, 966, 967, 968, 969, 970, 971, 972,
        973, 974, 975, 976, 977, 978, 979, 980, 981, 1185, 1239, 1240, 1241,
        1242, 1243, 1244, 1245, 1246, 1247, 1248, 1249, 1250, 1251, 1252, 1253,
        1254, 1255, 1256, 1257, 1258, 1259, 1260, 1261, 1342, 1343, 1369, 1379,
        1388, 1393, 1396, 1400, 1405, 1406, 1411, 1415, 1417, 1423, 1424, 1425,
        1432, 1437, 1439, 1444, 1453, 1459, 1460, 1510, 1511, 1512, 1513, 1514,
        1515, 1516, 1517, 1595, 1617, 1625, 1628, 1630, 1631, 1633, 1634, 1635,
        1636, 1637, 1638, 1639,
      ],
      camparedWithin: [
        "Irvine, CA Corporate Office",
        "Oregon Home based",
        "Nevada Retail",
        "Hawaii Retail",
        "Hawaii Retail",
        "California Retail",
        "Florida Retail",
        "Hawaii Retail",
        "California Retail",
        "Nevada Retail",
        "Hawaii Retail",
        "California Retail",
        "Hawaii Retail",
        "Hawaii Retail",
        "Hawaii Retail",
        "Hawaii Retail",
        "New Jersey Retail",
        "Hawaii Retail",
        "Arizona Retail",
        "South Carolina Retail",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "New York Showroom",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "California Retail",
        "Delaware Retail",
        "Texas Retail",
        "California Retail",
        "Hawaii Retail",
        "California Retail",
        "California Retail",
        "California Retail",
        "Connecticut Retail",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "ã‚¢ã‚·ãƒƒã‚¯ã‚¹æœ¬ç¤¾",
        "ï¼¡ï¼ªï¼°æœ¬ç¤¾",
        "ã‚¢ã‚·ãƒƒã‚¯ã‚¹æœ¬ç¤¾",
        "ã‚¢ã‚·ãƒƒã‚¯ã‚¹æœ¬ç¤¾",
        "ã‚¢ã‚·ãƒƒã‚¯ã‚¹æœ¬ç¤¾",
        "ã‚¢ã‚·ãƒƒã‚¯ã‚¹æœ¬ç¤¾",
        "Boston, MA Headquarters",
        "Irvine, CA Corporate Office",
        "Oregon Home based",
        "Nevada Retail",
        "Hawaii Retail",
        "Hawaii Retail",
        "California Retail",
        "Florida Retail",
        "Hawaii Retail",
        "California Retail",
        "Nevada Retail",
        "Hawaii Retail",
        "California Retail",
        "Hawaii Retail",
        "Hawaii Retail",
        "Hawaii Retail",
        "Hawaii Retail",
        "New Jersey Retail",
        "Hawaii Retail",
        "Arizona Retail",
        "South Carolina Retail",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "New York Showroom",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "California Retail",
        "Delaware Retail",
        "Texas Retail",
        "California Retail",
        "Hawaii Retail",
        "California Retail",
        "California Retail",
        "California Retail",
        "Connecticut Retail",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Byhalia, Ms DC",
        "ã‚¢ã‚·ãƒƒã‚¯ã‚¹æœ¬ç¤¾",
        "ï¼¡ï¼ªï¼°æœ¬ç¤¾",
        "ã‚¢ã‚·ãƒƒã‚¯ã‚¹æœ¬ç¤¾",
        "ã‚¢ã‚·ãƒƒã‚¯ã‚¹æœ¬ç¤¾",
        "ã‚¢ã‚·ãƒƒã‚¯ã‚¹æœ¬ç¤¾",
        "ã‚¢ã‚·ãƒƒã‚¯ã‚¹æœ¬ç¤¾",
        "Boston, MA Headquarters",
      ],
      comparedValues: [
        {
          value: "Irvine, CA Corporate Office",
          amt: 78,
        },
        {
          value: "Oregon Home based",
          amt: 2,
        },
        {
          value: "Nevada Retail",
          amt: 4,
        },
        {
          value: "Hawaii Retail",
          amt: 20,
        },
        {
          value: "California Retail",
          amt: 16,
        },
        {
          value: "Florida Retail",
          amt: 2,
        },
        {
          value: "New Jersey Retail",
          amt: 2,
        },
        {
          value: "Arizona Retail",
          amt: 2,
        },
        {
          value: "South Carolina Retail",
          amt: 2,
        },
        {
          value: "Boston, MA Corporate Office",
          amt: 20,
        },
        {
          value: "Byhalia, Ms DC",
          amt: 6,
        },
        {
          value: "New York Showroom",
          amt: 2,
        },
        {
          value: "Delaware Retail",
          amt: 2,
        },
        {
          value: "Texas Retail",
          amt: 2,
        },
        {
          value: "Connecticut Retail",
          amt: 2,
        },
        {
          value: "ã‚¢ã‚·ãƒƒã‚¯ã‚¹æœ¬ç¤¾",
          amt: 10,
        },
        {
          value: "ï¼¡ï¼ªï¼°æœ¬ç¤¾",
          amt: 2,
        },
        {
          value: "Boston, MA Headquarters",
          amt: 2,
        },
      ],
    },
    {
      value: "Two or More Races",
      amt: 60,
      index: [
        35, 38, 203, 204, 205, 206, 207, 208, 209, 210, 211, 366, 446, 453, 454,
        455, 456, 544, 547, 548, 552, 556, 557, 720, 721, 722, 723, 724, 782,
        790, 855, 858, 1023, 1024, 1025, 1026, 1027, 1028, 1029, 1030, 1031,
        1186, 1266, 1273, 1274, 1275, 1276, 1364, 1367, 1368, 1372, 1376, 1377,
        1540, 1541, 1542, 1543, 1544, 1602, 1610,
      ],
      camparedWithin: [
        "Boston, MA Corporate Office",
        "Massachusetts Retail",
        "Maryland Retail",
        "Florida Retail",
        "Oklahoma Retail",
        "Maryland Retail",
        "Arizona Retail",
        "North Carolina Retail",
        "California Retail",
        "Texas Retail",
        "Minnesota Retail",
        "Florida Home Based",
        "Byhalia, Ms DC",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Florida Retail",
        "Florida Retail",
        "Pennsylvania Retail",
        "South Carolina Retail",
        "Hawaii Retail",
        "Florida Retail",
        "Byhalia, Ms DC",
        "Boston, MA Corporate Office",
        "Massachusetts Retail",
        "Maryland Retail",
        "Florida Retail",
        "Oklahoma Retail",
        "Maryland Retail",
        "Arizona Retail",
        "North Carolina Retail",
        "California Retail",
        "Texas Retail",
        "Minnesota Retail",
        "Florida Home Based",
        "Byhalia, Ms DC",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Irvine, CA Corporate Office",
        "Boston, MA Corporate Office",
        "Boston, MA Corporate Office",
        "Florida Retail",
        "Florida Retail",
        "Pennsylvania Retail",
        "South Carolina Retail",
        "Hawaii Retail",
        "Florida Retail",
        "Byhalia, Ms DC",
      ],
      comparedValues: [
        {
          value: "Boston, MA Corporate Office",
          amt: 8,
        },
        {
          value: "Massachusetts Retail",
          amt: 2,
        },
        {
          value: "Maryland Retail",
          amt: 4,
        },
        {
          value: "Florida Retail",
          amt: 8,
        },
        {
          value: "Oklahoma Retail",
          amt: 2,
        },
        {
          value: "Arizona Retail",
          amt: 2,
        },
        {
          value: "North Carolina Retail",
          amt: 2,
        },
        {
          value: "California Retail",
          amt: 2,
        },
        {
          value: "Texas Retail",
          amt: 2,
        },
        {
          value: "Minnesota Retail",
          amt: 2,
        },
        {
          value: "Florida Home Based",
          amt: 2,
        },
        {
          value: "Byhalia, Ms DC",
          amt: 4,
        },
        {
          value: "Irvine, CA Corporate Office",
          amt: 14,
        },
        {
          value: "Pennsylvania Retail",
          amt: 2,
        },
        {
          value: "South Carolina Retail",
          amt: 2,
        },
        {
          value: "Hawaii Retail",
          amt: 2,
        },
      ],
    },
    {
      value: "Native Hawaiian or Other Pacific Islander",
      amt: 16,
      index: [
        141, 199, 200, 201, 202, 519, 718, 719, 961, 1019, 1020, 1021, 1022,
        1339, 1538, 1539,
      ],
      camparedWithin: [
        "Georgia Retail",
        "Hawaii Retail",
        "California Retail",
        "Hawaii Retail",
        "Hawaii Retail",
        "Irvine, CA Corporate Office",
        "Arizona Retail",
        "California Retail",
        "Georgia Retail",
        "Hawaii Retail",
        "California Retail",
        "Hawaii Retail",
        "Hawaii Retail",
        "Irvine, CA Corporate Office",
        "Arizona Retail",
        "California Retail",
      ],
      comparedValues: [
        {
          value: "Georgia Retail",
          amt: 2,
        },
        {
          value: "Hawaii Retail",
          amt: 6,
        },
        {
          value: "California Retail",
          amt: 4,
        },
        {
          value: "Irvine, CA Corporate Office",
          amt: 2,
        },
        {
          value: "Arizona Retail",
          amt: 2,
        },
      ],
    },
    {
      value: "American Indian or Alaska Native",
      amt: 8,
      index: [142, 143, 689, 789, 962, 963, 1509, 1609],
      camparedWithin: [
        "Kentucky Retail",
        "Arizona Retail",
        "New York Retail",
        "Byhalia, Ms DC",
        "Kentucky Retail",
        "Arizona Retail",
        "New York Retail",
        "Byhalia, Ms DC",
      ],
      comparedValues: [
        {
          value: "Kentucky Retail",
          amt: 2,
        },
        {
          value: "Arizona Retail",
          amt: 2,
        },
        {
          value: "New York Retail",
          amt: 2,
        },
        {
          value: "Byhalia, Ms DC",
          amt: 2,
        },
      ],
    },
  ],
};
