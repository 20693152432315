import React, { useState, useContext } from "react";
import { Grid, TextField, Typography, Modal, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { db, auth } from "../../config/firebaseConfig";
import { theme } from "../../global/theme";

const AuthModal = ({ handleLogin, getUserData, open, setOpen }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [loginOptions, setLoginOptions] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);

  const login = () => {
    handleLogin(email, password);
  };

  const signUp = () => {
    console.log(email, password);
    handleSignUp(email, password);
  };

  const handleSignUp = (email, password, data) => {
    auth
      .createUserWithEmailAndPassword(email, password)
      .then((user) => {
        console.log(user.user.uid);
        //setCurrentUser(user.user.uid);
        // const userObj = { ...data, uid: user.user.uid };
        // console.log("userObject from Auth Contenxt", userObj);
        db.collection("users")
          .doc(user.user.uid)
          .set({ email: email, uid: user.user.uid, name: name, phone: phone })
          .then(() => {
            getUserData(user.user);
          });
        console.log("This is the user id", user.user.uid);
      })
      .catch((err) => {
        if (err) {
          // setUserLoading(false)
          console.log("ERROR:", err.message);
        }
      });
  };

  return (
    <Modal
      open={!!open}
      onClose={() => {
        setOpen(!open);
      }}
    >
      <Grid
        container
        item
        xs={10}
        md={5}
        style={{
          outline: "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          borderRadius: "15px",
          padding: 30,
        }}
      >
        {!!loginOptions ? (
          <>
            <TextField
              name="email"
              variant="outlined"
              label="Email"
              value={email}
              onChange={(x) => setEmail(x.target.value)}
              style={{ width: "100%", marginBottom: 20 }}
            />
            <TextField
              name="password"
              type="password"
              variant="outlined"
              label="Password"
              value={password}
              onChange={(x) => setPassword(x.target.value)}
              style={{ width: "100%", marginBottom: 20 }}
            />

            <Button
              variant="contained"
              fullWidth
              style={{ backgroundColor: theme.primary, color: "white" }}
              onClick={login}
            >
              Let's Go!
            </Button>

            <Typography
              variant="caption"
              style={{ width: "100%", textAlign: "right" }}
            >
              Don't have an account?{" "}
              <span
                style={{ textDecoration: "underline" }}
                onClick={() => {
                  setLoginOptions(!loginOptions);
                }}
              >
                Sign Up!
              </span>
            </Typography>
          </>
        ) : (
          <>
            <TextField
              required
              name="fullName"
              type="text"
              variant="outlined"
              label="Full Name"
              value={name}
              onChange={(x) => setName(x.target.value)}
              style={{ width: "100%", marginBottom: 20 }}
            />

            <TextField
              required
              name="phone"
              type="tel"
              variant="outlined"
              label="Mobile Phone"
              value={phone}
              onChange={(x) => setPhone(x.target.value)}
              style={{ width: "100%", marginBottom: 20 }}
            />

            <TextField
              required
              name="email"
              type="email"
              variant="outlined"
              label="Email"
              value={email}
              onChange={(x) => setEmail(x.target.value)}
              style={{ width: "100%", marginBottom: 20 }}
            />

            <TextField
              required
              name="password"
              type="password"
              variant="outlined"
              label="Password"
              value={password}
              onChange={(x) => setPassword(x.target.value)}
              style={{ width: "100%", marginBottom: 20 }}
            />

            <Button
              variant="contained"
              fullWidth
              style={{ backgroundColor: theme.primary, color: "white" }}
              onClick={signUp}
              disabled={
                name === "" || email === "" || password === "" || phone === ""
              }
            >
              Sign up
            </Button>

            <Typography
              variant="caption"
              style={{ width: "100%", textAlign: "right" }}
            >
              Have an account?{" "}
              <span
                style={{ textDecoration: "underline" }}
                onClick={() => {
                  setLoginOptions(!loginOptions);
                }}
              >
                Sign In!
              </span>
            </Typography>
          </>
        )}
      </Grid>
    </Modal>
  );
};

export default AuthModal;
