import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  RadialBar,
  RadialBarChart,
  Tooltip,
  XAxis,
  YAxis,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { getColorContrast } from "../Utils";

const ChartThree = ({
  theme,
  parentRefHeight,
  parentRefWidth,
  data,
  metric,
  dataAmt,
}) => {
  let height =
    parentRefHeight < parentRefWidth
      ? Math.floor(parentRefWidth * 0.8)
      : Math.floor(parentRefHeight * 0.8);
  let width =
    parentRefHeight < parentRefWidth
      ? Math.floor(parentRefWidth * 0.8)
      : Math.floor(parentRefHeight * 0.8);
  const [csvData, setCSVData] = useState(null);
  const [convertedData, setConvertedData] = useState(null);

  console.log("METRIC", metric, data, theme);
  console.log("DATA HEADERS", data.headers);
  console.log("DATA ROWS", data.rows);

  useEffect(() => {
    if (metric && !convertedData) {
      let values = [
        ...metric.values
          .filter((x) => !isNaN(x.amt))
          .sort((a, b) => a.amt - b.amt),
      ];
      for (let index = 0; index < values.length; index++) {
        let value = values[index];
        let totalValue = values.reduce(function (acc, value) {
          acc += value.amt;
          return acc;
        }, 0);
        value.percentage = (value.amt / totalValue) * 100;
      }
      setConvertedData(values.slice(0, 10));
    }

    console.log("CONVERTED DATA", convertedData);
  }, [metric, convertedData]);

  useEffect(() => {}, [width, height]);

  console.log(convertedData);

  const renderCustomizedTop = ({ x, y, width, value }) => {
    //if value is less than 10% of highest value, text fill will be black
    let sortedData = [...convertedData].sort((a, b) => b.amt - a.amt);
    let highest = sortedData[0].amt;

    //if value is less than 10% of highest value, text fill will be black
    let color = getColorContrast(theme.primary);
    let yValue = y + 20;
    if (value < highest * 0.25) {
      color = "#000000";
      yValue = y - 20;
    }
  };

  let aspect = dataAmt > 3 ? 1 / 1 : 4 / 3;

  return (
    <>
      {/* <ResponsiveContainer width={width} aspect={aspect}> */}
      <RadialBarChart
        width={width}
        height={height}
        innerRadius="13%"
        outerRadius={width / 2}
        data={convertedData}
        startAngle={180}
        endAngle={0}
        style={{}}
      >
        <RadialBar
          style={{ marginTop: "15%" }}
          minAngle={15}
          label={{
            fill: getColorContrast(theme.primary),
            position: "insideStart",
          }}
          background
          clockWise={true}
          dataKey="percentage"
        >
          {convertedData &&
            convertedData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={theme.primary}
                value={entry.value + " - " + entry.percentage.toFixed(2) + "%"}
              />
            ))}
        </RadialBar>
        {/* <Legend
          iconSize={10}
          // width={width * 2}
          // height={height}
          layout="vertical"
          verticalAlign="middle"
          align="left"
        /> */}
        <Tooltip />
      </RadialBarChart>
      {/* </ResponsiveContainer> */}
    </>
  );
};

export default ChartThree;
