import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Grid,
  Container,
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  FormGroup,
  FormControlLabel,
  Link,
  IconButton,
  CircularProgress,
  Checkbox,
  Select,
  Menu,
  MenuItem,
  InputAdornment,
  Chip,
  InputLabel,
  Tooltip,
  Snackbar,
  Alert,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Import the checkmark icon from Material-UI
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import {
  DataGrid,
  GridCellEditStopReasons,
  GridColumnMenu,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import {
  Add,
  Article,
  ArticleOutlined,
  Category,
  Close,
  Delete,
  Edit,
  FilePresentRounded,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Save,
  SaveAlt,
  Sync,
  Upload,
  UploadFile,
} from "@mui/icons-material";
import { theme as defaultTheme } from "../global/theme";
import { motion } from "framer-motion";
import * as XLSX from "xlsx";
import { diversityMetrics } from "../global/sampleData";
import { useSelector, useDispatch } from "react-redux";
import firebase, { db, functions, storage } from "../config/firebaseConfig";
import { extractJsonContent } from "../Utils";
import axios from "axios";
import { setUserDataSets } from "../global/authActions";

function CustomUserItem(props) {
  const { myCustomHandler, myCustomValue } = props;
  return (
    <MenuItem onClick={myCustomHandler}>
      <ListItemIcon>
        <Sync fontSize="small" />
      </ListItemIcon>
      <ListItemText>{myCustomValue}</ListItemText>
    </MenuItem>
  );
}

const MyData = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.userData);
  const theme = userData?.theme ? userData.theme : defaultTheme;
  const userDataSets = useSelector((state) => state.auth.userDataSets);
  //   console.log("User DAta ===>", userData);
  //   console.log("User Dataset ===>", userDataSets);

  const fileInputRef = useRef(null);

  const [documents, setDocuments] = useState([]);
  const [currentDocument, setCurrentDocument] = useState(null);
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(0);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [dataHeaders, setDataHeaders] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  const [dataSetsModalOpen, setDataSetsModalOpen] = useState(false);
  const [suggestedMetrics, setSuggestedMetrics] = useState(null);
  const [isPrimary, setIsPrimary] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCategorizedByVizz, setLoadingCategorizedByVizz] =
    useState(false);
  const [addDataModalOpen, setAddDataModalOpen] = useState(false);
  const [uploadDataModalOpen, setUploadDataModalOpen] = useState(false);
  const [nameDataModalOpen, setNameDataModalOpen] = useState(false);

  const [newDataSetName, setNewDataSetName] = useState("");
  const [metricName, setMetricName] = useState("");
  const [metrics, setMetrics] = useState([]);
  const [selectedDataSet, setSelectedDataSet] = useState(null);
  const [chosenDataSet, setChosenDataSet] = useState(null); //used when uploading new files...
  const [chatHistory, setChatHistory] = useState([]);
  const [suggestionsModalOpen, setSuggestionsModalOpen] = useState(false);
  const [selectCategoriesModal, setSelectCategoriesModal] = useState(false);
  const [vizzSuggestions, setVizzSuggestions] = useState(null);
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);

  const [extraHeadersModalOpen, setExtraHeadersModalOpen] = useState(false);

  //DATA GRID STATES
  const [rowSelectedHighlight, setRowSelectedHighlight] = useState(false);
  const [cellChanges, setCellChanges] = useState([]);
  const [clickedHistory, setClickedHistory] = useState([]);
  const [selectedDocItems, setSelectedDocItems] = useState([]);
  const [isEditing, setIsEditeding] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [extraHeaders, setExtraHeaders] = useState([]);
  const [newDataHeaders, setNewDataHeaders] = useState([]);
  const [newRows, setNewRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [replaceValueModalOpen, setReplaceValueModalOpen] = useState(false);
  const [valueToReplace, setValueToReplace] = useState(null);
  const [selectedToReplace, setSelectedToReplace] = useState(null);
  const [newValueForReplace, setNewValueForReplace] = useState("");

  //used for ai suggestions.. may need to rename
  const [previewCSVData, setPreviewCSVData] = useState(null);
  const [csvData, setCSVData] = useState(null);

  const [menuAnchor, setMenuAnchor] = useState(null);
  const columnHeaderRef = useRef(null);

  const StyledDataGrid = styled(DataGrid)(({ Theme }) => ({
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: theme.secondary, // Your desired background color
      color: theme.primary, // Optional: change text color
    },
  }));

  function CustomColumnMenu(props) {
    console.log("CustomColumnMenu", props);
    return (
      <GridColumnMenu
        {...props}
        slots={{
          // Add new item
          columnMenuUserItem: CustomUserItem,
        }}
        slotProps={{
          columnMenuUserItem: {
            // set `displayOrder` for the new item
            displayOrder: 15,
            // Additional props
            myCustomValue: "Replace Value",
            myCustomHandler: () => {
              setValueToReplace(props?.colDef?.field);
              setReplaceValueModalOpen(true);
            },
          },
        }}
      />
    );
  }

  const handleReplaceValue = () => {
    setLoading(true);
    // setReplaceValueModalOpen(false);
    if (selectedToReplace && newValueForReplace) {
      const newRows = [...currentDocument.rows].map((row) => {
        if (row[valueToReplace] === selectedToReplace) {
          row[valueToReplace] = newValueForReplace;
        }
        return row;
      });

      console.log("newRows", newRows);
      console.log(
        "new rows check",
        newRows.filter((row) => row[valueToReplace] === selectedToReplace)
          .length,
        newRows.filter((row) => row[valueToReplace] === newValueForReplace)
          .length
      );

      db.collection("users")
        .doc(userData.uid)
        .collection("DataSets")
        .doc(selectedDataSet.name)
        .collection("files")
        .doc(currentDocument.id)
        .update({
          rows: newRows,
        })
        .then(() => {
          console.log("Document successfully updated!");
          setReplaceValueModalOpen(false);
          setLoading(false);
          setNewValueForReplace("");
          //   setHasUnsavedChanges(true);
        });
      //   setDataRows(newRows);
      //   setHasUnsavedChanges(true);
    }
  };

  useEffect(() => {
    if (userDataSets && userDataSets.length > 0) {
      console.log("user userDataSets", userDataSets);
      setSelectedDataSet(userDataSets[0]);
    }
  }, [userDataSets]);

  useEffect(() => {
    if (selectedDataSet) {
      console.log("Selected dataSet ", selectedDataSet);
      db.collection("users")
        .doc(userData.uid)
        .collection("DataSets")
        .doc(selectedDataSet.name)
        .collection("files")
        .orderBy("uploadedAt", "desc")
        // .limit(1)
        .get()
        .then(async (querySnapshot) => {
          if (querySnapshot.empty) {
            console.log("No files found");
          } else {
            setDocuments(
              querySnapshot.docs.map((doc) => {
                let data = doc.data();
                // console.log(doc.id, data);
                return { ...data, id: doc.id };
              })
            );
            // console.log(document);
            // setDocuments((prev) => [
            //   ...prev,
            //   {
            //     rows: document.rows,
            //     headers: document.headers,
            //     id: document.id,
            //     name: document.name,
            //   },
            // ]);
          }
        })
        .then(() => {
          setLoading(false);
        });
    } else {
      // setPreviewCSVData("");
    }
  }, [selectedDataSet]);

  useEffect(() => {
    if (documents.length > 0) {
      if (!currentDocument) {
        console.log("documents", documents);
        setCurrentDocument({
          ...documents[0],
          headers: documents[0].headers.map((r) => {
            if (r.field === "id") {
              return r;
            }
            return { ...r, editable: true };
          }),
          rows: documents[0].rows,
        });
      }
    }
  }, [documents, currentDocument]);

  useEffect(() => {
    if (dataHeaders.length > 0 && dataRows.length > 0) {
      let dataSet = {
        headers: dataHeaders,
        rows: dataRows,
      };
      setDocuments((prev) => [...prev, dataSet]);
    }
    console.log("DATA HEAEDERS", dataHeaders);
  }, [dataRows, dataHeaders]);

  useEffect(() => {
    console.log("THESE ARE THE DOCS", documents);
    if (documents.length > 0) {
      setCurrentDocument(documents[0]);
    }
  }, [documents]);

  useEffect(() => {
    if (!!isEditing) {
      setRowSelectedHighlight(true);
    }
  }, [isEditing, rowSelectedHighlight, selectedDocItems]);

  useEffect(() => {}, [currentDocument]);

  useEffect(() => {
    console.log("New Data Headers =>", newDataHeaders);
    console.log("New Data Rows =>", newRows);
    if (dataSetsModalOpen) {
      handleUploadV2();
    }
  }, [newDataHeaders, newRows]);

  useEffect(() => {
    if (metricName.includes(",")) {
      setMetricName("");
    }
  }, [metricName]);

  useEffect(() => {}, [extraHeaders]);

  // const CustomToastContent = ({ message }) => (
  //   <div style={{ display: "flex", alignItems: "center" }}>
  //     <CheckCircleIcon style={{ fontSize: "20px", marginRight: "8px" }} />{" "}
  //     {/* Adjust the icon size here */}
  //     <div>{message}</div>
  //   </div>
  // );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleFileSelect = (e) => {
    let files = e.target.files;
    console.log("FILES", files);
    if (!files[0]) return;
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("handling drag over");
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("handling drop", e);
    const files = e?.dataTransfer?.files || e?.target?.files;
    if (files && files.length > 0) {
      const fileTypes = [".xlsx", ".docx", ".csv", ".pdf"];
      const acceptedFile = files[0];
      const fileExtension = acceptedFile.name.split(".").pop();
      if (fileTypes.includes("." + fileExtension.toLowerCase())) {
        // console.log("file added", files[0]);
        setFileType(fileExtension);
        // console.log("accepted file:", acceptedFile);
        setFile(acceptedFile);
      } else {
        alert("Unsupported file type.");
      }
    }
  };

  const handleUploadClick = async () => {
    setLoading(true);
    if (selectedDataSet) {
      //! Just upload the file and add the filepath to the selectdataSet.documents array in firebase db
    } else {
      //! Create a new dataSet and upload the file and add the filepath to the selectdataSet.documents array in firebase
      if (
        file &&
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          let hs = [];
          let rs = [];

          workbook.SheetNames.forEach((sheetName) => {
            const sheet = workbook.Sheets[sheetName];
            const jsonSheet = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            console.log("Sheet name:", sheetName);
            //   console.log("Full JSON Sheet:", jsonSheet); // Log the full JSON sheet

            if (jsonSheet.length > 0 && jsonSheet[0].length > 0) {
              // Headers
              jsonSheet[0].forEach((header) => {
                if (header && !hs.some((h) => h.headerName === header)) {
                  hs.push({
                    field: header.replace(/\s+/g, "").toLowerCase(),
                    headerName: header,
                    width: 140,
                  });
                }
              });

              // Rows
              rs.push(
                ...jsonSheet.slice(1).map((item, index) => {
                  return {
                    id: index + 1,
                    ...item.reduce((acc, val, i) => {
                      acc[hs[i].field] = val;
                      return acc;
                    }, {}),
                  };
                })
              );
            }
          });
          hs.unshift({ field: "id", headerName: "ID", width: 70 });
          let first2Rows = rs.slice(0, 2);
          console.log("Headers:", hs);
          console.log("First 2 Rows:", first2Rows);

          //create a .csv format from hs and rs
          let csv = "";
          csv += hs.map((h) => h.headerName).join(",") + "\n";
          rs.forEach((r) => {
            csv += Object.values(r).join(",") + "\n";
          });

          let previewCSV = "";
          previewCSV += hs.map((h) => h.headerName).join(",") + "\n";
          first2Rows.forEach((r) => {
            previewCSV += Object.values(r).join(",") + "\n";
          });
          console.log("CSV:", previewCSV);
          setCSVData({ headers: hs, rows: rs });
          setPreviewCSVData(previewCSV);

          console.log("userData", userData);
          //! handle callable here... (TONIO)
          let messagePrompt = `My Suggesetions: ${metrics}\n\n
            My data:\n\n
            ${previewCSV}
			`;
          console.log("prompt ==>", messagePrompt);

          const handleVizz = async () => {
            try {
              const aiAssistant = functions.httpsCallable("AiAssistant");
              const result = await aiAssistant({
                chatHistory,
                userMessage: messagePrompt,
                part: 1,
              });

              if (result) {
                let aiMessage = result.data.data.find(
                  (message) => message.role === "assistant"
                );
                let vizzChat = aiMessage?.content[0]?.text?.value;
                // console.log("This is result ==>", vizzChat);

                let cleanedVizzChat = vizzChat
                  .replace(/`/g, "")
                  .replace(/javascript/g, "")
                  .replace(/json/g, "");

                //TODO (new code start)==================================================
                const jsonContent = extractJsonContent(cleanedVizzChat);
                console.log("json Content is ==>", jsonContent);

                let cleanedSuggesetions = JSON.parse(jsonContent);
                //TODO (new code end)==================================================

                // let cleanedSuggesetions = JSON.parse(cleanedVizzChat);

                console.log(
                  "viz chat converted is a ",
                  typeof cleanedSuggesetions,
                  cleanedSuggesetions
                );

                if (
                  cleanedSuggesetions &&
                  typeof cleanedSuggesetions === "object" &&
                  cleanedSuggesetions.length > 0
                ) {
                  cleanedSuggesetions = cleanedSuggesetions.map((s) => {
                    return { ...s, calculationMethod: "" };
                  });
                  setVizzSuggestions(cleanedSuggesetions);
                  setSuggestionsModalOpen(true);
                  setLoading(false);
                }
              } else {
                console.log("There is no result");
              }
            } catch (error) {
              console.log("Something went wrong", error);
            }
          };

          await handleVizz();

          // and update the db. Then set the most recent document to dataHeaders and dataRows in state.
          // then provide the suggestions to the user (we can play with this order... maybe do this first?).

          // setDataHeaders(hs);
          // setDataRows(rs);
        };

        reader.onerror = (error) => {
          console.error("Error reading file:", error);
        };

        reader.readAsBinaryString(file);
      } else {
        console.log("Unsupported file type or no file selected.");
      }
    }
  };

  const handleUploadClickV2 = async () => {
    setLoadingCategorizedByVizz(true);
    if (chosenDataSet) {
      console.log("we have a chosen Data set", chosenDataSet);
      //! Just upload the file and add the filepath to the selectdataSet.documents array in firebase db
      if (
        file &&
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        console.log("initializing");
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          let hs = [];
          let rs = [];

          workbook.SheetNames.forEach((sheetName) => {
            const sheet = workbook.Sheets[sheetName];
            const jsonSheet = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            console.log("Sheet name:", sheetName);
            //   console.log("Full JSON Sheet:", jsonSheet); // Log the full JSON sheet

            if (jsonSheet.length > 0 && jsonSheet[0].length > 0) {
              // Headers
              jsonSheet[0].forEach((header) => {
                if (header && !hs.some((h) => h.headerName === header)) {
                  hs.push({
                    field: header.replace(/\s+/g, "").toLowerCase(),
                    headerName: header,
                    width: 140,
                  });
                }
              });

              // Rows
              rs.push(
                ...jsonSheet.slice(1).map((item, index) => {
                  return {
                    id: index + 1,
                    ...item.reduce((acc, val, i) => {
                      acc[hs[i].field] = val;
                      return acc;
                    }, {}),
                  };
                })
              );
            }
          });
          hs.unshift({ field: "id", headerName: "ID", width: 70 });
          let first2Rows = rs.slice(0, 2);
          console.log("Headers:", hs);
          console.log("First 2 Rows:", first2Rows);

          //create a .csv format from hs and rs
          let csv = "";
          csv += hs.map((h) => h.headerName).join(",") + "\n";
          rs.forEach((r) => {
            csv += Object.values(r).join(",") + "\n";
          });

          let previewCSV = "";
          previewCSV += hs.map((h) => h.headerName).join(",") + "\n";
          first2Rows.forEach((r) => {
            previewCSV += Object.values(r).join(",") + "\n";
          });
          console.log("CSV:", previewCSV);

          let fileHeaderNames = hs
            .map((header) => header.headerName)
            .sort((a, b) => a.localeCompare(b));
          let selectedDataSetHeaders = [...chosenDataSet.metrics]
            .map((header) => header.headerName)
            .sort((a, b) => a.localeCompare(b));

          let isEqual = true;
          let extraFields = [];
          if (fileHeaderNames.length !== selectedDataSetHeaders.length) {
            isEqual = false;
            extraFields = fileHeaderNames.filter(
              (x) => !selectedDataSetHeaders.includes(x)
            );
          } else {
            for (let i = 0; i < fileHeaderNames.length; i++) {
              const header = fileHeaderNames[i];
              if (header !== selectedDataSetHeaders[i]) {
                isEqual = false;
                extraFields.push(`"${fileHeaderNames[i]}"`);
              }
            }
          }

          console.log(
            "headers are equal =>",
            isEqual,
            "extra fields =>",
            extraFields
          );

          if (!isEqual) {
            //this part will be all you my man..
            //We will need to open a modal that displays the extra headers with a checkbox next to it (could optianally be a MUI Chip component)
            console.log("we are not equal", extraFields);
            setExtraHeaders(extraFields);
            //that will allow the user to keep the extra fields,  or remove them. If not, move FWD to data set modal.
            setCSVData({ headers: hs, rows: rs });
            setPreviewCSVData(previewCSV);
            setNewDataHeaders(hs);
            setNewRows(rs);
            console.log("All Images have loaded");
            setDataSetsModalOpen(false);
            setExtraHeadersModalOpen(!extraHeadersModalOpen);
          } else {
            console.log("equal headers");
            setNewDataHeaders(hs);
            setNewRows(rs);
            setCSVData({ headers: hs, rows: rs });
          }

          //! dont do this yet... prob will need to tho.. lets just console for now to see if data is straight

          // console.log("userData", userData);

          // setDataHeaders(hs);
          // setDataRows(rs);
        };

        reader.onerror = (error) => {
          console.error("Error reading file:", error);
        };

        reader.readAsBinaryString(file);
      } else {
        console.log("Unsupported file type or no file selected.");
      }
    } else {
      //! Create a new dataSet and upload the file and add the filepath to the selectdataSet.documents array in firebase
      if (
        file &&
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          let hs = [];
          let rs = [];

          workbook.SheetNames.forEach((sheetName) => {
            const sheet = workbook.Sheets[sheetName];
            const jsonSheet = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            console.log("Sheet name:", sheetName);
            //   console.log("Full JSON Sheet:", jsonSheet); // Log the full JSON sheet

            if (jsonSheet.length > 0 && jsonSheet[0].length > 0) {
              // Headers
              jsonSheet[0].forEach((header) => {
                if (header && !hs.some((h) => h.headerName === header)) {
                  hs.push({
                    field: header.replace(/\s+/g, "").toLowerCase(),
                    headerName: header,
                    width: 140,
                  });
                }
              });

              // Rows
              rs.push(
                ...jsonSheet.slice(1).map((item, index) => {
                  return {
                    id: index + 1,
                    ...item.reduce((acc, val, i) => {
                      acc[hs[i].field] = val;
                      return acc;
                    }, {}),
                  };
                })
              );
            }
          });
          hs.unshift({ field: "id", headerName: "ID", width: 70 });
          let first2Rows = rs.slice(0, 2);
          console.log("Headers:", hs);
          console.log("First 2 Rows:", first2Rows);

          //create a .csv format from hs and rs
          let csv = "";
          csv += hs.map((h) => h.headerName).join(",") + "\n";
          rs.forEach((r) => {
            csv += Object.values(r).join(",") + "\n";
          });

          let previewCSV = "";
          previewCSV += hs.map((h) => h.headerName).join(",") + "\n";
          first2Rows.forEach((r) => {
            previewCSV += Object.values(r).join(",") + "\n";
          });
          console.log("CSV:", previewCSV);
          setCSVData({ headers: hs, rows: rs });
          setPreviewCSVData(previewCSV);

          console.log("userData", userData);
          //! handle callable here... (TONIO)
          let messagePrompt = `My data:\n\n${previewCSV}`;
          console.log("prompt ==>", messagePrompt);

          const handleVizz = async () => {
            try {
              const aiAssistant = functions.httpsCallable("AiAssistant");
              const result = await aiAssistant({
                chatHistory,
                userMessage: messagePrompt,
                part: 1.2,
              });

              if (result) {
                let aiMessage = result.data.data.find(
                  (message) => message.role === "assistant"
                );
                let vizzChat = aiMessage?.content[0]?.text?.value;
                // console.log("This is result ==>", vizzChat);

                let cleanedVizzChat = vizzChat
                  .replace(/`/g, "")
                  .replace(/javascript/g, "")
                  .replace(/json/g, "");

                //TODO (new code start)==================================================
                const jsonContent = extractJsonContent(cleanedVizzChat);
                console.log("json Content is ==> ", jsonContent);

                let cleanedHeaders = JSON.parse(jsonContent);
                //TODO (new code end)==================================================

                // let cleanedHeaders = JSON.parse(cleanedVizzChat);

                console.log(
                  "viz chat converted is a ",
                  typeof cleanedHeaders,
                  cleanedHeaders
                );

                if (
                  cleanedHeaders &&
                  typeof cleanedHeaders === "object" &&
                  cleanedHeaders.length > 0
                ) {
                  let headersWithCategory = hs.map((header) => {
                    return {
                      ...header,
                      type: cleanedHeaders.find(
                        (h) => h?.headerName === header?.headerName
                      )?.type,
                    };
                  });
                  console.log("headers with category", headersWithCategory);
                  setDataHeaders(headersWithCategory);
                  setSelectCategoriesModal(true);
                  setLoadingCategorizedByVizz(false);
                  setUploadDataModalOpen(false);
                  //   setLoading(false);
                }
              } else {
                console.log("There is no result");
              }
            } catch (error) {
              console.log("Something went wrong", error);
              setLoadingCategorizedByVizz(false);
            }
          };

          await handleVizz();

          // and update the db. Then set the most recent document to dataHeaders and dataRows in state.
          // then provide the suggestions to the user (we can play with this order... maybe do this first?).

          // setDataHeaders(hs);
          // setDataRows(rs);
        };

        reader.onerror = (error) => {
          console.error("Error reading file:", error);
        };

        reader.readAsBinaryString(file);
      } else {
        console.log("Unsupported file type or no file selected.");
      }
    }
  };

  const handleAdd = (suggestion, isDelete) => {
    if (
      selectedSuggestions.find((s) => s.metricName === suggestion.metricName)
    ) {
      setSelectedSuggestions((prev) =>
        prev.filter((s) => s.metricName !== suggestion.metricName)
      );
    } else {
      setSelectedSuggestions((prev) => [...prev, suggestion]);
    }
    if (isDelete) {
      setSelectedSuggestions((prev) =>
        prev.filter((s) => s.metricName !== suggestion.metricName)
      );
      setVizzSuggestions((prev) =>
        prev.filter((s) => s.metricName !== suggestion.metricName)
      );
    }
  };

  const handleUpload = async () => {
    setDataSetsModalOpen(false);
    setLoading(true);
    console.log("Here are the selected suggestions", selectedSuggestions);
    console.log("Here is the CSV data ==>", previewCSVData);
    const newMessagePrompt = `The data:\n ${previewCSVData}\n\n The metrics: ${selectedSuggestions}`;

    const aiAssistant = functions.httpsCallable("AiAssistant");
    const finalResult = await aiAssistant({
      chatHistory,
      userMessage: newMessagePrompt,
      part: 2,
    });

    if (finalResult) {
      let aiMessage = finalResult.data.data.find(
        (message) => message.role === "assistant"
      );
      console.log("final res", aiMessage);
      let vizzChat = aiMessage?.content[0]?.text?.value;
      console.log("This is result of the 2nd run ==>", vizzChat);

      let cleanedVizzChat = vizzChat
        .replace(/`/g, "")
        .replace(/javascript/g, "")
        .replace(/json/g, "");

      //TODO (new code start)==================================================
      const jsonContent = extractJsonContent(cleanedVizzChat);
      console.log("jsonContent => ", jsonContent);

      const cleanedMetrics = JSON.parse(jsonContent);
      //TODO (new code end)==================================================

      // let cleanedSuggesetions = JSON.parse(cleanedVizzChat);

      console.log("viz chat converted is a ", cleanedMetrics);

      if (
        cleanedMetrics &&
        typeof cleanedMetrics === "object" &&
        cleanedMetrics.length > 0
      ) {
        //Upload file to firebase storage, get the downlaod url
        let downloadURL;
        // console.log("DEBUGGER:", file);
        // debugger;
        var uploadTask = storage
          .ref(`users/${userData.uid}/data/${fileType}/${file.name}`)
          .put(file);
        uploadTask.on("state_changed", (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING:
              console.log("Upload is running");
              break;
            default:
              console.log("Upload completed!");
              break;
          }
        });
        await uploadTask.then(async (snapshot) => {
          console.log("File uploaded successfully");
          downloadURL = await snapshot.ref.getDownloadURL();
          console.log("File available at", downloadURL);
        });
        db.collection("users")
          .doc(userData.uid)
          .collection("DataSets")
          .doc(newDataSetName)
          .set(
            {
              name: newDataSetName,
              updatedAt: new Date(),
              fileType: fileType,
              rawFileType: file.type,
              metrics: cleanedMetrics,
              // files: [downloadURL],
            },
            { merge: true }
          )
          .then(() => {
            let data = {
              name: file.name,
              uploadedAt: new Date(),
              downloadURL: downloadURL,
              rawFileType: file.type,
            };
            if (fileType === "csv" || "xlsx") {
              data.headers = csvData.headers;
              data.rows = csvData.rows;
            }

            db.collection("users")
              .doc(userData.uid)
              .collection("DataSets")
              .doc(newDataSetName)
              .collection("files")
              .add(data);
          })
          .then(() => {
            //! Set all state back to original values after uploading a dataSet.
            setLoading(false);
            setDataSetsModalOpen(false);
            setSuggestedMetrics(null);
            setFile(null);
            setFileType(null);
            setPreviewCSVData(null);
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
            setLoading(false);
          });
      }
    }
  };

  const handleUploadV2 = async () => {
    setLoading(true);
    //Upload file to firebase storage, get the downlaod url
    let downloadURL;
    // console.log("DEBUGGER:", file);
    // debugger;
    var uploadTask = storage
      .ref(`users/${userData.uid}/data/${fileType}/${file.name}`)
      .put(file);
    uploadTask.on("state_changed", (snapshot) => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      console.log("Upload is " + progress + "% done");
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED:
          console.log("Upload is paused");
          break;
        case firebase.storage.TaskState.RUNNING:
          console.log("Upload is running");
          break;
        default:
          console.log("Upload completed!");
          break;
      }
    });
    await uploadTask.then(async (snapshot) => {
      console.log("File uploaded successfully");
      downloadURL = await snapshot.ref.getDownloadURL();
      console.log("File available at", downloadURL);
    });

    const dataSetName =
      newDataSetName.length > 0
        ? newDataSetName
        : dataSetsModalOpen
        ? chosenDataSet?.name
        : selectedDataSet?.name;
    const headers = newDataHeaders.length === 0 ? dataHeaders : newDataHeaders;

    if (newDataHeaders.length > 0 && dataSetsModalOpen) {
      let data = {
        name: file.name,
        uploadedAt: new Date(),
        downloadURL: downloadURL,
        rawFileType: file.type,
      };
      if (fileType === "csv" || "xlsx") {
        data.headers = csvData.headers;
        data.rows = csvData.rows;
      }

      db.collection("users")
        .doc(userData.uid)
        .collection("DataSets")
        .doc(dataSetName)
        .collection("files")
        .add(data)
        .then(() => {
          //! Set all state back to original values after uploading a dataSet.
          setLoading(false);
          setDataSetsModalOpen(false);
          setSelectCategoriesModal(false);
          setSuggestedMetrics(null);
          setFile(null);
          setFileType(null);
          setPreviewCSVData(null);
          setChosenDataSet(null);
          setDataSetsModalOpen(false);
          setLoadingCategorizedByVizz(false);
        })
        .then(() => {
          db.collection("users")
            .doc(userData.uid)
            .collection("DataSets")
            .get()
            .then((snapshot) => {
              const dataSets = snapshot.docs.map((doc) => doc.data());
              dispatch(setUserDataSets(dataSets));
            });
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
          setLoadingCategorizedByVizz(false);
          setLoading(false);
        });
    } else {
      db.collection("users")
        .doc(userData.uid)
        .collection("DataSets")
        .doc(dataSetName)
        .set(
          {
            name: dataSetName,
            updatedAt: new Date(),
            fileType: fileType,
            rawFileType: file.type,
            metrics: headers,
            // files: [downloadURL],
          },
          { merge: true }
        )
        .then(() => {
          let data = {
            name: file.name,
            uploadedAt: new Date(),
            downloadURL: downloadURL,
            rawFileType: file.type,
          };
          if (fileType === "csv" || "xlsx") {
            data.headers = csvData.headers;
            data.rows = csvData.rows;
          }

          db.collection("users")
            .doc(userData.uid)
            .collection("DataSets")
            .doc(dataSetName)
            .collection("files")
            .add(data);
        })
        .then(() => {
          //! Set all state back to original values after uploading a dataSet.
          setLoading(false);
          setDataSetsModalOpen(false);
          setSelectCategoriesModal(false);
          setSuggestedMetrics(null);
          setFile(null);
          setFileType(null);
          setPreviewCSVData(null);
        })
        .then(() => {
          db.collection("users")
            .doc(userData.uid)
            .collection("DataSets")
            .get()
            .then((snapshot) => {
              const dataSets = snapshot.docs.map((doc) => doc.data());
              dispatch(setUserDataSets(dataSets));
            });
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
          setLoading(false);
        });
    }
  };

  const handleUpdateDataSet = () => {
    // Update previous data set with new data
    if (newDataHeaders.length > 0) {
      console.log("extraHeaders", extraHeaders);
      handleUploadV2();
      setOpen(true); // Open the Snackbar
      // let removedHeaders = newDataHeaders.filter(
      //   (r) => !extraHeaders.includes(r.field)
      // );

      //Update the file in the database
    } else {
    }
  };

  const handleDeleteRow = () => {
    //selectedDocItems

    let newRows = [...currentDocument.rows].filter(
      (row) =>
        selectedDocItems.find((docItem) => docItem.id === row.id) === undefined
    );
    db.collection("users")
      .doc(userData.uid)
      .collection("DataSets")
      .doc(selectedDataSet.name)
      .collection("files")
      .doc(currentDocument.id)
      .set({ rows: newRows }, { merge: true })
      .then(() => {
        //fecth the doc from the db then update global state
        db.collection("users")
          .doc(userData.uid)
          .collection("DataSets")
          .doc(selectedDataSet.name)
          .collection("files")
          .doc(currentDocument.id)
          .get()
          .then((doc) => {
            let docData = doc.data();
            setCurrentDocument({
              id: doc.id,
              rows: docData.rows,
              headers: docData.headers,
            });
            setDocuments((prev) => [
              ...prev.filter((document) => document.id !== currentDocument.id),
              {
                rows: docData.rows,
                headers: docData.headers,
                id: doc.id,
              },
            ]);
            setSelectedDocItems([]);
          });
      });
  };

  const handleSaveChanges = async () => {
    await db
      .collection("users")
      .doc(userData.uid)
      .collection("DataSets")
      .doc(selectedDataSet.name)
      .collection("files")
      .doc(currentDocument.id)
      .set({ rows: currentDocument.rows }, { merge: true })
      .then(() => {
        setSelectedDocItems([]);
        setHasUnsavedChanges(false);
      })
      .catch((err) => {
        console.log(
          `Error saving changes to file ${currentDocument.id}: `,
          err
        );
      });

    // await db
    //   .collection("users")
    //   .doc(userData.uid)
    //   .collection("DataSets")
    //   .doc(selectedDataSet.name)
    //   .collection("files")
    //   .doc(currentDocument.id)
    //   .get()
    //   .then((doc) => {
    //     let docData = doc.data();
    //     setCurrentDocument({
    //       id: doc.id,
    //       rows: docData.rows,
    //       headers: docData.headers,
    //     });

    //   })
    //   .catch((err) => {
    //     console.log(`Error fetching changes at ${currentDocument.id}: `, err);
    //   });
  };

  const getCellValue = (rowId, field) => {
    // Find the row data by rowId
    const rowData = [...currentDocument.rows].find((row) => row.id === rowId);
    // Return the value of the specified field (column)
    return rowData ? rowData[field] : undefined;
  };

  const removeExtraHeader = (headers) => {
    if (!Array.isArray(headers)) return [];
    return headers.slice(1);
  };

  const handleChangeDataSet = (dataSet) => {
    console.log("dataSet", dataSet);
    setLoading(true);
    setCurrentDocument(null);
    setSelectedDataSet(dataSet);
    // setDocuments([]);
    // setSelectedDocItems([]);
  };

  const handleChangeDoc = (direction) => {
    if (direction === "prev") {
      if (currentDocumentIndex > 0) {
        setCurrentDocumentIndex(currentDocumentIndex - 1);
        setCurrentDocument({
          ...documents[currentDocumentIndex - 1],
          headers: documents[currentDocumentIndex - 1].headers.map((r) => {
            if (r.field === "id") {
              return r;
            }
            return { ...r, editable: true };
          }),
          rows: documents[currentDocumentIndex - 1].rows,
        });
      }
    } else {
      if (currentDocumentIndex < documents.length - 1) {
        setCurrentDocumentIndex(currentDocumentIndex + 1);
        setCurrentDocument({
          ...documents[currentDocumentIndex + 1],
          headers: documents[currentDocumentIndex + 1].headers.map((r) => {
            if (r.field === "id") {
              return r;
            }
            return { ...r, editable: true };
          }),
          rows: documents[currentDocumentIndex + 1].rows,
        });
      }
    }
  };

  return (
    <Container style={{ height: "calc(100vh - 65px)" }}>
      <Grid item container xs={12} style={{}}>
        <Grid item container xs={12} style={{ padding: 20 }}>
          <Grid item container xs={8} style={{ padding: 10 }}>
            {selectedDataSet && (
              <Select
                fullWidth
                variant="outlined"
                value={selectedDataSet?.name || ""}
              >
                {userDataSets?.map((dataSet, i) => (
                  <MenuItem
                    key={dataSet.name + i}
                    value={dataSet.name}
                    onClick={() => handleChangeDataSet(dataSet)}
                  >
                    {dataSet.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Grid>
          <Grid
            item
            container
            xs={4}
            style={{
              padding: 10,
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {!!isEditing ? (
              <Button
                style={{
                  height: 50,
                  borderRadius: 10,
                  backgroundColor: theme.secondary,
                  color: theme.primary,
                }}
                variant="contained"
                color="primary"
              >
                <Add /> Add Data
              </Button>
            ) : (
              <>
                <Tooltip title="Edit (BETA)">
                  <IconButton
                    style={{
                      backgroundColor: theme.primary,
                      color: theme.white,
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => setIsEditeding(!isEditing)}
                  >
                    <Edit style={{}} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add a File">
                  <IconButton
                    onClick={() => setAddDataModalOpen(!addDataModalOpen)}
                    style={{
                      marginLeft: 10,
                      color: theme.white,
                      backgroundColor: theme.primary,
                      zIndex: 10,
                    }}
                  >
                    <Add />
                  </IconButton>
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept=".csv,.xlsx,.json"
                    hidden
                    onChange={handleDrop}
                  />
                </Tooltip>
              </>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          style={{ padding: 20, position: "relative" }}
        >
          {documents.length === 0 ? (
            <motion.div
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              whileHover={
                !file && { scale: 0.99, opacity: 0.75, cursor: "pointer" }
              }
              style={{
                width: "100%",
                display: "flex",
                flexFlow: "column",
                background: "#c6c6c6",
                height: "75vh",
                border: `6px dashed ${theme.primary}`,
                borderRadius: 14,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {file ? (
                <Button
                  style={{
                    height: 50,
                    marginTop: 10,
                    borderRadius: 10,
                    backgroundColor: theme.primary,
                    color: theme.white,
                  }}
                  onClick={() => setDataSetsModalOpen(true)}
                >
                  <Upload style={{ marginRight: 5 }} /> Upload
                </Button>
              ) : (
                <>
                  <Typography style={{ color: theme.primary }}>
                    No Data Found! Please add a dataset to get started.
                  </Typography>
                  <Button
                    style={{
                      height: 50,
                      marginTop: 10,
                      borderRadius: 10,
                      backgroundColor: theme.primary,
                      color: theme.white,
                    }}
                    onClick={() => fileInputRef.current.click()}
                  >
                    <Add style={{ marginRight: 5 }} /> Add Data
                  </Button>
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept=".csv,.xlsx,.json"
                    hidden
                    onChange={handleDrop}
                  />
                </>
              )}
            </motion.div>
          ) : (
            <>
              {(selectedDocItems.length > 0 || !!isEditing) && (
                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    padding: 10,
                    justifyContent: "space-between",
                    background: theme.secondary,
                    borderRadius: "14px 14px 0px 0px",
                  }}
                >
                  <Grid
                    item
                    container
                    xs={6}
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="caption">
                      {selectedDocItems.length} Selected
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={6}
                    style={{
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      // onClick={handleDeleteRow}
                      onClick={handleSaveChanges} //this will change...
                      disabled={hasUnsavedChanges === false}
                      style={{
                        color: theme.white,
                        background: theme.success,
                        height: 40,
                        borderRadius: 40,
                        marginRight: "2%",
                        opacity: hasUnsavedChanges ? 1 : 0.3,
                      }}
                    >
                      <Save /> Save
                    </Button>

                    {selectedDocItems.length > 0 && (
                      <Button
                        onClick={handleDeleteRow}
                        style={{
                          color: theme.white,
                          background: theme.danger,
                          height: 40,
                          borderRadius: 40,
                          marginRight: "2%",
                        }}
                      >
                        <Delete /> Delete ({selectedDocItems.length})
                      </Button>
                    )}
                    <Tooltip title="Cancel">
                      <IconButton
                        onClick={() => {
                          setIsEditeding(!isEditing);
                          setSelectedDocItems([]);
                          setRowSelectedHighlight(false);
                        }}
                        style={{
                          background: theme.primary,
                          color: theme.white,
                          borderRadius: 40,
                          height: 40,
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
              {loading && <CircularProgress />}
              {currentDocument && (
                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    padding: 10,
                    alignItems: "center",
                    justifyContent: "space-between",
                    background: theme.secondary,
                    color: theme.primary,
                    borderRadius: "8px 8px 0px 0px",
                    border: "1px solid #ccc",
                  }}
                >
                  <IconButton
                    disabled={currentDocumentIndex === 0}
                    style={{
                      marginRight: 5,
                      opacity: currentDocumentIndex === 0 ? 0.3 : 1,
                      background: theme.secondary,
                      height: 40,
                      color: theme.primary,
                    }}
                    onClick={() => handleChangeDoc("prev")}
                  >
                    <KeyboardArrowLeft />
                  </IconButton>
                  <Typography>{currentDocument.name}</Typography>
                  <IconButton
                    disabled={documents.length - 1 === currentDocumentIndex}
                    style={{
                      opacity:
                        documents.length - 1 === currentDocumentIndex ? 0.3 : 1,
                      background: theme.secondary,
                      height: 40,
                      color: theme.primary,
                    }}
                    onClick={() => handleChangeDoc("next")}
                  >
                    <KeyboardArrowRight />
                  </IconButton>
                </Grid>
              )}
              {currentDocument && (
                <>
                  <StyledDataGrid
                    // onColumnHeaderDoubleClick={handleColumnHeaderDoubleClick}
                    rows={currentDocument.rows}
                    columns={currentDocument.headers}
                    // columns={currentDocument.headers.map((x) => {
                    //   return { ...x, sortable: false };
                    // })}
                    slots={{
                      columnMenu: CustomColumnMenu,
                    }}
                    processRowUpdate={(newRow, oldRow) => {
                      console.log("Old row:", oldRow);
                      console.log("Updated row:", newRow);
                      let newRows = [...currentDocument.rows];
                      newRows[oldRow.id - 1] = newRow;
                      setCurrentDocument({ ...currentDocument, rows: newRows });
                      setHasUnsavedChanges(true);
                      // Perform any additional actions with newRow here

                      // Return the updated row to finalize the update
                      return newRow;
                    }}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                      },
                    }}
                    pageSizeOptions={[10, 15, 25, 50, 100]}
                    //   onPageChange={(newProps) => setCurrentPage(newProps.page)}
                    editMode={isEditing && "cell"}
                    checkboxSelection={isEditing}
                    onCellEditStart={(params) => {
                      // console.log("params ==>", params);
                      params.cellMode = "edit";
                      console.log(
                        `Editing Cell at row ${params.id} and field ${params.field}, mode: ${params.cellMode}`
                      );
                      console.log("Editing Text ==>", params.value);
                    }}
                    onCellEditStop={(params, event) => {
                      console.log("Edited Text ==>", params, event);

                      // console.log(
                      //   `Cell at row ${params.id} and field ${params.field} is ${params.value}`
                      // );
                      const currentRowData = [...currentDocument.rows].find(
                        (row) => row.id === params.id
                      );

                      const currentValue = currentRowData
                        ? currentRowData[params.field]
                        : null;
                      console.log("current value", currentValue);

                      const specificCellValue = getCellValue(
                        params.id,
                        `${params.field}`
                      );

                      clickedHistory.push(params);
                      setClickedHistory(clickedHistory);
                      console.log("Clicked HISTORY =>", clickedHistory);

                      console.log("SPECIFIC VALUE", specificCellValue);
                      // Now you can compare `currentFormattedValue` with `e.formattedValue`
                      if (currentValue !== params.value) {
                        console.log("The Value has been updated.");
                        console.log("END OF EVENT", params);
                      } else {
                        console.log("The Value has not changed.");
                        console.log("END OF EVENT", params);
                      }
                    }}
                    rowSelection={rowSelectedHighlight}
                    onCellDoubleClick={(e) => {
                      if (isEditing) {
                        console.log("Cell Double-Click EVENT", e.cellMode);
                        // setRowSelectedHighlight(false);
                        if (e.field !== "__check__") {
                          // clickedHistory.push(e);
                          // setClickedHistory(clickedHistory);
                          // console.log("Clicked HISTORY =>", clickedHistory);
                        }
                      }
                    }}
                    onCellClick={(e, event) => {
                      //if row is not in setSelectedDocItems
                      if (e.field === "__check__") {
                        console.log("clicking this check box");
                        // setRowSelectedHighlight(true);
                        if (
                          !selectedDocItems.find(
                            (docItem) => docItem.id === e.id
                          )
                        ) {
                          setSelectedDocItems((prev) => [...prev, e.row]);
                        } else {
                          setSelectedDocItems((prev) =>
                            prev.filter((docItem) => docItem.id !== e.id)
                          );
                        }
                      } else {
                        // console.log(event);
                        event.defaultMuiPrevented = true;
                        event.defaultPrevented = true;
                        e.hasFocus = false;
                        console.log(e);
                        console.log(event);
                      }
                    }}
                    disableSelectionOnClick
                  />
                  {/* <CustomMenu
                    onClose={handleCloseMenu}
                    anchorRef={columnHeaderRef}
                  /> */}
                  {valueToReplace && (
                    <Modal
                      open={!!replaceValueModalOpen}
                      onClose={() => setReplaceValueModalOpen(false)}
                    >
                      <Grid
                        item
                        container
                        xs={11}
                        md={6}
                        lg={5}
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          borderRadius: 14,
                          background: theme.white,
                          outline: "none",
                          padding: 20,
                          justifyContent: "flex-end",
                        }}
                      >
                        <Typography
                          variant="h4"
                          style={{
                            width: "100%",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          Replace Cell Value
                        </Typography>

                        <Typography
                          variant="cation"
                          style={{
                            marginTop: 20,
                            width: "100%",
                            textAlign: "left",
                          }}
                        >
                          Select a Value to Replace
                        </Typography>
                        <Select
                          value={selectedToReplace || ""}
                          onChange={(e) => setSelectedToReplace(e.target.value)}
                          fullWidth
                          style={{ marginTop: 20 }}
                        >
                          {[
                            ...new Set(
                              currentDocument.rows.map(
                                (row) => row[valueToReplace]
                              )
                            ),
                          ].map((value) => (
                            <MenuItem value={value}>{value}</MenuItem>
                          ))}
                        </Select>

                        <TextField
                          variant="outlined"
                          label="New Value"
                          value={newValueForReplace || ""}
                          onChange={(e) =>
                            setNewValueForReplace(e.target.value)
                          }
                          fullWidth
                          style={{ marginTop: 20 }}
                        />
                        <Button
                          disabled={
                            newValueForReplace === "" ||
                            !valueToReplace ||
                            loading
                              ? true
                              : false
                          }
                          variant="contained"
                          style={{
                            background: theme.primary,
                            color: theme.white,
                            opacity:
                              newValueForReplace === "" ||
                              !valueToReplace ||
                              loading
                                ? 0.5
                                : 1,
                          }}
                          onClick={handleReplaceValue}
                        >
                          Replace
                        </Button>
                      </Grid>
                    </Modal>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>

      <Modal open={!!dataSetsModalOpen}>
        <Grid
          item
          container
          xs={11}
          md={6}
          lg={5}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 14,
            background: theme.white,
            outline: "none",
            padding: 20,
          }}
        >
          <Grid
            item
            container
            xs={12}
            style={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              style={{
                position: "absolute",
                top: -30,
                right: -30,
                color: theme.accentOne,
                background: theme.secondary,
              }}
              onClick={() => {
                setDataSetsModalOpen(false);
                setFile(null);
                setFileType(null);
                setChosenDataSet(null);
              }}
            >
              <Close />
            </IconButton>
            {userDataSets && userDataSets.length > 0 ? (
              <>
                <Typography variant="h5" style={{ marginBottom: 18 }}>
                  Select a Data Set
                </Typography>
                <Select
                  fullWidth
                  variant="outlined"
                  value={chosenDataSet?.name || ""}
                >
                  {userDataSets.map((dataSet, idx) => {
                    return (
                      <MenuItem
                        onClick={() => setChosenDataSet(dataSet)}
                        key={idx}
                        value={dataSet.name}
                      >
                        {dataSet.name}
                      </MenuItem>
                    );
                  })}
                </Select>

                {file ? (
                  <Grid
                    item
                    container
                    xs={12}
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      flexFlow: "row",
                      backgroundColor: "#ececec",
                      padding: "5%",
                      marginTop: "5%",
                      borderRadius: 10,
                    }}
                  >
                    <Grid item container xs={2} style={{}}>
                      <FilePresentRounded
                        style={{ height: 100, width: 100, color: "grey" }}
                      />
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography
                        style={{
                          justifyContent: "flex-start",
                          marginLeft: "10%",
                        }}
                      >
                        {file.name}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={4}
                      style={{ justifyContent: "flex-end" }}
                    >
                      {!loadingCategorizedByVizz && (
                        <Tooltip title={"Remove File"}>
                          <IconButton
                            onClick={() => {
                              setFile(null);
                              setFileType(null);
                            }}
                            style={{
                              justifyContent: "flex-start",
                              color: theme.danger,
                              backgroundColor: `${theme.danger}35`,
                              marginRight: 10,
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip
                        title={
                          !chosenDataSet
                            ? "Please select your data set"
                            : "Upload File"
                        }
                      >
                        <IconButton
                          disabled={!chosenDataSet}
                          onClick={() => {
                            handleUploadClickV2();
                          }}
                          style={{
                            justifyContent: "flex-start",
                            color: theme.white,
                            backgroundColor: loadingCategorizedByVizz
                              ? theme.secondary
                              : theme.primary,
                            opacity: !chosenDataSet ? 0.5 : 1,
                          }}
                        >
                          {loadingCategorizedByVizz ? (
                            <CircularProgress
                              size={24}
                              style={{ color: theme.primary }}
                            />
                          ) : (
                            <UploadFile />
                          )}
                        </IconButton>
                        <input
                          ref={fileInputRef}
                          type="file"
                          accept=".csv,.xlsx,.json"
                          hidden
                          onChange={handleDrop}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                ) : (
                  <motion.div
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    whileHover={
                      !file && { scale: 0.99, opacity: 0.75, cursor: "pointer" }
                    }
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexFlow: "column",
                      backgroundColor: "#c6c6c6",
                      padding: "5%",
                      marginTop: "5%",
                      borderRadius: 10,
                      border: `4px dashed ${theme.primary}`,
                    }}
                  >
                    <Typography style={{ marginTop: "5%" }}>
                      Please select a file to upload
                    </Typography>

                    <Tooltip title="Add a File">
                      <IconButton
                        onClick={() => fileInputRef.current.click()}
                        style={{
                          marginTop: "50%",
                          marginLeft: "30%",
                          color: theme.white,
                          backgroundColor: theme.primary,
                        }}
                      >
                        <Add />
                      </IconButton>
                      <input
                        ref={fileInputRef}
                        type="file"
                        accept=".csv,.xlsx,.json"
                        hidden
                        onChange={handleDrop}
                      />
                    </Tooltip>
                  </motion.div>
                )}
              </>
            ) : (
              <>
                <Typography>
                  You don't have any data sets yet. Create a new one to get
                  started.
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  placeholder="Enter a name for your Data Set"
                  autoComplete={false}
                  variant="outlined"
                  value={newDataSetName}
                  onChange={(e) => setNewDataSetName(e.target.value)}
                  style={{ marginTop: 10 }}
                />
              </>
            )}
          </Grid>

          {userDataSets?.length === 0 && (
            <Grid
              item
              container
              xs={12}
              style={{ marginTop: 10, justifyContent: "space-between" }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setDataSetsModalOpen(false);
                }}
                color="primary"
                style={{ backgroundColor: theme.primary }}
              >
                Cancel
              </Button>
              <Button
                disabled={
                  !file ||
                  (userDataSets?.length > 0 && !chosenDataSet) ||
                  (userDataSets?.length === 0 && !newDataSetName) ||
                  !!loadingCategorizedByVizz

                  // (userDataSets.length === 0 && (!newDataSetName || !metrics.length))
                }
                variant="contained"
                onClick={handleUploadClickV2} //needs to handle loading state
                color="primary"
                style={{ backgroundColor: theme.primary }}
              >
                {loadingCategorizedByVizz ? (
                  <CircularProgress
                    size={24}
                    style={{ marginRight: 5, color: theme.white }}
                  />
                ) : (
                  <Add style={{ marginRight: 5 }} />
                )}
                Apply
              </Button>
            </Grid>
          )}
        </Grid>
      </Modal>

      {suggestionsModalOpen && (
        <Modal
          open={
            suggestionsModalOpen &&
            vizzSuggestions &&
            vizzSuggestions.length > 0
          }
        >
          <Grid
            item
            container
            xs={11}
            md={6}
            lg={5}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: 14,
              background: theme.white,
              outline: "none",
              padding: 20,
              justifyContent: loading && "center",
              alignItems: loading && "center",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Typography
                  fullWidth
                  align="center"
                  style={{ fontWeight: "bold", textAlign: "center" }}
                >
                  Choose Metrics
                </Typography>
                <Typography
                  fullWidth
                  align="center"
                  style={{ textAlign: "center" }}
                >
                  Select your desired metrics by order of importance.
                </Typography>

                <Grid
                  container
                  style={{
                    marginTop: 20,
                    padding: 20,
                    border: `1px solid ${theme.secondary}`,
                    position: "relative",
                    maxHeight: 400,
                    overflowY: "scroll",
                  }}
                >
                  <Typography
                    variant="caption"
                    style={{
                      position: "sticky",
                      top: -21,
                      textAlign: "right",
                      width: "100%",
                      padding: 2,
                      background: theme.white,
                    }}
                  >
                    Selected: {selectedSuggestions.length}
                  </Typography>
                  {vizzSuggestions.map((suggestion, idx) => {
                    let isAdded = selectedSuggestions.find(
                      (s) => s.metricName === suggestion.metricName
                    );
                    return (
                      <Grid item key={idx} style={{ padding: 5 }}>
                        <Chip
                          style={{
                            backgroundColor: isAdded
                              ? theme.primary
                              : theme.grey,
                            margin: 2,
                          }}
                          label={suggestion.metricName}
                          onClick={() => handleAdd(suggestion)}
                          onDelete={() => handleAdd(suggestion, true)}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  style={{ justifyContent: "space-between", marginTop: 20 }}
                >
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: theme.secondary,
                      color: theme.primary,
                    }}
                    // onClick={handleUpload}
                  >
                    More Suggestions
                  </Button>

                  <Button
                    variant="contained"
                    style={{ backgroundColor: theme.primary }}
                    onClick={handleUpload}
                  >
                    Next{" "}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Modal>
      )}

      {suggestedMetrics && (
        <Modal open={!!openModal}>
          <Grid
            item
            container
            xs={11}
            md={6}
            lg={5}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: 14,
              background: theme.white,
              outline: "none",
              padding: 20,
              justifyContent: loading && "center",
              alignItems: loading && "center",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Typography
                  style={{
                    color: theme.primary,
                    fontWeight: "bold",
                    fontSize: 18,
                    padding: 10,
                    textAlign: "center",
                  }}
                >
                  Suggesetions:
                </Typography>
                <Typography style={{ textAlign: "center" }}>
                  🥳 We're done analyzing the data you provided!
                  <br />
                  Choose which metrics you'd like to visualize from the list
                  below in order of importance.
                </Typography>
                <Grid item xs={12} style={{ padding: 10 }}>
                  {suggestedMetrics.map((suggestion) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        style={{
                          padding: 10,
                          height: "fit-content",
                          display: "flex",
                          flexFlow: "row",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            // height: "100%",
                            width: 50,
                            padding: 10,
                            display: "flex",
                            flexFlow: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Checkbox />
                        </div>
                        <div
                          style={{
                            // height: "100%",
                            width: "calc(100% - 90px)",
                            padding: 10,
                            display: "flex",
                            flexFlow: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            fullWidth
                            style={{
                              color: theme.primary,
                              fontWeight: "bold",
                              fontSize: 16,
                            }}
                          >
                            {suggestion.name}
                          </Typography>
                          <Typography
                            fullWidth
                            style={{ color: theme.primary, fontSize: 14 }}
                          >
                            {suggestion.description}
                          </Typography>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  style={{ justifyContent: "flex-end" }}
                >
                  <Button
                    style={{
                      backgroundColor: theme.secondary,
                      color: theme.primary,
                      marginRight: 10,
                    }}
                    onClick={() => {
                      setOpenModal(false);
                      console.log("clicked cancel...");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      backgroundColor: theme.primary,
                      color: theme.white,
                    }}
                  >
                    <SaveAlt style={{ marginRight: 5 }} /> Save
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Modal>
      )}

      {dataHeaders && (
        <Modal open={!!selectCategoriesModal}>
          <Grid
            item
            container
            xs={11}
            md={6}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: 14,
              background: theme.white,
              outline: "none",
              padding: 20,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Typography style={{ textAlign: "center" }}>
                  🥳 We're done analyzing the data you provided!
                  <br />
                  Let's double-check to make sure we're categorizing your data
                  correctly.
                </Typography>
                <Grid item xs={12} style={{ padding: 10 }}>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{ maxHeight: "60vh", overflowY: "scroll" }}
                  >
                    {dataHeaders.map((header, idx) => {
                      return (
                        <Grid
                          item
                          container
                          xs={6}
                          style={{
                            justifyContent: "space-between",
                            flexFlow: "row",
                            padding: 10,
                          }}
                        >
                          <Grid
                            item
                            container
                            xs={12}
                            md={6}
                            style={{ alignItems: "center" }}
                          >
                            <Typography style={{ textAlign: "left" }}>
                              {header.headerName}
                            </Typography>
                          </Grid>
                          <Grid item container xs={12} md={6}>
                            <Select
                              disabled={idx === 0 ? true : false}
                              fullWidth
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Type"
                              //initial value set to header.type
                              value={header.type}
                              onChange={(e) => {
                                //update the header category by updating the state
                                const newHeaders = [...dataHeaders].map((h) => {
                                  if (h.headerName === header.headerName) {
                                    h.type = e.target.value;
                                  }
                                  return h;
                                });
                                setDataHeaders(newHeaders);
                              }}
                            >
                              <MenuItem disabled value={header.type}>
                                {header.type}
                              </MenuItem>
                              {[
                                "Category",
                                "Number",
                                "DateTime",
                                "Location",
                              ].map((headerType) => {
                                return (
                                  <MenuItem value={headerType}>
                                    {headerType}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  style={{ justifyContent: "flex-end" }}
                >
                  <Button
                    style={{
                      backgroundColor: theme.secondary,
                      color: theme.primary,
                      marginRight: 10,
                    }}
                    onClick={() => setSelectCategoriesModal(false)}
                  >
                    Cancel
                  </Button>

                  <Button
                    onClick={handleUploadV2}
                    style={{
                      backgroundColor: theme.primary,
                      color: theme.white,
                    }}
                  >
                    <SaveAlt style={{ marginRight: 5 }} /> Next
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Modal>
      )}

      <Modal open={!!extraHeadersModalOpen}>
        <Grid
          item
          container
          xs={11}
          md={6}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 14,
            background: theme.white,
            outline: "none",
            padding: 20,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <>
            <Typography style={{ textAlign: "center" }}>
              To add the extra categories, please select a data type and Click
              "Next"
              <br />
            </Typography>
            <Grid item xs={12} style={{ padding: 10 }}>
              <Grid
                item
                container
                xs={12}
                style={{ maxHeight: "60vh", overflowY: "scroll" }}
              >
                {extraHeaders.map((header) => {
                  return (
                    <Grid
                      item
                      container
                      xs={6}
                      style={{
                        justifyContent: "space-between",
                        flexFlow: "row",
                        padding: 10,
                      }}
                    >
                      <Grid
                        item
                        container
                        xs={12}
                        md={6}
                        style={{ alignItems: "center" }}
                      >
                        <Typography
                          style={{ textAlign: "left", fontWeight: "bold" }}
                        >
                          {header}
                        </Typography>
                      </Grid>
                      <Grid item container xs={12} md={6}>
                        <Select
                          fullWidth
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Type"
                          //initial value set to header.type
                          value={header.type}
                          onChange={(e) => {
                            //update the header category by updating the state
                            let newItem = {
                              field: header.toLowerCase(),
                              width: 140,
                              headerName: header,
                              type: e.target.value,
                            };
                            const newHeaders = [
                              ...chosenDataSet.metrics,
                              newItem,
                            ].map((h) => {
                              if (h.headerName === header.headerName) {
                                h.type = e.target.value;
                              }
                              return h;
                            });
                            console.log(newHeaders, dataHeaders);
                            setNewDataHeaders(newHeaders);
                          }}
                        >
                          <MenuItem disabled value={header.type}>
                            {header.type}
                          </MenuItem>
                          {["Category", "Number", "DateTime", "Location"].map(
                            (headerType) => {
                              return (
                                <MenuItem value={headerType}>
                                  {headerType}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                        <Snackbar
                          open={open}
                          autoHideDuration={6000}
                          onClose={() => {
                            handleClose();
                            setExtraHeadersModalOpen(false);
                          }}
                        >
                          <Alert
                            onClose={() => {
                              handleClose();
                              setExtraHeadersModalOpen(false);
                            }}
                            severity="success"
                            sx={{ width: "100%" }}
                          >
                            Your update was successful ✅
                          </Alert>
                        </Snackbar>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        md={6}
                        style={{ alignItems: "center" }}
                      >
                        <Close onClick={removeExtraHeader(header.headerName)} />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item container xs={12} style={{ justifyContent: "flex-end" }}>
              <Button
                style={{
                  backgroundColor: theme.secondary,
                  color: theme.primary,
                  marginRight: 10,
                }}
                onClick={() => setExtraHeadersModalOpen(!extraHeadersModalOpen)}
              >
                Cancel
              </Button>

              <Button
                onClick={handleUpdateDataSet}
                style={{
                  backgroundColor: theme.primary,
                  color: theme.white,
                }}
              >
                <SaveAlt style={{ marginRight: 5 }} /> Next
              </Button>
            </Grid>
          </>
        </Grid>
      </Modal>
      <Modal open={!!addDataModalOpen}>
        <Grid
          item
          container
          xs={11}
          md={6}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 14,
            background: theme.white,
            outline: "none",
            padding: 20,
          }}
        >
          <Grid
            item
            container
            xs={12}
            style={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              style={{
                position: "absolute",
                top: -30,
                right: -30,
                color: theme.accentOne,
                background: theme.secondary,
              }}
              onClick={() => setAddDataModalOpen(false)}
            >
              <Close />
            </IconButton>

            <Typography style={{ textAlign: "center" }}>
              Would you like to create a new data set or add data to an existing
              one?
              <br />
            </Typography>
            <Grid
              item
              container
              xs={12}
              style={{ justifyContent: "space-evenly", marginTop: "5%" }}
            >
              <Button
                style={{
                  backgroundColor: theme.secondary,
                  color: theme.primary,
                  marginRight: 10,
                }}
                onClick={() => {
                  setUploadDataModalOpen(!uploadDataModalOpen);
                  setAddDataModalOpen(false);
                }}
              >
                <Add style={{ marginRight: 5 }} /> New Data set
              </Button>

              <Button
                onClick={() => {
                  setDataSetsModalOpen(!dataSetsModalOpen);
                  setAddDataModalOpen(false);
                }}
                style={{
                  backgroundColor: theme.primary,
                  color: theme.white,
                }}
              >
                <Add style={{ marginRight: 5 }} /> Add to Existing
              </Button>
              <Grid
                item
                container
                xs={12}
                style={{ marginTop: "5%", justifyContent: "space-between" }}
              ></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal open={!!uploadDataModalOpen}>
        <Grid
          item
          container
          xs={11}
          md={6}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 14,
            background: theme.white,
            outline: "none",
            padding: 20,
          }}
        >
          <Grid
            item
            container
            xs={12}
            style={{
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <IconButton
              onClick={() => {
                setUploadDataModalOpen(false);
                setFile(null);
                setFileType(null);
              }}
              style={{
                position: "absolute",
                top: -30,
                right: -30,
                color: theme.accentOne,
                background: theme.secondary,
              }}
            >
              <Close />
            </IconButton>
            <Typography>Please name your data set.</Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              placeholder="Enter a name for your Data Set"
              autoComplete={false}
              variant="outlined"
              value={newDataSetName}
              onChange={(e) => setNewDataSetName(e.target.value)}
              style={{ marginTop: 10 }}
            />
            {file ? (
              <Grid
                item
                container
                xs={12}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  flexFlow: "row",
                  backgroundColor: "#ececec",
                  padding: "5%",
                  marginTop: "5%",
                  borderRadius: 10,
                }}
              >
                <Grid item container xs={2} style={{}}>
                  <FilePresentRounded
                    style={{ height: 100, width: 100, color: "grey" }}
                  />
                </Grid>
                <Grid item container xs={6}>
                  <Typography
                    style={{ justifyContent: "flex-start", marginLeft: "10%" }}
                  >
                    {file.name}
                  </Typography>
                </Grid>

                <Grid
                  item
                  container
                  xs={4}
                  style={{ justifyContent: "flex-end" }}
                >
                  {!loadingCategorizedByVizz && (
                    <Tooltip title={"Remove File"}>
                      <IconButton
                        onClick={() => {
                          setFile(null);
                          setFileType(null);
                        }}
                        style={{
                          justifyContent: "flex-start",
                          color: theme.danger,
                          backgroundColor: `${theme.danger}35`,
                          marginRight: 10,
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip
                    title={
                      newDataSetName === ""
                        ? "Please name your data set"
                        : "Upload File"
                    }
                  >
                    <IconButton
                      disabled={
                        newDataSetName === "" || loadingCategorizedByVizz
                      }
                      onClick={() => {
                        handleUploadClickV2();
                      }}
                      style={{
                        justifyContent: "flex-start",
                        color: theme.white,
                        backgroundColor: loadingCategorizedByVizz
                          ? theme.secondary
                          : theme.primary,
                        opacity: newDataSetName === "" ? 0.5 : 1,
                      }}
                    >
                      {loadingCategorizedByVizz ? (
                        <CircularProgress
                          size={24}
                          style={{ color: theme.primary }}
                        />
                      ) : (
                        <UploadFile />
                      )}
                    </IconButton>
                    <input
                      ref={fileInputRef}
                      type="file"
                      accept=".csv,.xlsx,.json"
                      hidden
                      onChange={handleDrop}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <motion.div
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                whileHover={
                  !file && { scale: 0.99, opacity: 0.75, cursor: "pointer" }
                }
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexFlow: "column",
                  backgroundColor: "#c6c6c6",
                  padding: "5%",
                  marginTop: "5%",
                  borderRadius: 10,
                  border: `4px dashed ${theme.primary}`,
                }}
              >
                <Typography style={{ marginTop: "5%" }}>
                  Please select a file to upload
                </Typography>

                <Tooltip title="Add a File">
                  <IconButton
                    onClick={() => fileInputRef.current.click()}
                    style={{
                      marginTop: "50%",
                      marginLeft: "30%",
                      color: theme.white,
                      backgroundColor: theme.primary,
                    }}
                  >
                    <Add />
                  </IconButton>
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept=".csv,.xlsx,.json"
                    hidden
                    onChange={handleDrop}
                  />
                </Tooltip>
              </motion.div>
            )}
          </Grid>
        </Grid>
      </Modal>
      {/* <Modal open={!!nameDataModalOpen}>
        <Grid
          item
          container
          xs={11}
          md={6}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 14,
            background: theme.white,
            outline: "none",
            padding: 20,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <>
            <Typography>
              Please name your data set and click "Next" to continue.
            </Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              placeholder="Enter a name for your Data Set"
              autoComplete={false}
              variant="outlined"
              value={newDataSetName}
              onChange={(e) => setNewDataSetName(e.target.value)}
              style={{ marginTop: 10 }}
            />
            <Grid
              item
              container
              xs={12}
              style={{ justifyContent: "center", marginTop: "2%" }}
            >
              <Button
                style={{
                  backgroundColor: theme.secondary,
                  color: theme.primary,
                  marginRight: 10,
                }}
                onClick={() => {
                  setNameDataModalOpen(!nameDataModalOpen);
                  handleUploadClickV2();
                }}
              >
                <Add style={{ marginRight: 5 }} /> Upload
              </Button>
            </Grid>
          </>
        </Grid>
      </Modal> */}
    </Container>
  );
};

export default MyData;
