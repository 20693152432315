import React, { useState, useEffect, useRef } from "react";
import { Grid, IconButton, Paper, Typography } from "@mui/material";
import CompanyMakeupRaceGraph from "./CompanyMakeupRaceGraph";
import { sampleData } from "../global/sampleData";
import ChartOne from "./ChartOne";
import { theme as defaultTheme } from "../global/theme";
import ChartTwo from "./ChartTwo";
import ChartThree from "./ChartThree";
import { getColorContrast } from "../Utils";
import ChartFour from "./ChartFour";
import { motion } from "framer-motion";
import { Close } from "@mui/icons-material";
import CustomBarChart from "./CustomBarChart";

const MetricContainer = ({
  dataAmt,
  userData,
  metrics,
  setMetrics,
  rawData,
  selectedPreset,
}) => {
  console.log("METRICS FROM METRIC CONTAINER", metrics);
  const metric1GraphRef = useRef(null);
  const metric2GraphRef = useRef(null);
  const metric3GraphRef = useRef(null);
  const metric4GraphRef = useRef(null);
  const metric5GraphRef = useRef(null);
  const metric6GraphRef = useRef(null);
  const metric7GraphRef = useRef(null);
  const [metricData, setMetricData] = useState(null);
  const [display1, setDisplay1] = useState(false);
  const [display2, setDisplay2] = useState(false);
  const [display3, setDisplay3] = useState(false);
  const [display4, setDisplay4] = useState(false);
  const [display5, setDisplay5] = useState(false);
  const [display6, setDisplay6] = useState(false);
  const [display7, setDisplay7] = useState(false);
  let theme = userData?.theme ? userData.theme : defaultTheme;

  useEffect(() => {
    if (selectedPreset) {
      if (metrics.length === 1 && metric1GraphRef !== null && metricData) {
        console.log("PARENT REF:", metric1GraphRef?.current?.clientHeight);
        setDisplay1(true);
      } else if (
        metrics.length === 2 &&
        metric2GraphRef !== null &&
        metricData
      ) {
        setDisplay1(true);
        setDisplay2(true);
      } else if (
        metrics.length === 3 &&
        metric3GraphRef !== null &&
        metricData
      ) {
        setDisplay1(true);
        setDisplay2(true);
        setDisplay3(true);
      } else if (
        metrics.length === 4 &&
        metric4GraphRef !== null &&
        metricData
      ) {
        setDisplay1(true);
        setDisplay2(true);
        // setDisplay3(true);
        // setDisplay4(true);
      } else if (
        metrics.length === 5 &&
        metric5GraphRef !== null &&
        metricData
      ) {
        setDisplay1(true);
        setDisplay2(true);
        setDisplay3(true);
        // setDisplay4(true);
        // setDisplay5(true);
      } else if (
        metrics.length === 6 &&
        metric6GraphRef !== null &&
        metricData
      ) {
        setDisplay1(true);
        setDisplay2(true);
        setDisplay3(true);
        setDisplay4(true);
        setDisplay5(true);
        setDisplay6(true);
      } else if (
        metrics.length === 7 &&
        metric7GraphRef !== null &&
        metricData
      ) {
        setDisplay1(true);
        setDisplay2(true);
        setDisplay3(true);
        setDisplay4(true);
        setDisplay5(true);
        setDisplay6(true);
        setDisplay7(true);
      }
    } else {
      if (metrics.length === 1 && metric1GraphRef !== null && metricData) {
        console.log("PARENT REF:", metric1GraphRef?.current?.clientHeight);
        setDisplay1(true);
      } else if (
        metrics.length === 2 &&
        metric2GraphRef !== null &&
        metricData
      ) {
        setDisplay2(true);
      } else if (
        metrics.length === 3 &&
        metric3GraphRef !== null &&
        metricData
      ) {
        setDisplay3(true);
      } else if (
        metrics.length === 4 &&
        metric4GraphRef !== null &&
        metricData
      ) {
        setDisplay4(true);
      } else if (
        metrics.length === 5 &&
        metric5GraphRef !== null &&
        metricData
      ) {
        setDisplay5(true);
      } else if (
        metrics.length === 6 &&
        metric6GraphRef !== null &&
        metricData
      ) {
        setDisplay6(true);
      } else if (
        metrics.length === 7 &&
        metric7GraphRef !== null &&
        metricData
      ) {
        setDisplay7(true);
      }
    }
  }, [
    metrics,
    metric1GraphRef,
    metric2GraphRef,
    metric3GraphRef,
    metric4GraphRef,
    metric5GraphRef,
    metric6GraphRef,
    metric7GraphRef,
    metricData,
  ]);

  useEffect(() => {}, [
    display1,
    display2,
    display3,
    display4,
    display5,
    display6,
    display7,
  ]);

  useEffect(() => {
    // console.log("RAW DATA", rawData);
    if (metrics.length > 0 && rawData && rawData.rows && !metricData) {
      const getUpdatedMetrics = async () => {
        let newMetrics = [...metrics];
        for (let index = 0; index < newMetrics.length; index++) {
          let metric = newMetrics[index];
          try {
            if (metric.calculationMethod) {
              let calculationMethodString = metric.calculationMethod;

              const calculate = eval(`(${calculationMethodString})`);

              console.log(rawData.rows);
              const result = await calculate(rawData.rows);
              metric.value = result;

              console.log("RESULT IS: ", result);
            } else {
              for (
                let valueIdx = 0;
                valueIdx < metric.values.length;
                valueIdx++
              ) {
                let valueObj = metric.values[valueIdx];
                valueObj.comparedValues = [];
                if (
                  valueObj.camparedWithin &&
                  valueObj.camparedWithin.length > 0
                ) {
                  for (
                    let comparedItemIdx = 0;
                    comparedItemIdx < valueObj.camparedWithin.length;
                    comparedItemIdx++
                  ) {
                    const category = valueObj.camparedWithin[comparedItemIdx];
                    if (
                      !valueObj.comparedValues.find((x) => x.value === category)
                    ) {
                      valueObj.comparedValues.push({ value: category, amt: 1 });
                    } else {
                      const index = valueObj.comparedValues.findIndex(
                        (x) => x.value === category
                      );

                      valueObj.comparedValues[index].amt += 1;
                    }
                  }
                  //   let NonApplicable = { value: "N/A", amt: 0 };
                  //   valueObj.comparedValues.push(NonApplicable);
                  //   if (valueObj.comparedWithin.length !== valueObj.amt) {
                  // 	//Get the difference between the total and the sum of the compared values
                  //   }
                }
              }
            }
          } catch (error) {
            console.error("Error in dynamically created function:", error);
          }
        }
        console.log("DEBUGGER NEW METRICS", newMetrics);
        setMetricData(newMetrics);
      };

      getUpdatedMetrics();
    }
    console.log("new metric data", metricData);
  }, [metrics, rawData, metricData]);

  return (
    <>
      {metricData && (
        <>
          {dataAmt === 1 && (
            <Grid item container xs={12} style={{ height: "100%" }}>
              <Grid
                item
                container
                xs={12}
                style={{
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  flexFlow: "column",
                  overflow: "hidden",
                  padding: ".5% 2%",
                }}
              >
                <Paper
                  elevation={5}
                  style={{
                    height: "100%",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileHover={{ opacity: 1 }}
                    style={{
                      height: "fit-content",
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      position: "absolute",
                      padding: "10px",
                      top: "0",
                      right: "0",
                      zIndex: 1000,
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        // remove the metric from the list of metrics
                        setMetrics(metrics.filter((metric, i) => i !== 0));
                      }}
                      style={{
                        backgroundColor: theme.secondary,
                        color: theme.primary,
                      }}
                    >
                      <Close />
                    </IconButton>
                  </motion.div>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{
                      padding: "2% 10px",
                      height: "100%",
                      overflow: "hidden",
                    }}
                  >
                    {metrics[0]?.values[0]?.comparedValues.length > 20 ? (
                      <CustomBarChart
                        theme={theme}
                        metricData={metrics[0]}
                        amtOfDisplayedMetrics={dataAmt}
                      />
                    ) : (
                      <>
                        {/* TOP HALF OF COMPONENT TEXT */}
                        <Grid
                          item
                          container
                          xs={12}
                          style={{
                            containerType: "inline-size",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "3cqw",
                              fontWeight: "bold",
                              marginLeft: 15,
                            }}
                          >
                            {metrics[0].metric?.calculationMethod}{" "}
                            {metrics[0].metric.headerName}{" "}
                            {metrics[0].metric.comparedWithin && (
                              <span style={{ fontSize: "2.25cqw" }}>
                                {" "}
                                / {metrics[0].metric.secondMetric}
                              </span>
                            )}
                          </Typography>
                          <Typography
                            style={{ fontSize: "2cqw", marginRight: 15 }}
                          >
                            {metrics[0].metric.type}
                          </Typography>
                        </Grid>

                        {/* TOP HALF OF COMPONENT CHART */}
                        <Grid
                          ref={metric1GraphRef}
                          item
                          container
                          xs={12}
                          style={{
                            height: "60%",
                            alignItems: "center",
                            justifyContent: "center",
                            borderBottom: "3px solid lightgrey",
                          }}
                        >
                          <>
                            {display1 && (
                              <ChartOne
                                comparedWithin={
                                  metrics[0].metric.comparedWithin
                                }
                                metric={metrics[0]}
                                dataAmt={dataAmt}
                                data={rawData}
                                theme={theme}
                                parentRefHeight={
                                  metric1GraphRef.current.clientHeight
                                }
                                parentRefWidth={
                                  metric1GraphRef.current.clientWidth
                                }
                              />
                            )}
                          </>
                        </Grid>

                        {/* BOTTOM LEFT OF COMPONENT */}
                        <Grid
                          item
                          container
                          xs={12}
                          style={{
                            height: "40%",
                            justifyContent: "center",
                            padding: "3% 2%",
                          }}
                        >
                          <Paper
                            elevation={5}
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: theme.primary,
                              width: "100%",
                              height: "100%",
                              padding: "0% 0% 0% 5%",
                              containerType: "inline-size",
                            }}
                          >
                            <Grid item container xs={12} style={{}}>
                              <Grid item container xs={12}>
                                <Typography
                                  style={{
                                    color: getColorContrast(theme.primary),
                                    fontSize: "2cqh",
                                    fontWeight: "bold",
                                    textAlign: "left",
                                    marginTop: "2%",
                                    width: "100%",
                                  }}
                                >
                                  {metrics[0].metric.headerName} Percentage View
                                </Typography>
                              </Grid>
                              {metrics[0].values.map((value) => {
                                let totalValue = metrics[0].values.reduce(
                                  function (acc, value) {
                                    acc += value.amt;
                                    return acc;
                                  },
                                  0
                                );
                                let percentage = (value.amt / totalValue) * 100;
                                let percentageHeight =
                                  metrics[0].values.length > 4
                                    ? 9
                                    : metrics[0].values.length > 6
                                    ? 7
                                    : 12;
                                let textHeight =
                                  metrics[0].values.length > 4
                                    ? 7
                                    : metrics[0].values.length > 6
                                    ? 6
                                    : 9;
                                return (
                                  <Grid
                                    item
                                    container
                                    xs={6}
                                    style={{
                                      flexFlow: "row",
                                      alignItems: "center",
                                      overflow: "hidden",
                                      padding: "0 2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        borderBottom: `1px solid ${getColorContrast(
                                          theme.primary
                                        )}`,
                                        display: "flex",
                                        justifyContent: "space-between",
                                        containerType: "inline-size",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          color: getColorContrast(
                                            theme.primary
                                          ),
                                          fontSize: `1.65cqh`,

                                          fontWeight: "bold",
                                        }}
                                      >
                                        {percentage.toFixed(2)}%
                                      </Typography>
                                      <Typography
                                        style={{
                                          color: getColorContrast(
                                            theme.primary
                                          ),
                                          textAlign: "left",
                                          fontSize: `1.25cqh`,
                                          marginLeft: 5,
                                        }}
                                      >
                                        {value.value}
                                      </Typography>
                                    </div>
                                  </Grid>
                                );
                              })}
                            </Grid>
                            {/* MAP SOME DATA HERE  */}
                          </Paper>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          )}

          {dataAmt === 2 && (
            <>
              {/* LEFT SIDE */}
              <Grid item container xs={6} style={{ height: "100%" }}>
                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    flexFlow: "column",
                    overflow: "hidden",
                    padding: 10,
                  }}
                >
                  <Paper
                    elevation={5}
                    style={{
                      height: "100%",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <motion.div
                      initial={{ opacity: 0 }}
                      whileHover={{ opacity: 1 }}
                      style={{
                        height: "fit-content",
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        position: "absolute",
                        padding: "10px",
                        top: "0",
                        right: "0",
                        zIndex: 1000,
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          // remove the metric from the list of metrics
                          setMetrics(metrics.filter((metric, i) => i !== 0));
                        }}
                        style={{
                          backgroundColor: theme.secondary,
                          color: theme.primary,
                        }}
                      >
                        <Close />
                      </IconButton>
                    </motion.div>

                    {metrics[0]?.values[0]?.comparedValues.length > 10 ? (
                      <CustomBarChart
                        theme={theme}
                        metricData={metrics[0]}
                        amtOfDisplayedMetrics={dataAmt}
                      />
                    ) : (
                      <Grid
                        item
                        container
                        xs={12}
                        style={{ padding: 10, height: "100%" }}
                      >
                        {/* TOP HALF OF COMPONENT TEXT */}
                        <Grid
                          item
                          container
                          xs={12}
                          style={{
                            containerType: "inline-size",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "2.5cqw",
                              fontWeight: "bold",
                              marginLeft: 15,
                            }}
                          >
                            {metrics[0].metric.headerName}
                          </Typography>
                          <Typography
                            style={{ fontSize: "1.75cqw", marginRight: 15 }}
                          >
                            {metrics[0].metric.type}
                          </Typography>
                        </Grid>

                        {/* TOP HALF OF COMPONENT CHART */}
                        <Grid
                          ref={metric1GraphRef}
                          item
                          container
                          xs={12}
                          style={{
                            height: "50%",
                            alignItems: "center",
                            justifyContent: "center",
                            borderBottom: "3px solid lightgrey",
                          }}
                        >
                          {display1 && (
                            <ChartOne
                              comparedWithin={metrics[0].metric.comparedWithin}
                              metric={metrics[0]}
                              dataAmt={dataAmt}
                              data={rawData}
                              theme={theme}
                              parentRefHeight={
                                metric1GraphRef.current.clientHeight
                              }
                              parentRefWidth={
                                metric1GraphRef.current.clientWidth
                              }
                            />
                          )}
                        </Grid>

                        {/* BOTTOM LEFT OF COMPONENT */}
                        <Grid
                          item
                          container
                          xs={12}
                          style={{
                            height: "50%",
                            justifyContent: "center",
                            padding: "3% 2%",
                          }}
                        >
                          <Paper
                            elevation={5}
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: theme.primary,
                              width: "100%",
                              height: "100%",
                              padding: "0% 0% 0% 5%",
                              containerType: "inline-size",
                            }}
                          >
                            <Grid item container xs={12} style={{}}>
                              <Grid item container xs={12}>
                                <Typography
                                  style={{
                                    color: getColorContrast(theme.primary),
                                    fontSize: "2cqh",
                                    fontWeight: "bold",
                                    textAlign: "left",
                                    marginTop: "2%",
                                    width: "100%",
                                  }}
                                >
                                  {metrics[0].metric.headerName} Percentage View
                                </Typography>
                              </Grid>
                              {metrics[0].values.map((value) => {
                                let totalValue = metrics[0].values.reduce(
                                  function (acc, value) {
                                    acc += value.amt;
                                    return acc;
                                  },
                                  0
                                );
                                let percentage = (value.amt / totalValue) * 100;
                                let percentageHeight =
                                  metrics[0].values.length > 4
                                    ? 9
                                    : metrics[0].values.length > 6
                                    ? 7
                                    : 12;
                                let textHeight =
                                  metrics[0].values.length > 4
                                    ? 7
                                    : metrics[0].values.length > 6
                                    ? 6
                                    : 9;
                                return (
                                  <Grid
                                    item
                                    container
                                    xs={
                                      metrics[0]?.values.length < 7
                                        ? metrics[0]?.values.length > 12
                                          ? 12
                                          : 6
                                        : 12
                                    }
                                    style={{
                                      flexFlow: "row",
                                      alignItems: "center",
                                      overflow: "hidden",
                                      padding: "0 2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        borderBottom: `1px solid ${getColorContrast(
                                          theme.primary
                                        )}`,
                                        display: "flex",
                                        justifyContent: "space-between",
                                        containerType: "inline-size",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          color: getColorContrast(
                                            theme.primary
                                          ),
                                          fontSize: `1.65cqh`,

                                          fontWeight: "bold",
                                        }}
                                      >
                                        {percentage.toFixed(2)}%
                                      </Typography>
                                      <Typography
                                        style={{
                                          color: getColorContrast(
                                            theme.primary
                                          ),
                                          textAlign: "left",
                                          fontSize: `1.25cqh`,
                                          marginLeft: 5,
                                        }}
                                      >
                                        {value.value}
                                      </Typography>
                                    </div>
                                  </Grid>
                                );
                              })}
                            </Grid>
                            {/* MAP SOME DATA HERE  */}
                          </Paper>
                        </Grid>

                        {/* BOTTOM RIGHT OF COMPONENT */}
                        {/* <Grid
                        item
                        container
                        xs={7}
                        style={{
                          height: "50%",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 10,
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: 20,
                            fontWeight: "bold",
                            marginTop: 10,
                            width: "100%",
                          }}
                        >
                          Highlights
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 10,
                          }}
                        >
                          When measuring {metrics[0].metric.headerName}, ...
                          more notes...
                        </Typography>
                      </Grid> */}
                      </Grid>
                    )}
                  </Paper>
                </Grid>
              </Grid>

              {/* RIGHT SIDE */}
              <Grid item container xs={6} style={{ height: "100%" }}>
                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    flexFlow: "column",
                    overflow: "hidden",
                    padding: "1%",
                  }}
                >
                  <Paper
                    elevation={5}
                    style={{
                      height: "100%",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <motion.div
                      initial={{ opacity: 0 }}
                      whileHover={{ opacity: 1 }}
                      style={{
                        height: "fit-content",
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        position: "absolute",
                        padding: "10px",
                        top: "0",
                        right: "0",
                        zIndex: 1000,
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          // remove the metric from the list of metrics
                          setMetrics(metrics.filter((metric, i) => i !== 1));
                        }}
                        style={{
                          backgroundColor: theme.secondary,
                          color: theme.primary,
                        }}
                      >
                        <Close />
                      </IconButton>
                    </motion.div>
                    <Grid
                      item
                      container
                      xs={12}
                      style={{
                        padding: 10,
                        height: "100%",
                        containerType: "inline-size",
                      }}
                    >
                      {/* TOP HALF OF COMPONENT TEXT */}
                      <Grid
                        item
                        container
                        xs={12}
                        style={{
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: 10,
                            fontWeight: "bold",
                            marginLeft: 15,
                          }}
                        >
                          {metrics[1].metric.headerName}
                        </Typography>
                        <Typography style={{ fontSize: 10, marginRight: 15 }}>
                          {metrics[1].metric.type}
                        </Typography>
                      </Grid>
                      <Grid
                        ref={metric2GraphRef}
                        item
                        container
                        xs={12}
                        style={{
                          height: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          borderBottom: "3px solid lightgrey",
                        }}
                      >
                        {display2 && (
                          <ChartTwo
                            metric={metrics[1]}
                            dataAmt={dataAmt}
                            data={rawData}
                            theme={theme}
                            parentRefHeight={
                              metric2GraphRef.current.clientHeight
                            }
                            parentRefWidth={metric2GraphRef.current.clientWidth}
                          />
                        )}
                        {/* BOTTOM  TEXT */}
                        <Grid
                          item
                          container
                          xs={12}
                          style={{
                            justifyContent: "space-between",
                          }}
                        >
                          {metrics[1].values.map((value) => {
                            let Value =
                              !value.value || value.value === ""
                                ? "N/A"
                                : value.value;
                            return (
                              <Grid
                                item
                                container
                                xs={6}
                                key={value.amt + Value}
                                style={{
                                  textWrap: "nowrap",
                                  overflow: "hidden",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: `1.5cqh`,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {value.amt}{" "}
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: `1.5cqh`,
                                  }}
                                >
                                  -{" "}
                                  {Value.length > 20
                                    ? Value.substring(0, 18) + "..."
                                    : Value}
                                </Typography>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </>
          )}

          {dataAmt === 3 && (
            <>
              <Grid item container xs={6} style={{ height: "100%" }}>
                {metrics[0]?.values[0]?.comparedValues.length > 20 ? (
                  <CustomBarChart
                    theme={theme}
                    metricData={metrics[0]}
                    amtOfDisplayedMetrics={dataAmt}
                  />
                ) : (
                  <Grid
                    item
                    container
                    xs={12}
                    style={{
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      flexFlow: "column",
                      overflow: "hidden",
                      padding: 10,
                    }}
                  >
                    <Paper
                      elevation={5}
                      style={{
                        height: "100%",
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <motion.div
                        initial={{ opacity: 0 }}
                        whileHover={{ opacity: 1 }}
                        style={{
                          height: "fit-content",
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          position: "absolute",
                          padding: "10px",
                          top: "0",
                          right: "0",
                          zIndex: 1000,
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            // remove the metric from the list of metrics
                            setMetrics(metrics.filter((metric, i) => i !== 0));
                          }}
                          style={{
                            backgroundColor: theme.secondary,
                            color: theme.primary,
                          }}
                        >
                          <Close />
                        </IconButton>
                      </motion.div>

                      {metrics[0]?.values[0]?.comparedValues.length > 10 ? (
                        <CustomBarChart
                          theme={theme}
                          metricData={metrics[0]}
                          amtOfDisplayedMetrics={dataAmt}
                        />
                      ) : (
                        <Grid
                          item
                          container
                          xs={12}
                          style={{ padding: 10, height: "100%" }}
                        >
                          {/* TOP HALF OF COMPONENT TEXT */}
                          <Grid
                            item
                            container
                            xs={12}
                            style={{
                              containerType: "inline-size",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "2.5cqw",
                                fontWeight: "bold",
                                marginLeft: 15,
                              }}
                            >
                              {metrics[0].metric.headerName}
                            </Typography>
                            <Typography
                              style={{ fontSize: "1.75cqw", marginRight: 15 }}
                            >
                              {metrics[0].metric.type}
                            </Typography>
                          </Grid>

                          {/* TOP HALF OF COMPONENT CHART */}
                          <Grid
                            ref={metric1GraphRef}
                            item
                            container
                            xs={12}
                            style={{
                              height: "50%",
                              alignItems: "center",
                              justifyContent: "center",
                              borderBottom: "3px solid lightgrey",
                            }}
                          >
                            {display1 && (
                              <ChartOne
                                comparedWithin={
                                  metrics[0].metric.comparedWithin
                                }
                                metric={metrics[0]}
                                dataAmt={dataAmt}
                                data={rawData}
                                theme={theme}
                                parentRefHeight={
                                  metric1GraphRef.current.clientHeight
                                }
                                parentRefWidth={
                                  metric1GraphRef.current.clientWidth
                                }
                              />
                            )}
                          </Grid>

                          {/* BOTTOM LEFT OF COMPONENT */}
                          <Grid
                            item
                            container
                            xs={12}
                            style={{
                              height: "50%",
                              justifyContent: "center",
                              padding: "3% 2%",
                            }}
                          >
                            <Paper
                              elevation={5}
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: theme.primary,
                                width: "100%",
                                height: "100%",
                                padding: "0% 0% 0% 5%",
                                containerType: "inline-size",
                              }}
                            >
                              <Grid item container xs={12} style={{}}>
                                <Grid item container xs={12}>
                                  <Typography
                                    style={{
                                      color: getColorContrast(theme.primary),
                                      fontSize: "2cqh",
                                      fontWeight: "bold",
                                      textAlign: "left",
                                      marginTop: "2%",
                                      width: "100%",
                                    }}
                                  >
                                    {metrics[0].metric.headerName} Percentage
                                    View
                                  </Typography>
                                </Grid>
                                {metrics[0].values.map((value) => {
                                  let totalValue = metrics[0].values.reduce(
                                    function (acc, value) {
                                      acc += value.amt;
                                      return acc;
                                    },
                                    0
                                  );
                                  let percentage =
                                    (value.amt / totalValue) * 100;
                                  let percentageHeight =
                                    metrics[0].values.length > 4
                                      ? 9
                                      : metrics[0].values.length > 6
                                      ? 7
                                      : 12;
                                  let textHeight =
                                    metrics[0].values.length > 4
                                      ? 7
                                      : metrics[0].values.length > 6
                                      ? 6
                                      : 9;
                                  return (
                                    <Grid
                                      item
                                      container
                                      xs={
                                        metrics[0]?.values.length < 7
                                          ? metrics[0]?.values.length > 12
                                            ? 12
                                            : 6
                                          : 12
                                      }
                                      style={{
                                        flexFlow: "row",
                                        alignItems: "center",
                                        overflow: "hidden",
                                        padding: "0 2%",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          borderBottom: `1px solid ${getColorContrast(
                                            theme.primary
                                          )}`,
                                          display: "flex",
                                          justifyContent: "space-between",
                                          containerType: "inline-size",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            color: getColorContrast(
                                              theme.primary
                                            ),
                                            fontSize: `1.65cqh`,

                                            fontWeight: "bold",
                                          }}
                                        >
                                          {percentage.toFixed(2)}%
                                        </Typography>
                                        <Typography
                                          style={{
                                            color: getColorContrast(
                                              theme.primary
                                            ),
                                            textAlign: "left",
                                            fontSize: `1.25cqh`,
                                            marginLeft: 5,
                                          }}
                                        >
                                          {value.value}
                                        </Typography>
                                      </div>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                              {/* MAP SOME DATA HERE  */}
                            </Paper>
                          </Grid>

                          {/* BOTTOM RIGHT OF COMPONENT */}
                        </Grid>
                      )}
                    </Paper>
                  </Grid>
                )}
              </Grid>
              <Grid item container xs={6} style={{ height: "100%" }}>
                <Grid item container xs={12} style={{ height: "50%" }}>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      flexFlow: "column",
                      overflow: "hidden",
                      padding: "1%",
                    }}
                  >
                    <Paper
                      elevation={5}
                      style={{
                        height: "100%",
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <motion.div
                        initial={{ opacity: 0 }}
                        whileHover={{ opacity: 1 }}
                        style={{
                          height: "fit-content",
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          position: "absolute",
                          padding: "10px",
                          top: "0",
                          right: "0",
                          zIndex: 1000,
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            // remove the metric from the list of metrics
                            setMetrics(metrics.filter((metric, i) => i !== 1));
                          }}
                          style={{
                            backgroundColor: theme.secondary,
                            color: theme.primary,
                          }}
                        >
                          <Close />
                        </IconButton>
                      </motion.div>
                      <Grid
                        item
                        container
                        xs={12}
                        style={{
                          padding: 10,
                          height: "100%",
                          containerType: "inline-size",
                        }}
                      >
                        {/* TOP HALF OF COMPONENT TEXT */}
                        <Grid
                          item
                          container
                          xs={12}
                          style={{
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: 10,
                              fontWeight: "bold",
                              marginLeft: 15,
                            }}
                          >
                            {metrics[1].metric.headerName}
                          </Typography>
                          <Typography style={{ fontSize: 10, marginRight: 15 }}>
                            {metrics[1].metric.type}
                          </Typography>
                        </Grid>
                        <Grid
                          ref={metric2GraphRef}
                          item
                          container
                          xs={7}
                          style={{
                            height: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                            borderBottom: "3px solid lightgrey",
                          }}
                        >
                          {display2 && (
                            <ChartTwo
                              metric={metrics[1]}
                              dataAmt={dataAmt}
                              theme={theme}
                              data={rawData}
                              parentRefHeight={
                                metric2GraphRef.current.clientHeight
                              }
                              parentRefWidth={
                                metric2GraphRef.current.clientWidth
                              }
                            />
                          )}
                        </Grid>
                        <Grid
                          item
                          container
                          xs={5}
                          style={{
                            height: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            borderBottom: "3px solid lightgrey",
                          }}
                        >
                          {/* BOTTOM  TEXT */}
                          <Grid
                            item
                            container
                            xs={12}
                            style={{
                              justifyContent: "space-between",
                            }}
                          >
                            {metrics[1].values.map((value) => {
                              let Value =
                                !value.value || value.value === ""
                                  ? "N/A"
                                  : value.value;
                              return (
                                <Grid
                                  item
                                  container
                                  xs={12}
                                  key={value.amt + Value}
                                  style={{
                                    textWrap: "nowrap",
                                    overflow: "hidden",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontSize: `1.5cqh`,
                                    }}
                                  >
                                    {Value.length > 20
                                      ? Value.substring(0, 18) + "..."
                                      : Value}{" "}
                                    -
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontSize: `1.5cqh`,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {value.amt}
                                  </Typography>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>

                <Grid item container xs={12} style={{ height: "50%" }}>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      flexFlow: "column",
                      overflow: "hidden",
                      padding: "1%",
                    }}
                  >
                    <Paper
                      elevation={5}
                      style={{
                        height: "100%",
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <motion.div
                        initial={{ opacity: 0 }}
                        whileHover={{ opacity: 1 }}
                        style={{
                          height: "fit-content",
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          position: "absolute",
                          padding: "10px",
                          top: "0",
                          right: "0",
                          zIndex: 1000,
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            // remove the metric from the list of metrics
                            setMetrics(metrics.filter((metric, i) => i !== 2));
                          }}
                          style={{
                            backgroundColor: theme.secondary,
                            color: theme.primary,
                          }}
                        >
                          <Close />
                        </IconButton>
                      </motion.div>
                      <Grid
                        item
                        container
                        xs={12}
                        style={{
                          padding: 10,
                          height: "100%",
                          containerType: "inline-size",
                        }}
                      >
                        {/* TOP HALF OF COMPONENT TEXT */}
                        <Grid
                          item
                          container
                          xs={12}
                          style={{
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: 10,
                              fontWeight: "bold",
                              marginLeft: 15,
                            }}
                          >
                            {metrics[2].metric.headerName}
                          </Typography>
                          <Typography style={{ fontSize: 10, marginRight: 15 }}>
                            {metrics[2].metric.type}
                          </Typography>
                        </Grid>

                        <Grid
                          ref={metric3GraphRef}
                          item
                          container
                          xs={12}
                          style={{
                            height: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                            borderBottom: "3px solid lightgrey",
                            overflow: "hidden",
                          }}
                        >
                          {display3 && (
                            <ChartThree
                              metric={metrics[2]}
                              dataAmt={dataAmt}
                              theme={theme}
                              data={rawData}
                              parentRefHeight={
                                metric3GraphRef.current.clientHeight
                              }
                              parentRefWidth={
                                metric3GraphRef.current.clientWidth
                              }
                            />
                          )}
                        </Grid>

                        {/* BOTTOM  TEXT */}
                        <Grid
                          item
                          container
                          xs={12}
                          style={{
                            justifyContent: "space-between",
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            padding: "2%",
                          }}
                        >
                          {metrics[2].values.map((value) => {
                            let Value =
                              !value.value || value.value === ""
                                ? "N/A"
                                : value.value;
                            return (
                              <Grid
                                item
                                container
                                xs={6}
                                key={value.amt + Value}
                                style={{
                                  textWrap: "nowrap",
                                  overflow: "hidden",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: `1.5cqh`,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {value.amt}{" "}
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: `1.5cqh`,
                                  }}
                                >
                                  -{" "}
                                  {Value.length > 20
                                    ? Value.substring(0, 18) + "..."
                                    : Value}
                                </Typography>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {dataAmt === 4 && (
            <>
              <Grid item container xs={6} style={{ height: "100%" }}>
                <Grid item container xs={12} style={{ height: "60%" }}>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      flexFlow: "column",
                      overflow: "hidden",
                      padding: "1%",
                    }}
                  >
                    <Paper
                      elevation={5}
                      style={{
                        height: "100%",
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <motion.div
                        initial={{ opacity: 0 }}
                        whileHover={{ opacity: 1 }}
                        style={{
                          height: "fit-content",
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          position: "absolute",
                          padding: "10px",
                          top: "0",
                          right: "0",
                          zIndex: 1000,
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            // remove the metric from the list of metrics
                            setMetrics(metrics.filter((metric, i) => i !== 0));
                          }}
                          style={{
                            backgroundColor: theme.secondary,
                            color: theme.primary,
                          }}
                        >
                          <Close />
                        </IconButton>
                      </motion.div>

                      {metrics[0]?.values[0]?.comparedValues.length > 20 ? (
                        <CustomBarChart
                          theme={theme}
                          metricData={metrics[0]}
                          amtOfDisplayedMetrics={dataAmt}
                        />
                      ) : (
                        <Grid
                          item
                          container
                          xs={12}
                          style={{ padding: 10, height: "100%" }}
                        >
                          {/* TOP HALF OF COMPONENT TEXT */}
                          <Grid
                            item
                            container
                            xs={12}
                            style={{
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: 10,
                                fontWeight: "bold",
                                marginLeft: 15,
                              }}
                            >
                              {metrics[0].metric.headerName}
                            </Typography>
                            <Typography
                              style={{ fontSize: 10, marginRight: 15 }}
                            >
                              {metrics[0].metric.type}
                            </Typography>
                          </Grid>

                          <Grid
                            ref={metric1GraphRef}
                            item
                            container
                            xs={12}
                            style={{
                              height: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              borderBottom: "3px solid lightgrey",
                            }}
                          >
                            {display1 && (
                              <ChartOne
                                comparedWithin={
                                  metrics[0].metric.comparedWithin
                                }
                                dataAmt={dataAmt}
                                theme={theme}
                                data={rawData}
                                metric={metrics[0]}
                                parentRefHeight={
                                  metric1GraphRef.current.clientHeight
                                }
                                parentRefWidth={
                                  metric1GraphRef.current.clientWidth
                                }
                              />
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Paper>
                  </Grid>
                </Grid>

                <Grid item container xs={12} style={{ height: "40%" }}>
                  <Grid item container xs={12} style={{ height: "100%" }}>
                    <Grid
                      item
                      container
                      xs={12}
                      style={{
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        flexFlow: "column",
                        overflow: "hidden",
                        padding: "1%",
                      }}
                    >
                      <Paper
                        elevation={5}
                        style={{
                          height: "100%",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <motion.div
                          initial={{ opacity: 0 }}
                          whileHover={{ opacity: 1 }}
                          style={{
                            height: "fit-content",
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            position: "absolute",
                            padding: "10px",
                            top: "0",
                            right: "0",
                            zIndex: 1000,
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              // remove the metric from the list of metrics
                              setMetrics(
                                metrics.filter((metric, i) => i !== 1)
                              );
                            }}
                            style={{
                              backgroundColor: theme.secondary,
                              color: theme.primary,
                            }}
                          >
                            <Close />
                          </IconButton>
                        </motion.div>
                        <Grid
                          item
                          container
                          xs={12}
                          style={{
                            padding: 10,
                            height: "100%",
                            containerType: "inline-size",
                          }}
                        >
                          {/* TOP HALF OF COMPONENT TEXT */}
                          <Grid
                            item
                            container
                            xs={12}
                            style={{
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: 10,
                                fontWeight: "bold",
                                marginLeft: 15,
                              }}
                            >
                              {metrics[1].metric.headerName}
                            </Typography>
                            <Typography
                              style={{ fontSize: 10, marginRight: 15 }}
                            >
                              {metrics[1].metric.type}
                            </Typography>
                          </Grid>
                          <Grid
                            ref={metric2GraphRef}
                            item
                            container
                            xs={7}
                            style={{
                              height: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              borderBottom: "3px solid lightgrey",
                            }}
                          >
                            {display2 && (
                              <ChartTwo
                                dataAmt={dataAmt}
                                theme={theme}
                                data={rawData}
                                metric={metrics[1]}
                                parentRefHeight={
                                  metric2GraphRef.current.clientHeight
                                }
                                parentRefWidth={
                                  metric2GraphRef.current.clientWidth
                                }
                              />
                            )}
                          </Grid>
                          <Grid
                            item
                            container
                            xs={5}
                            style={{
                              height: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              borderBottom: "3px solid lightgrey",
                              overFlow: "hidden",
                            }}
                          >
                            {/* BOTTOM  TEXT */}
                            <Grid
                              item
                              container
                              xs={12}
                              style={{
                                justifyContent: "space-between",
                              }}
                            >
                              {metrics[1].values.map((value) => {
                                let Value =
                                  !value.value || value.value === ""
                                    ? "N/A"
                                    : value.value;
                                return (
                                  <Grid
                                    item
                                    container
                                    xs={12}
                                    key={value.amt + Value}
                                    style={{
                                      textWrap: "nowrap",
                                      overflow: "hidden",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontSize: `1.40cqh`,
                                      }}
                                    >
                                      {Value.length > 20
                                        ? Value.substring(0, 18) + "..."
                                        : Value}{" "}
                                      -
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: `1.2cqh`,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {value.amt}
                                    </Typography>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={6} style={{ height: "100%" }}>
                <Grid
                  item
                  container
                  xs={6}
                  style={{
                    height: "100%",
                    justifyContent: "center",
                    padding: "1% 2%",
                  }}
                >
                  <Paper
                    elevation={5}
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: theme.primary,
                      width: "100%",
                      height: "100%",
                      padding: "0% 5%",
                      containerType: "inline-size",
                      position: "relative",
                      overFlow: "hidden",
                    }}
                  >
                    <motion.div
                      initial={{ opacity: 0 }}
                      whileHover={{ opacity: 1 }}
                      style={{
                        height: "fit-content",
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        position: "absolute",
                        padding: "10px",
                        top: "0",
                        right: "0",
                        zIndex: 1000,
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          // remove the metric from the list of metrics
                          setMetrics(metrics.filter((metric, i) => i !== 2));
                        }}
                        style={{
                          backgroundColor: theme.secondary,
                          color: theme.primary,
                        }}
                      >
                        <Close />
                      </IconButton>
                    </motion.div>
                    <Typography
                      style={{
                        color: getColorContrast(theme.primary),
                        fontSize: "7cqw",
                        fontWeight: "bold",
                        textAlign: "left",
                        marginTop: "2%",
                        width: "100%",
                        textAlign: "center",
                        marginBottom: 10,
                      }}
                    >
                      {metrics[2].metric.headerName} Percentage View
                    </Typography>
                    {/* MAP SOME DATA HERE  */}
                    {metrics[2].values.map((value) => {
                      let totalValue = metrics[0].values.reduce(function (
                        acc,
                        value
                      ) {
                        acc += value.amt;
                        return acc;
                      },
                      0);
                      let percentage = (value.amt / totalValue) * 100;
                      let percentageHeight =
                        metrics[2].values.length > 4
                          ? 9
                          : metrics[2].values.length > 6
                          ? 7
                          : 12;
                      let textHeight =
                        metrics[2].values.length > 4
                          ? 7
                          : metrics[2].values.length > 6
                          ? 6
                          : 9;
                      return (
                        <Grid
                          item
                          container
                          xs={12}
                          style={{
                            flexFlow: "row",
                            alignItems: "center",
                            borderBottom: `1px solid ${theme.secondary}`,
                            overflow: "hidden",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            style={{
                              color: getColorContrast(theme.primary),
                              fontSize: `6cqw`,
                              fontWeight: "bold",
                            }}
                          >
                            {percentage.toFixed(2)}%
                          </Typography>
                          <Typography
                            style={{
                              color: getColorContrast(theme.primary),
                              textAlign: "right",
                              fontSize: `5cqw`,
                              marginLeft: 5,
                            }}
                          >
                            {value.value}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Paper>
                </Grid>

                <Grid
                  item
                  container
                  xs={6}
                  style={{ height: "100%", padding: "1%" }}
                >
                  <Paper
                    elevation={5}
                    style={{
                      height: "100%",
                      width: "100%",
                      containerType: "inline-size",
                      padding: "0% 2%",
                      position: "relative",
                    }}
                  >
                    <motion.div
                      initial={{ opacity: 0 }}
                      whileHover={{ opacity: 1 }}
                      style={{
                        height: "fit-content",
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        position: "absolute",
                        padding: "10px",
                        top: "0",
                        right: "0",
                        zIndex: 1000,
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          // remove the metric from the list of metrics
                          setMetrics(metrics.filter((metric, i) => i !== 3));
                        }}
                        style={{
                          backgroundColor: theme.secondary,
                          color: theme.primary,
                        }}
                      >
                        <Close />
                      </IconButton>
                    </motion.div>
                    <Typography
                      variant="h5"
                      gutterBottom
                      style={{ color: theme.primary, marginTop: "3%" }}
                    >
                      {metrics[3].metric.headerName}
                    </Typography>
                    {metrics[3].values.map((value) => {
                      let totalValue = metrics[3].values.reduce(function (
                        acc,
                        value
                      ) {
                        acc += value.amt;
                        return acc;
                      },
                      0);
                      let percentage = (value.amt / totalValue) * 100;
                      let percentageHeight =
                        metrics[3].values.length > 4
                          ? 9
                          : metrics[3].values.length > 6
                          ? 7
                          : 12;
                      let textHeight =
                        metrics[3].values.length > 4
                          ? 7
                          : metrics[3].values.length > 6
                          ? 6
                          : 9;
                      return (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexFlow: "column",
                            marginBottom: 8,
                          }}
                        >
                          <Typography
                            style={{
                              width: "100%",
                              textAlign: "left",
                              fontSize: "6cqw",
                              color: theme.primary,
                              marginTop: "3%",
                              marginLeft: "3%",
                            }}
                          >
                            {!value.value || value.value === ""
                              ? "N/A"
                              : value.value.length > 20
                              ? `${value.value.substring(0, 17)}...`
                              : value.value}
                            : {percentage.toFixed(2)}%
                          </Typography>
                          <div
                            style={{
                              width: "90%",
                              display: "flex",
                              flexFlow: "row",
                              height: 10,
                              borderRadius: 10,
                              backgroundColor: "#eeeeee",
                              marginLeft: "3%",
                            }}
                          >
                            <div
                              style={{
                                width: `${percentage}%`,

                                height: "100%",
                                borderRadius: 10,
                                backgroundColor: theme.primary,
                                // marginLeft: "1%",
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </Paper>
                </Grid>
              </Grid>
            </>
          )}

          {dataAmt === 5 && (
            <>
              <Grid item container xs={6} style={{ height: "100%" }}>
                <Grid item container xs={12} style={{ height: "60%" }}>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      flexFlow: "column",
                      overflow: "hidden",
                      padding: "1%",
                    }}
                  >
                    <Paper
                      elevation={5}
                      style={{
                        height: "100%",
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <motion.div
                        initial={{ opacity: 0 }}
                        whileHover={{ opacity: 1 }}
                        style={{
                          height: "fit-content",
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          position: "absolute",
                          padding: "10px",
                          top: "0",
                          right: "0",
                          zIndex: 1000,
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            // remove the metric from the list of metrics
                            setMetrics(metrics.filter((metric, i) => i !== 0));
                          }}
                          style={{
                            backgroundColor: theme.secondary,
                            color: theme.primary,
                          }}
                        >
                          <Close />
                        </IconButton>
                      </motion.div>
                      {metrics[0]?.values[0]?.comparedValues.length > 20 ? (
                        <CustomBarChart
                          theme={theme}
                          metricData={metrics[0]}
                          amtOfDisplayedMetrics={dataAmt}
                        />
                      ) : (
                        <Grid
                          item
                          container
                          xs={12}
                          style={{ padding: 10, height: "100%" }}
                        >
                          {/* TOP HALF OF COMPONENT TEXT */}
                          <Grid
                            item
                            container
                            xs={12}
                            style={{
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: 10,
                                fontWeight: "bold",
                                marginLeft: 15,
                              }}
                            >
                              {metrics[0].metric.headerName}
                            </Typography>
                            <Typography
                              style={{ fontSize: 10, marginRight: 15 }}
                            >
                              CHART TYPE
                            </Typography>
                          </Grid>

                          <Grid
                            ref={metric1GraphRef}
                            item
                            container
                            xs={12}
                            style={{
                              height: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              borderBottom: "3px solid lightgrey",
                            }}
                          >
                            {display1 && (
                              <ChartOne
                                comparedWithin={
                                  metrics[0].metric.comparedWithin
                                }
                                dataAmt={dataAmt}
                                theme={theme}
                                data={rawData}
                                metric={metrics[0]}
                                parentRefHeight={
                                  metric1GraphRef.current.clientHeight
                                }
                                parentRefWidth={
                                  metric1GraphRef.current.clientWidth
                                }
                              />
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Paper>
                  </Grid>
                </Grid>

                <Grid item container xs={12} style={{ height: "40%" }}>
                  <Grid item container xs={6} style={{ height: "100%" }}>
                    <Grid
                      item
                      container
                      xs={12}
                      style={{
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        flexFlow: "column",
                        overflow: "hidden",
                        padding: "1%",
                      }}
                    >
                      <Paper
                        elevation={5}
                        style={{
                          height: "100%",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <motion.div
                          initial={{ opacity: 0 }}
                          whileHover={{ opacity: 1 }}
                          style={{
                            height: "fit-content",
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            position: "absolute",
                            padding: "10px",
                            top: "0",
                            right: "0",
                            zIndex: 1000,
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              // remove the metric from the list of metrics
                              setMetrics(
                                metrics.filter((metric, i) => i !== 1)
                              );
                            }}
                            style={{
                              backgroundColor: theme.secondary,
                              color: theme.primary,
                            }}
                          >
                            <Close />
                          </IconButton>
                        </motion.div>
                        <Grid
                          item
                          container
                          xs={12}
                          style={{
                            padding: 10,
                            height: "100%",
                            containerType: "inline-size",
                          }}
                        >
                          {/* TOP HALF OF COMPONENT TEXT */}
                          <Grid
                            item
                            container
                            xs={12}
                            style={{
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: 10,
                                fontWeight: "bold",
                                marginLeft: 15,
                              }}
                            >
                              {metrics[1].metric.headerName}
                            </Typography>
                            <Typography
                              style={{ fontSize: 10, marginRight: 15 }}
                            >
                              CHART TYPE
                            </Typography>
                          </Grid>
                          <Grid
                            ref={metric2GraphRef}
                            item
                            container
                            xs={12}
                            style={{
                              height: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              borderBottom: "3px solid lightgrey",
                            }}
                          >
                            {display2 && (
                              <ChartTwo
                                dataAmt={dataAmt}
                                theme={theme}
                                data={rawData}
                                metric={metrics[1]}
                                parentRefHeight={
                                  metric2GraphRef.current.clientHeight
                                }
                                parentRefWidth={
                                  metric2GraphRef.current.clientWidth
                                }
                              />
                            )}
                            {/* BOTTOM  TEXT */}
                            <Grid
                              item
                              container
                              xs={12}
                              style={{
                                justifyContent: "space-between",
                              }}
                            >
                              {metrics[1].values.map((value) => {
                                let totalValue = metrics[1].values.reduce(
                                  function (acc, value) {
                                    acc += value.amt;
                                    return acc;
                                  },
                                  0
                                );
                                let percentage = (value.amt / totalValue) * 100;
                                let percentageHeight =
                                  metrics[1].values.length > 4
                                    ? 9
                                    : metrics[1].values.length > 6
                                    ? 7
                                    : 12;
                                let textHeight =
                                  metrics[1].values.length > 4
                                    ? 7
                                    : metrics[1].values.length > 6
                                    ? 6
                                    : 9;
                                return (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: `3cqw`,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {value.value} - {percentage.toFixed(2)}%
                                    </Typography>
                                  </>
                                );
                              })}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                  {/* SECTION 5.3 */}
                  <Grid item container xs={6} style={{ height: "100%" }}>
                    <Grid
                      item
                      container
                      xs={12}
                      style={{
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        flexFlow: "column",
                        overflow: "hidden",
                        padding: "1%",
                      }}
                    >
                      <Paper
                        elevation={5}
                        style={{
                          height: "100%",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <motion.div
                          initial={{ opacity: 0 }}
                          whileHover={{ opacity: 1 }}
                          style={{
                            height: "fit-content",
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            position: "absolute",
                            padding: "10px",
                            top: "0",
                            right: "0",
                            zIndex: 1000,
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              // remove the metric from the list of metrics
                              setMetrics(
                                metrics.filter((metric, i) => i !== 2)
                              );
                            }}
                            style={{
                              backgroundColor: theme.secondary,
                              color: theme.primary,
                            }}
                          >
                            <Close />
                          </IconButton>
                        </motion.div>

                        <Grid
                          item
                          container
                          xs={12}
                          style={{
                            padding: 10,
                            height: "100%",
                            containerType: "inline-size",
                            position: "relative",
                          }}
                        >
                          {/* TOP HALF OF COMPONENT TEXT */}
                          <Grid
                            item
                            container
                            xs={12}
                            style={{
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: 10,
                                fontWeight: "bold",
                                marginLeft: 15,
                              }}
                            >
                              {metrics[2].metric.headerName}
                            </Typography>
                            <Typography
                              style={{ fontSize: 10, marginRight: 15 }}
                            >
                              CHART TYPE
                            </Typography>
                          </Grid>
                          <Grid
                            ref={metric3GraphRef}
                            item
                            container
                            xs={12}
                            style={{
                              height: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              borderBottom: "3px solid lightgrey",
                            }}
                          >
                            {display3 && (
                              <ChartThree
                                dataAmt={dataAmt}
                                theme={theme}
                                data={rawData}
                                metric={metrics[2]}
                                parentRefHeight={
                                  metric2GraphRef.current.clientHeight
                                }
                                parentRefWidth={
                                  metric2GraphRef.current.clientWidth
                                }
                              />
                            )}
                          </Grid>

                          {/* BOTTOM  TEXT */}
                          <Grid
                            item
                            container
                            xs={12}
                            style={{
                              justifyContent: "space-between",
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              padding: "2%",
                            }}
                          >
                            {metrics[2].values.map((value) => {
                              let totalValue = metrics[2].values.reduce(
                                function (acc, value) {
                                  acc += value.amt;
                                  return acc;
                                },
                                0
                              );
                              let percentage = (value.amt / totalValue) * 100;
                              let percentageHeight =
                                metrics[2].values.length > 4
                                  ? 9
                                  : metrics[2].values.length > 6
                                  ? 7
                                  : 12;
                              let textHeight =
                                metrics[2].values.length > 4
                                  ? 7
                                  : metrics[2].values.length > 6
                                  ? 6
                                  : 9;
                              return (
                                <>
                                  <Typography
                                    style={{
                                      fontSize: `3cqw`,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {value.value} - {percentage.toFixed(2)}%
                                  </Typography>
                                </>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={6} style={{ height: "100%" }}>
                <Grid
                  item
                  container
                  xs={6}
                  style={{ height: "100%", padding: "1%" }}
                >
                  {/* LEFT SIDE */}
                  <Paper
                    elevation={5}
                    style={{
                      height: "100%",
                      width: "100%",
                      containerType: "inline-size",
                      padding: "0% 2%",
                      position: "relative",
                    }}
                  >
                    <motion.div
                      initial={{ opacity: 0 }}
                      whileHover={{ opacity: 1 }}
                      style={{
                        height: "fit-content",
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        position: "absolute",
                        padding: "10px",
                        top: "0",
                        right: "0",
                        zIndex: 1000,
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          // remove the metric from the list of metrics
                          setMetrics(metrics.filter((metric, i) => i !== 3));
                        }}
                        style={{
                          backgroundColor: theme.secondary,
                          color: theme.primary,
                        }}
                      >
                        <Close />
                      </IconButton>
                    </motion.div>
                    <Typography
                      variant="h5"
                      gutterBottom
                      style={{ color: theme.primary, marginTop: "3%" }}
                    >
                      {metrics[3].metric.headerName}
                    </Typography>
                    {metrics[3].values
                      .sort((a, b) => b.amt - a.amt)
                      .map((value) => {
                        let totalValue = metrics[3].values.reduce(function (
                          acc,
                          value
                        ) {
                          acc += value.amt;
                          return acc;
                        },
                        0);
                        let percentage = (value.amt / totalValue) * 100;
                        let percentageHeight =
                          metrics[3].values.length > 4
                            ? 9
                            : metrics[3].values.length > 6
                            ? 7
                            : 12;
                        let textHeight =
                          metrics[3].values.length > 4
                            ? 7
                            : metrics[3].values.length > 6
                            ? 6
                            : 9;
                        return (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexFlow: "column",
                              marginBottom: 8,
                            }}
                          >
                            <Typography
                              style={{
                                width: "100%",
                                textAlign: "left",
                                fontSize: "6cqw",
                                color: theme.primary,
                                marginTop: "3%",
                                marginLeft: "3%",
                              }}
                            >
                              {!value.value || value.value === ""
                                ? "N/A"
                                : value.value.length > 20
                                ? `${value.value.substring(0, 17)}...`
                                : value.value}
                              : {percentage.toFixed(2)}%
                            </Typography>
                            <div
                              style={{
                                width: "90%",
                                display: "flex",
                                flexFlow: "row",
                                height: 10,
                                borderRadius: 10,
                                backgroundColor: "#eeeeee",
                                marginLeft: "3%",
                              }}
                            >
                              <div
                                style={{
                                  width: `${percentage}%`,

                                  height: "100%",
                                  borderRadius: 10,
                                  backgroundColor: theme.primary,
                                  // marginLeft: "1%",
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </Paper>
                </Grid>
                <Grid
                  item
                  container
                  xs={6}
                  style={{ height: "100%", padding: "1%" }}
                >
                  {/* RIGHT SIDE */}
                  <Paper
                    elevation={5}
                    style={{
                      height: "100%",
                      width: "100%",
                      containerType: "inline-size",
                      postion: "relative",
                    }}
                  >
                    <motion.div
                      initial={{ opacity: 0 }}
                      whileHover={{ opacity: 1 }}
                      style={{
                        height: "fit-content",
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        position: "absolute",
                        padding: "10px",
                        top: "0",
                        right: "0",
                        zIndex: 1000,
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          // remove the metric from the list of metrics
                          setMetrics(metrics.filter((metric, i) => i !== 4));
                        }}
                        style={{
                          backgroundColor: theme.secondary,
                          color: theme.primary,
                        }}
                      >
                        <Close />
                      </IconButton>
                    </motion.div>
                    <Typography
                      variant="h5"
                      gutterBottom
                      style={{
                        color: theme.primary,
                        marginTop: "3%",
                        marginBottom: "10%",
                      }}
                    >
                      {metrics[4].metric.headerName}
                    </Typography>
                    <Grid
                      item
                      container
                      xs={12}
                      style={{
                        height: "80%",
                        flexFlow: "column",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {metrics[4].values.map((value) => {
                        let totalValue = metrics[4].values.reduce(function (
                          acc,
                          value
                        ) {
                          acc += value.amt;
                          return acc;
                        },
                        0);
                        let percentage = (value.amt / totalValue) * 100;
                        return (
                          <Typography
                            style={{
                              width: "100%",
                              fontSize: "6cqw",
                              color: theme.primary,
                              marginBottom: "2%",
                            }}
                          >
                            {!value.value || value.value === ""
                              ? "N/A"
                              : value.value}
                            : {percentage.toFixed(2)}%
                          </Typography>
                        );
                      })}
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </>
          )}

          {dataAmt === 6 && (
            <>
              <Grid item container xs={6} style={{ height: "100%" }}>
                <Grid item container xs={12} style={{ height: "60%" }}>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      flexFlow: "column",
                      overflow: "hidden",
                      padding: "1%",
                    }}
                  >
                    <Paper
                      elevation={5}
                      style={{ height: "100%", width: "100%" }}
                    >
                      {metrics[0]?.values[0]?.comparedValues.length > 20 ? (
                        <CustomBarChart
                          theme={theme}
                          metricData={metrics[0]}
                          amtOfDisplayedMetrics={dataAmt}
                        />
                      ) : (
                        <Grid
                          item
                          container
                          xs={12}
                          style={{ padding: 10, height: "100%" }}
                        >
                          {/* TOP HALF OF COMPONENT TEXT */}
                          <Grid
                            item
                            container
                            xs={12}
                            style={{
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: 10,
                                fontWeight: "bold",
                                marginLeft: 15,
                              }}
                            >
                              {metrics[0].metric.headerName}
                            </Typography>
                            <Typography
                              style={{ fontSize: 10, marginRight: 15 }}
                            >
                              CHART TYPE
                            </Typography>
                          </Grid>

                          <Grid
                            ref={metric1GraphRef}
                            item
                            container
                            xs={12}
                            style={{
                              height: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              borderBottom: "3px solid lightgrey",
                            }}
                          >
                            {display1 && (
                              <ChartOne
                                comparedWithin={
                                  metrics[0].metric.comparedWithin
                                }
                                dataAmt={dataAmt}
                                metric={metrics[0]}
                                data={rawData}
                                theme={theme}
                                parentRefHeight={
                                  metric1GraphRef.current.clientHeight
                                }
                                parentRefWidth={
                                  metric1GraphRef.current.clientWidth
                                }
                              />
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Paper>
                  </Grid>
                </Grid>

                <Grid item container xs={12} style={{ height: "40%" }}>
                  <Grid item container xs={12} style={{ height: "100%" }}>
                    <Grid
                      item
                      container
                      xs={12}
                      style={{
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        flexFlow: "column",
                        overflow: "hidden",
                        padding: "1%",
                      }}
                    >
                      <Paper
                        elevation={5}
                        style={{ height: "100%", width: "100%" }}
                      >
                        <Grid
                          item
                          container
                          xs={12}
                          style={{
                            padding: 10,
                            height: "100%",
                            containerType: "inline-size",
                          }}
                        >
                          {/* TOP HALF OF COMPONENT TEXT */}
                          <Grid
                            item
                            container
                            xs={12}
                            style={{
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: 10,
                                fontWeight: "bold",
                                marginLeft: 15,
                              }}
                            >
                              {metrics[1].metric.headerName}
                            </Typography>
                            <Typography
                              style={{ fontSize: 10, marginRight: 15 }}
                            >
                              CHART TYPE
                            </Typography>
                          </Grid>
                          <Grid
                            ref={metric2GraphRef}
                            item
                            container
                            xs={7}
                            style={{
                              height: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              borderBottom: "3px solid lightgrey",
                            }}
                          >
                            {display2 && (
                              <ChartTwo
                                dataAmt={dataAmt}
                                metric={metrics[1]}
                                data={rawData}
                                theme={theme}
                                parentRefHeight={
                                  metric2GraphRef.current.clientHeight
                                }
                                parentRefWidth={
                                  metric2GraphRef.current.clientWidth
                                }
                              />
                            )}
                          </Grid>
                          <Grid
                            xs={5}
                            style={{
                              height: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              borderBottom: "3px solid lightgrey",
                            }}
                          >
                            {/* BOTTOM  TEXT */}
                            <Grid
                              item
                              container
                              xs={12}
                              style={{
                                justifyContent: "flex-end",
                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              {metrics[1].values.map((value) => {
                                let totalValue = metrics[1].values.reduce(
                                  function (acc, value) {
                                    acc += value.amt;
                                    return acc;
                                  },
                                  0
                                );
                                let percentage = (value.amt / totalValue) * 100;
                                let percentageHeight =
                                  metrics[1].values.length > 4
                                    ? 9
                                    : metrics[1].values.length > 6
                                    ? 7
                                    : 12;
                                let textHeight =
                                  metrics[1].values.length > 4
                                    ? 7
                                    : metrics[1].values.length > 6
                                    ? 6
                                    : 9;
                                return (
                                  <>
                                    <Typography
                                      style={{
                                        width: "100%",
                                        textAlign: "right",
                                        fontSize: `3cqw`,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {value.value} - {percentage.toFixed(2)}%
                                    </Typography>
                                  </>
                                );
                              })}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={6} style={{ height: "100%" }}>
                <Grid item container xs={6} style={{ height: "100%" }}>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{ height: "40%", padding: "2%" }}
                  >
                    <Paper
                      elevation={5}
                      style={{
                        height: "100%",
                        width: "100%",
                        backgroundColor: theme.primary,
                        containerType: "inline-size",
                      }}
                    >
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{
                          color: getColorContrast(theme.primary),
                          fontSize: "8cqw",
                          fontWeight: "bold",
                        }}
                      >
                        {metrics[2].metric.headerName}
                      </Typography>
                      {metrics[2].values.map((value) => {
                        let totalValue = metrics[2].values.reduce(function (
                          acc,
                          value
                        ) {
                          acc += value.amt;
                          return acc;
                        },
                        0);
                        let percentage = (value.amt / totalValue) * 100;
                        let percentageHeight =
                          metrics[2].values.length > 4
                            ? 9
                            : metrics[2].values.length > 6
                            ? 7
                            : 12;
                        let textHeight =
                          metrics[2].values.length > 4
                            ? 7
                            : metrics[2].values.length > 6
                            ? 6
                            : 9;
                        return (
                          <Grid
                            item
                            container
                            xs={12}
                            style={{
                              padding: "0% 2%",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize:
                                  metrics[2].values.length > 12
                                    ? "3.8cqw"
                                    : "5cqw",
                                color: getColorContrast(theme.primary),
                              }}
                            >
                              {!value.value || value.value === ""
                                ? "N/A"
                                : value.value.length > 23
                                ? `${value.value.substring(0, 20)}...`
                                : value.value}
                              :
                            </Typography>
                            <Typography
                              style={{
                                fontSize:
                                  metrics[2].values.length > 12
                                    ? "3.8cqw"
                                    : "5cqw",
                                color: getColorContrast(theme.primary),
                              }}
                            >
                              {percentage.toFixed(2)}%
                            </Typography>
                          </Grid>
                        );
                      })}
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{ height: "60%", padding: "2%" }}
                  >
                    <Paper
                      elevation={5}
                      style={{
                        height: "100%",
                        width: "100%",
                        containerType: "inline-size",
                        padding: "0% 2%",
                      }}
                    >
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{ color: theme.primary, fontWeight: "bold" }}
                      >
                        {metrics[3].metric.headerName}
                      </Typography>
                      {metrics[3].values.map((value) => {
                        let totalValue = metrics[3].values.reduce(function (
                          acc,
                          value
                        ) {
                          acc += value.amt;
                          return acc;
                        },
                        0);
                        let percentage = (value.amt / totalValue) * 100;
                        let percentageHeight =
                          metrics[3].values.length > 4
                            ? 9
                            : metrics[3].values.length > 6
                            ? 7
                            : 12;
                        let textHeight =
                          metrics[3].values.length > 4
                            ? 7
                            : metrics[3].values.length > 6
                            ? 6
                            : 9;
                        return (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexFlow: "column",
                              marginBottom: 8,
                            }}
                          >
                            <Typography
                              style={{
                                width: "100%",
                                textAlign: "left",
                                fontSize: "5cqw",
                                color: theme.primary,
                              }}
                            >
                              {!value.value || value.value === ""
                                ? "N/A"
                                : value.value.length > 20
                                ? `${value.value.substring(0, 17)}...`
                                : value.value}
                              : {percentage.toFixed(2)}%
                            </Typography>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexFlow: "row",
                                height: 10,
                                borderRadius: 10,
                                backgroundColor: "lightgrey",
                              }}
                            >
                              <div
                                style={{
                                  width: `${percentage}%`,
                                  height: "100%",
                                  borderRadius: 10,
                                  backgroundColor: theme.primary,
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </Paper>
                  </Grid>
                </Grid>
                <Grid item container xs={6} style={{ height: "100%" }}>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{ height: "60%", padding: "2% 0%" }}
                  >
                    <Paper
                      elevation={5}
                      style={{
                        height: "100%",
                        width: "100%",
                        containerType: "inline-size",
                        postion: "relative",
                      }}
                    >
                      <Grid
                        ref={metric5GraphRef}
                        item
                        container
                        xs={12}
                        style={{ height: "100%", display: "flex" }}
                      >
                        <Grid
                          item
                          container
                          xs={12}
                          style={{
                            justifyContent: "center",
                            containerType: "inline-size",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "6cqw",
                              fontWeight: "bold",
                              marginLeft: 15,
                            }}
                          >
                            {metrics[4].metric.headerName}
                          </Typography>
                        </Grid>
                        {display6 && (
                          <ChartFour
                            dataAmt={dataAmt}
                            data={rawData}
                            metric={metrics[4]}
                            theme={theme}
                            parentRefHeight={
                              metric5GraphRef.current.clientHeight
                            }
                            parentRefWidth={metric5GraphRef.current.clientWidth}
                          />
                        )}
                      </Grid>

                      {/* BOTTOM  TEXT */}
                      <Grid
                        item
                        container
                        xs={12}
                        style={{
                          justifyContent: "space-between",
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          padding: "2%",
                        }}
                      >
                        {metrics[4].values.map((value) => {
                          let totalValue = metrics[4].values.reduce(function (
                            acc,
                            value
                          ) {
                            acc += value.amt;
                            return acc;
                          },
                          0);
                          let percentage = (value.amt / totalValue) * 100;
                          let percentageHeight =
                            metrics[4].values.length > 4
                              ? 9
                              : metrics[4].values.length > 6
                              ? 7
                              : 12;
                          let textHeight =
                            metrics[4].values.length > 4
                              ? 7
                              : metrics[4].values.length > 6
                              ? 6
                              : 9;
                          return (
                            <>
                              <Typography
                                style={{
                                  fontSize: `3cqw`,
                                  fontWeight: "bold",
                                }}
                              >
                                <span style={{ fontWeight: "normal" }}>
                                  {value.value} -{" "}
                                </span>
                                {percentage.toFixed(2)}%
                              </Typography>
                            </>
                          );
                        })}
                      </Grid>
                    </Paper>
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    style={{ height: "40%" }}
                    ref={metric6GraphRef}
                  >
                    <Paper
                      elevation={5}
                      style={{
                        height: "100%",
                        width: "100%",
                        containerType: "inline-size",
                      }}
                    >
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{ color: theme.primary, fontWeight: "bold" }}
                      >
                        {metrics[5].metric.headerName}
                      </Typography>
                      {metrics[5].values.map((value) => {
                        let totalValue = metrics[5].values.reduce(function (
                          acc,
                          value
                        ) {
                          acc += value.amt;
                          return acc;
                        },
                        0);
                        let percentage = (value.amt / totalValue) * 100;
                        let percentageHeight =
                          metrics[5].values.length > 4
                            ? 9
                            : metrics[5].values.length > 6
                            ? 7
                            : 12;
                        let textHeight =
                          metrics[5].values.length > 4
                            ? 7
                            : metrics[5].values.length > 6
                            ? 6
                            : 9;
                        return (
                          <Grid
                            item
                            container
                            xs={12}
                            style={{
                              padding: "0% 2%",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize:
                                  metrics[5].values.length > 12
                                    ? "3.8cqw"
                                    : "5cqw",
                                color: theme.primary,
                              }}
                            >
                              {!value.value || value.value === ""
                                ? "N/A"
                                : value.value.length > 22
                                ? value.value.substring(0, 19) + "..."
                                : value.value}
                              :
                            </Typography>
                            <Typography
                              style={{
                                fontSize:
                                  metrics[5].values.length > 12
                                    ? "3.8cqw"
                                    : "5cqw",
                                color: theme.primary,
                              }}
                            >
                              {percentage.toFixed(2)}%
                            </Typography>
                          </Grid>
                        );
                      })}
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {dataAmt === 7 && (
            <>
              <Grid item container xs={6} style={{ height: "100%" }}>
                <Grid item container xs={12} style={{ height: "60%" }}>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      flexFlow: "column",
                      overflow: "hidden",
                      padding: "1%",
                    }}
                  >
                    <Paper
                      elevation={5}
                      style={{ height: "100%", width: "100%" }}
                    >
                      {metrics[0]?.values[0]?.comparedValues.length > 20 ? (
                        <CustomBarChart
                          theme={theme}
                          metricData={metrics[0]}
                          amtOfDisplayedMetrics={dataAmt}
                        />
                      ) : (
                        <Grid
                          item
                          container
                          xs={12}
                          style={{ padding: 10, height: "100%" }}
                        >
                          {/* TOP HALF OF COMPONENT TEXT */}
                          <Grid
                            item
                            container
                            xs={12}
                            style={{
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: 10,
                                fontWeight: "bold",
                                marginLeft: 15,
                              }}
                            >
                              {metrics[0].metric.headerName}
                            </Typography>
                            <Typography
                              style={{ fontSize: 10, marginRight: 15 }}
                            >
                              CHART TYPE
                            </Typography>
                          </Grid>

                          <Grid
                            ref={metric1GraphRef}
                            item
                            container
                            xs={12}
                            style={{
                              height: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              borderBottom: "3px solid lightgrey",
                            }}
                          >
                            {display1 && (
                              <ChartOne
                                comparedWithin={
                                  metrics[0].metric.comparedWithin
                                }
                                dataAmt={dataAmt}
                                theme={theme}
                                data={rawData}
                                metric={metrics[0]}
                                parentRefHeight={
                                  metric1GraphRef.current.clientHeight
                                }
                                parentRefWidth={
                                  metric1GraphRef.current.clientWidth
                                }
                              />
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Paper>
                  </Grid>
                </Grid>

                <Grid item container xs={12} style={{ height: "40%" }}>
                  {/* SECTION 7.2 */}
                  <Grid item container xs={6} style={{ height: "100%" }}>
                    <Grid
                      item
                      container
                      xs={12}
                      style={{
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        flexFlow: "column",
                        overflow: "hidden",
                        padding: "1%",
                      }}
                    >
                      <Paper
                        elevation={5}
                        style={{
                          height: "100%",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <Grid
                          item
                          container
                          xs={12}
                          style={{
                            padding: 10,
                            height: "100%",
                            containerType: "inline-size",
                          }}
                        >
                          {/* TOP HALF OF COMPONENT TEXT */}
                          <Grid
                            item
                            container
                            xs={12}
                            style={{
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: 10,
                                fontWeight: "bold",
                                marginLeft: 15,
                              }}
                            >
                              {metrics[1].metric.headerName}
                            </Typography>
                            <Typography
                              style={{ fontSize: 10, marginRight: 15 }}
                            >
                              CHART TYPE
                            </Typography>
                          </Grid>
                          <Grid
                            ref={metric2GraphRef}
                            item
                            container
                            xs={12}
                            style={{
                              height: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              borderBottom: "3px solid lightgrey",
                            }}
                          >
                            {display2 && (
                              <ChartTwo
                                dataAmt={dataAmt}
                                theme={theme}
                                data={rawData}
                                metric={metrics[1]}
                                parentRefHeight={
                                  metric2GraphRef.current.clientHeight
                                }
                                parentRefWidth={
                                  metric2GraphRef.current.clientWidth
                                }
                              />
                            )}
                            {/* BOTTOM  TEXT */}
                            <Grid
                              item
                              container
                              xs={12}
                              style={{
                                justifyContent: "space-between",
                              }}
                            >
                              {metrics[1].values.map((value) => {
                                let totalValue = metrics[1].values.reduce(
                                  function (acc, value) {
                                    acc += value.amt;
                                    return acc;
                                  },
                                  0
                                );
                                let percentage = (value.amt / totalValue) * 100;
                                let percentageHeight =
                                  metrics[1].values.length > 4
                                    ? 9
                                    : metrics[1].values.length > 6
                                    ? 7
                                    : 12;
                                let textHeight =
                                  metrics[1].values.length > 4
                                    ? 7
                                    : metrics[1].values.length > 6
                                    ? 6
                                    : 9;
                                return (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: `3cqw`,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {value.value} - {percentage.toFixed(2)}%
                                    </Typography>
                                  </>
                                );
                              })}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                  {/* SECTION 7.3 */}
                  <Grid item container xs={6} style={{ height: "100%" }}>
                    <Grid
                      item
                      container
                      xs={12}
                      style={{
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        flexFlow: "column",
                        overflow: "hidden",
                        padding: "1%",
                      }}
                    >
                      <Paper
                        elevation={5}
                        style={{
                          height: "100%",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <Grid
                          item
                          container
                          xs={12}
                          style={{
                            padding: 10,
                            height: "100%",
                            containerType: "inline-size",
                          }}
                        >
                          {/* TOP HALF OF COMPONENT TEXT */}
                          <Grid
                            item
                            container
                            xs={12}
                            style={{
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: 10,
                                fontWeight: "bold",
                                marginLeft: 15,
                              }}
                            >
                              {metrics[2].metric.headerName}
                            </Typography>
                            <Typography
                              style={{ fontSize: 10, marginRight: 15 }}
                            >
                              CHART TYPE
                            </Typography>
                          </Grid>

                          <Grid
                            ref={metric3GraphRef}
                            item
                            container
                            xs={12}
                            style={{
                              height: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              borderBottom: "3px solid lightgrey",
                            }}
                          >
                            {display3 && (
                              <ChartThree
                                dataAmt={dataAmt}
                                theme={theme}
                                data={rawData}
                                metric={metrics[2]}
                                parentRefHeight={
                                  metric2GraphRef.current.clientHeight
                                }
                                parentRefWidth={
                                  metric2GraphRef.current.clientWidth
                                }
                              />
                            )}
                          </Grid>

                          {/* BOTTOM  TEXT */}
                          <Grid
                            item
                            container
                            xs={12}
                            style={{
                              justifyContent: "space-between",
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              padding: "2%",
                            }}
                          >
                            {metrics[2].values.map((value) => {
                              let totalValue = metrics[2].values.reduce(
                                function (acc, value) {
                                  acc += value.amt;
                                  return acc;
                                },
                                0
                              );
                              let percentage = (value.amt / totalValue) * 100;
                              let percentageHeight =
                                metrics[2].values.length > 4
                                  ? 9
                                  : metrics[2].values.length > 6
                                  ? 7
                                  : 12;
                              let textHeight =
                                metrics[2].values.length > 4
                                  ? 7
                                  : metrics[2].values.length > 6
                                  ? 6
                                  : 9;
                              return (
                                <>
                                  <Typography
                                    style={{
                                      fontSize: `3cqw`,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {value.value} - {percentage.toFixed(2)}%
                                  </Typography>
                                </>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={6} style={{ height: "100%" }}>
                <Grid item container xs={6} style={{ height: "100%" }}>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{ height: "40%", padding: "2%" }}
                  >
                    <Paper
                      elevation={5}
                      style={{
                        height: "100%",
                        width: "100%",
                        backgroundColor: theme.primary,
                        containerType: "inline-size",
                      }}
                    >
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{
                          color: getColorContrast(theme.primary),
                          fontSize: "8cqw",
                          fontWeight: "bold",
                        }}
                      >
                        {metrics[3].metric.headerName}
                      </Typography>
                      {metrics[3].values.map((value) => {
                        let totalValue = metrics[3].values.reduce(function (
                          acc,
                          value
                        ) {
                          acc += value.amt;
                          return acc;
                        },
                        0);
                        let percentage = (value.amt / totalValue) * 100;
                        let percentageHeight =
                          metrics[3].values.length > 4
                            ? 9
                            : metrics[3].values.length > 6
                            ? 7
                            : 12;
                        let textHeight =
                          metrics[3].values.length > 4
                            ? 7
                            : metrics[3].values.length > 6
                            ? 6
                            : 9;
                        return (
                          <Grid
                            item
                            container
                            xs={12}
                            style={{
                              padding: "0% 2%",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize:
                                  metrics[3].values.length > 12
                                    ? "3.8cqw"
                                    : "5cqw",
                                color: getColorContrast(theme.primary),
                              }}
                            >
                              {!value.value || value.value === ""
                                ? "N/A"
                                : value.value.length > 23
                                ? `${value.value.substring(0, 20)}...`
                                : value.value}
                              :
                            </Typography>
                            <Typography
                              style={{
                                fontSize:
                                  metrics[3].values.length > 12
                                    ? "3.8cqw"
                                    : "5cqw",
                                color: getColorContrast(theme.primary),
                              }}
                            >
                              {percentage.toFixed(2)}%
                            </Typography>
                          </Grid>
                        );
                      })}
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{ height: "60%", padding: "0% 2%" }}
                  >
                    <Paper
                      elevation={5}
                      style={{
                        height: "100%",
                        width: "100%",
                        containerType: "inline-size",
                        padding: "0% 2%",
                      }}
                    >
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{ color: theme.primary, fontWeight: "bold" }}
                      >
                        {metrics[4].metric.headerName}
                      </Typography>
                      {metrics[4].values.map((value) => {
                        let totalValue = metrics[4].values.reduce(function (
                          acc,
                          value
                        ) {
                          acc += value.amt;
                          return acc;
                        },
                        0);
                        let percentage = (value.amt / totalValue) * 100;
                        let percentageHeight =
                          metrics[4].values.length > 4
                            ? 9
                            : metrics[4].values.length > 6
                            ? 7
                            : 12;
                        let textHeight =
                          metrics[4].values.length > 4
                            ? 7
                            : metrics[4].values.length > 6
                            ? 6
                            : 9;
                        return (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexFlow: "column",
                              marginBottom: 8,
                            }}
                          >
                            <Typography
                              style={{
                                width: "100%",
                                textAlign: "left",
                                fontSize: "5cqw",
                                color: theme.primary,
                              }}
                            >
                              {!value.value || value.value === ""
                                ? "N/A"
                                : value.value.length > 20
                                ? `${value.value.substring(0, 17)}...`
                                : value.value}
                              : {percentage.toFixed(2)}%
                            </Typography>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexFlow: "row",
                                height: 10,
                                borderRadius: 10,
                                backgroundColor: "lightgrey",
                              }}
                            >
                              <div
                                style={{
                                  width: `${percentage}%`,
                                  height: "100%",
                                  borderRadius: 10,
                                  backgroundColor: theme.primary,
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </Paper>
                  </Grid>
                </Grid>
                <Grid item container xs={6} style={{ height: "100%" }}>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{ height: "60%", padding: "2% 0%" }}
                  >
                    <Paper
                      elevation={5}
                      style={{
                        height: "100%",
                        width: "100%",
                        containerType: "inline-size",
                        postion: "relative",
                      }}
                    >
                      <Grid
                        ref={metric5GraphRef}
                        item
                        container
                        xs={12}
                        style={{ height: "100%", display: "flex" }}
                      >
                        <Grid
                          item
                          container
                          xs={12}
                          style={{
                            justifyContent: "center",
                            containerType: "inline-size",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "6cqw",
                              fontWeight: "bold",
                              marginLeft: 15,
                            }}
                          >
                            {metrics[5].metric.headerName}
                          </Typography>
                        </Grid>
                        {display6 && (
                          <ChartFour
                            dataAmt={dataAmt}
                            data={rawData}
                            metric={metrics[5]}
                            theme={theme}
                            parentRefHeight={
                              metric5GraphRef.current.clientHeight
                            }
                            parentRefWidth={metric5GraphRef.current.clientWidth}
                          />
                        )}
                      </Grid>

                      {/* BOTTOM  TEXT */}
                      <Grid
                        item
                        container
                        xs={12}
                        style={{
                          justifyContent: "space-between",
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          padding: "2%",
                        }}
                      >
                        {metrics[5].values.map((value) => {
                          let totalValue = metrics[5].values.reduce(function (
                            acc,
                            value
                          ) {
                            acc += value.amt;
                            return acc;
                          },
                          0);
                          let percentage = (value.amt / totalValue) * 100;
                          let percentageHeight =
                            metrics[5].values.length > 4
                              ? 9
                              : metrics[5].values.length > 6
                              ? 7
                              : 12;
                          let textHeight =
                            metrics[5].values.length > 4
                              ? 7
                              : metrics[5].values.length > 6
                              ? 6
                              : 9;
                          return (
                            <>
                              <Typography
                                style={{
                                  fontSize: `3cqw`,
                                  fontWeight: "bold",
                                }}
                              >
                                <span style={{ fontWeight: "normal" }}>
                                  {value.value} -{" "}
                                </span>
                                {percentage.toFixed(2)}%
                              </Typography>
                            </>
                          );
                        })}
                      </Grid>
                    </Paper>
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    style={{ height: "40%" }}
                    ref={metric6GraphRef}
                  >
                    <Paper
                      elevation={5}
                      style={{
                        height: "100%",
                        width: "100%",
                        containerType: "inline-size",
                      }}
                    >
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{ color: theme.primary, fontWeight: "bold" }}
                      >
                        {metrics[6].metric.headerName}
                      </Typography>
                      {metrics[6].values.map((value) => {
                        let totalValue = metrics[6].values.reduce(function (
                          acc,
                          value
                        ) {
                          acc += value.amt;
                          return acc;
                        },
                        0);
                        let percentage = (value.amt / totalValue) * 100;
                        let percentageHeight =
                          metrics[6].values.length > 4
                            ? 9
                            : metrics[6].values.length > 6
                            ? 7
                            : 12;
                        let textHeight =
                          metrics[6].values.length > 4
                            ? 7
                            : metrics[6].values.length > 6
                            ? 6
                            : 9;
                        return (
                          <Grid
                            item
                            container
                            xs={12}
                            style={{
                              padding: "0% 2%",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize:
                                  metrics[6].values.length > 12
                                    ? "3.8cqw"
                                    : "5cqw",
                                color: theme.primary,
                              }}
                            >
                              {!value.value || value.value === ""
                                ? "N/A"
                                : value.value.length > 22
                                ? value.value.substring(0, 19) + "..."
                                : value.value}
                              :
                            </Typography>
                            <Typography
                              style={{
                                fontSize:
                                  metrics[6].values.length > 12
                                    ? "3.8cqw"
                                    : "5cqw",
                                color: theme.primary,
                              }}
                            >
                              {percentage.toFixed(2)}%
                            </Typography>
                          </Grid>
                        );
                      })}
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
};

export default MetricContainer;
