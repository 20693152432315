import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Grid,
  Container,
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Link,
  IconButton,
  CircularProgress,
  Checkbox,
  Select,
  MenuItem,
  InputAdornment,
  Chip,
  InputLabel,
  Tooltip,
  Snackbar,
  Alert,
  Avatar,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Import the checkmark icon from Material-UI
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { DataGrid, GridCellEditStopReasons } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import {
  Add,
  Article,
  ArticleOutlined,
  Category,
  Check,
  Close,
  Delete,
  Edit,
  FilePresentRounded,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Save,
  SaveAlt,
  Upload,
  UploadFile,
} from "@mui/icons-material";
import { theme as defaultTheme } from "../global/theme";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import firebase, { db, functions, storage } from "../config/firebaseConfig";
import { setUserData } from "../global/authActions";
import { companyDepartments } from "../global/sampleData";

const ClaimAccount = ({ navigate }) => {
  const theme = defaultTheme;
  const vizzLogo =
    "https://firebasestorage.googleapis.com/v0/b/vizz-454f6.appspot.com/o/companyFiles%2Fvizz-logo.png?alt=media&token=eca0a95e-9b4b-4d05-af69-9bcea2539ed1";
  const userData = useSelector((state) => state.auth.userData);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState("");
  const [departmentOther, setDepartmentOther] = useState(false);
  const [title, setTitle] = useState("");
  const [titleOther, setTitleOther] = useState(false);
  const [availTitles, setAvailTitles] = useState([]);

  const [org, setOrg] = useState("");
  const [role, setRole] = useState("");

  const availDepartments = companyDepartments.map((cd) => cd.department);

  const url = window.location.pathname;
  const paths = url.split("/");
  const userId = paths[paths.length - 1];

  useEffect(() => {
    if (userData) {
      navigate("/dashboard");
    } else {
      db.collection("claimAccounts")
        .doc(userId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data();
            setName(data.name);
            setEmail(data.email);
            setOrg(data.org);
            setRole(data.role);
            setLoading(false);
          }
        });
    }
  }, []);

  useEffect(() => {
    if (department.length > 3) {
      let availableTitles = companyDepartments.find(
        (cd) => cd.department === department
      )?.titles;
      if (availableTitles) {
        setAvailTitles(availableTitles);
      } else {
        setAvailTitles(["Other"]);
      }
    } else {
      setAvailTitles([]);
    }
  }, [department]);

  const handleSubmit = () => {
    const data = {
      name,
      email,
      department,
      title,
      org: [{ name: org.companyName, uid: org.id }],
      theme: org.theme,
      phone: "",
      role,
      uid: userId,
      companyName: org.companyName,
      logo: org.logo,
      subscriptionTier: org.subscriptionTier,
      subscriptionStatus: org.subscriptionStatus,
      subscriptionPaid: org.subscriptionPaid,
      subscriptionID: org.subscriptionID,
    };
    db.collection("users")
      .doc(userId)
      .set(data)
      .then(() => {
        db.collection("organizations")
          .doc(org.id)
          .update({
            licenseHolders: firebase.firestore.FieldValue.arrayUnion({
              name,
              userId,
              email,
            }),
          })
          .then(() => {
            db.collection("claimAccounts")
              .doc(userId)
              .update({
                claimed: true,
              })
              .then(() => {
                // toast.success("Account deleted successfully");
                alert("Account created successfully");
                navigate("/");
              });
          });
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        padding: {
          xs: 0,
          md: "1% 10%",
          height: "calc(100vh - 64px)",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#c6c6c6",
        },
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Grid
          item
          container
          xs={11}
          md={8}
          lg={7}
          style={{
            height: "fit-content",
            justifyContent: "center",
            backgroundColor: "white",
            borderRadius: 10,
          }}
        >
          <Grid
            item
            container
            xs={6}
            md={4}
            style={{
              padding: 20,
              marginBottom: 0,
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <img src={vizzLogo} style={{ height: 40, width: "auto" }} />
            <Add />
            <img src={org.logo} style={{ height: 40, width: "auto" }} />
          </Grid>
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            style={{ width: "100%", textAlign: "center" }}
          >
            Claim Account
          </Typography>
          <Typography
            variant="h5"
            component="div"
            gutterBottom
            style={{ width: "100%", textAlign: "center" }}
          >
            You've been invited to claim an account on Vizz by {org.companyName}
          </Typography>
          <Grid item container xs={12} style={{ padding: 20 }}>
            <Typography
              variant="caption"
              style={{ textAlign: "left", marginTop: 10 }}
            >
              Name
            </Typography>
            <TextField
              id="outlined-basic"
              variant="outlined"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <Grid
              item
              container
              xs={6}
              style={{ marginTop: 10, paddingRight: 10 }}
            >
              <Typography variant="caption" style={{ textAlign: "left" }}>
                Email
              </Typography>
              <TextField
                disabled
                id="outlined-basic"
                variant="outlined"
                fullWidth
                value={email}
              />
            </Grid>
            <Grid
              item
              container
              xs={6}
              style={{ marginTop: 10, paddingLeft: 10 }}
            >
              <Typography variant="caption" style={{ textAlign: "left" }}>
                Role
              </Typography>
              <TextField
                disabled
                id="outlined-basic"
                variant="outlined"
                fullWidth
                value={role}
              />
            </Grid>

            {!departmentOther ? (
              <>
                <Typography
                  variant="caption"
                  style={{ textAlign: "left", marginTop: 10 }}
                >
                  Team player? Who do you support?
                </Typography>
                <FormControl sx={{ m: 1, maxHeight: 300, width: "100%" }}>
                  <InputLabel id="dept">Department</InputLabel>
                  <Select
                    label="Department"
                    fullWidth
                    value={department}
                    onChange={(event) => {
                      event.target.value === "Other"
                        ? setDepartmentOther(true)
                        : setDepartment(event.target.value);
                    }}
                  >
                    <MenuItem disabled value="">
                      - Select a Department -
                    </MenuItem>
                    {availDepartments.map((department) => (
                      <MenuItem value={department}>{department}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            ) : (
              <TextField
                label="Enter your Department"
                fullWidth
                value={department}
                onChange={(event) => setDepartment(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setDepartmentOther(false)}>
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  m: 1,
                  display: "flex",
                  flexWrap: "wrap",
                  // "& > *": { margin: 1 },
                }}
              />
            )}

            {!titleOther ? (
              <>
                <Typography
                  variant="caption"
                  style={{ textAlign: "left", marginTop: 10 }}
                >
                  Got a special job, how do you contribute?
                </Typography>
                <FormControl sx={{ m: 1, maxHeight: 300, width: "100%" }}>
                  <InputLabel id="role">Your Role</InputLabel>
                  <Select
                    ma
                    label="Your Role"
                    fullWidth
                    value={title}
                    onChange={(event) => {
                      event.target.value === "Other"
                        ? setTitleOther(true)
                        : setTitle(event.target.value);
                    }}
                  >
                    <MenuItem disabled value="">
                      - Select Your Role -
                    </MenuItem>
                    {availTitles.map((title) => (
                      <MenuItem value={title}>{title}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            ) : (
              <TextField
                label="Enter your Job Title"
                fullWidth
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setTitleOther(false)}>
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  m: 1,
                  display: "flex",
                  flexWrap: "wrap",
                  // "& > *": { margin: 1 },
                }}
              />
            )}

            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={loading || !name || !email || !department || !title}
              style={{
                marginTop: 10,
                background: theme.primary,
                color: theme.white,
                opacity:
                  loading || !name || !email || !department || !title ? 0.5 : 1,
              }}
            >
              Claim Account
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ClaimAccount;
