import authTypes from "./authTypes";

const initialState = {
  currentUser: null,
  userData: null,
  userDataSets: null,
  userPresets: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };

    case authTypes.CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
        userData: null,
      };

    case authTypes.SET_USER_DATA:
      return {
        ...state,
        userData: action.payload.userData,
      };

    case authTypes.SET_USER_DATASETS:
      return {
        ...state,
        userDataSets: action.payload.userDataSets,
      };

    case authTypes.SET_USER_PRESETS:
      return {
        ...state,
        userPresets: action.payload.userPresets,
      };

    default:
      return state;
  }
};

export default authReducer;
