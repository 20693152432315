import logo from "./logo.svg";
import "./App.css";
import {
  Grid,
  Container,
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  FormGroup,
  FormControlLabel,
  Link,
  IconButton,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import { Route, Routes, useNavigate } from "react-router-dom";
import {
  setCurrentUser,
  clearCurrentUser,
  setUserData,
  setUserDataSets,
  setUserPresets,
} from "./global/authActions";
import { useDispatch, useSelector } from "react-redux";
import LandingPage from "./views/LandingPage";
import Dashboard from "./views/Dashboard";
import React, { useEffect, useState } from "react";
import MyData from "./views/MyData";
import { auth, db } from "./config/firebaseConfig";
import AppBar from "./components/AppBar";
import AuthModal from "./components/modals/AuthModal";
import {
  AccountBox,
  AdminPanelSettings,
  Business,
  Collections,
  CreditCard,
  Folder,
  QueryStats,
  Settings,
  TripOrigin,
  Support,
  Workspaces,
  Assessment,
  TableChart,
  StackedBarChart,
  BackupTable,
} from "@mui/icons-material";
import BallotIcon from "@mui/icons-material/Ballot";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import EditNoteIcon from "@mui/icons-material/EditNote";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { theme as defaultTheme } from "./global/theme";
import Organization from "./views/Organization";
import BillingPage from "./views/BillingPage";
import SettingsView from "./views/SettingsView";
import ProfileSettings from "./views/ProfileSettings";
import { motion } from "framer-motion";
import ClaimAccount from "./views/ClaimAccount";
import SupportForm from "./components/modals/SupportForm";
import bizzyBookLogo from "./assets/social_avatar_small.png";
import bzrtLogo from "./assets/Bzzzrrrt-black-bg.png";

function App() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.userData);
  const userDataSets = useSelector((state) => state.auth.userDataSets);
  const userPresets = useSelector((state) => state.auth.userPresets);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [subModalOpen, setSubModalOpen] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const productsOpen = Boolean(anchorEl);

  const theme = userData?.theme ? userData.theme : defaultTheme;

  const [message, setMessage] = useState("");

  const list = (anchor) => (
    <Box
      style={{ height: "100%", position: "relative" }}
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 350 }}
      role="presentation"
      onClick={(e) => e.stopPropagation()}
      onKeyDown={closeDrawer}
    >
      <List sx={{ paddingTop: 0 }}>
        <Grid
          item
          container
          xs={12}
          style={{ padding: 10, justifyContent: "center" }}
        >
          <img
            src={userData?.logo}
            alt="logo"
            style={{ width: "auto", height: 50 }}
          />
        </Grid>
        <Divider />
        {[
          "Report View",
          "Data View",
          "Organization",
          "Billing Page",
          "Settings",
          //   "Profile",
        ].map((text, index) => (
          <ListItem key={text} disablePadding>
            <motion.div
              style={{ width: "100%" }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: index === 0 ? 0.15 : 0.15 * index,
                ease: [0.64, 0.03, 0.36, 1],
              }}
              whileHover={{ scale: 0.95, backgroundColor: theme.secondary }}
            >
              <ListItemButton
                onClick={() => {
                  if (text === "Organization") {
                    navigate("/organization");
                    setDrawerOpen(false);
                  } else if (text === "Report View") {
                    navigate("/dashboard");
                    setDrawerOpen(false);
                    // trackAction('navigation', 'dashboard')
                  } else if (text === "Settings") {
                    navigate("/settings");
                    setDrawerOpen(false);
                  } else if (text === "Billing Page") {
                    navigate("/billing");
                    setDrawerOpen(false);
                  } else if (text === "Data View") {
                    navigate("/my-data");
                    setDrawerOpen(false);
                    // trackAction('navigation', 'analytics')
                  }
                }}
              >
                <ListItemIcon>
                  {text === "Polls" ? (
                    <BallotIcon />
                  ) : text === "Organization" ? (
                    <Business />
                  ) : text === "Report View" ? (
                    <LeaderboardIcon />
                  ) : text === "Settings" ? (
                    <Settings />
                  ) : text === "Data View" ? (
                    <BackupTable />
                  ) : text === "Billing Page" ? (
                    <CreditCard />
                  ) : //   : text === "Profile" ? (
                  //     <AccountBox />
                  //   )
                  null}
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                />
              </ListItemButton>
            </motion.div>
          </ListItem>
        ))}
      </List>
      <List style={{ position: "absolute", left: 0, bottom: 0, width: "100%" }}>
        <ListItem disablePadding style={{ width: "100%" }}>
          <motion.div
            style={{ width: "100%" }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.15,
              delay: 0.15,
              ease: [0.64, 0.03, 0.36, 1],
            }}
            whileHover={{ scale: 0.95, backgroundColor: theme.secondary }}
          >
            <ListItemButton onClick={() => setSupportModalOpen(true)}>
              <ListItemIcon>
                <Support />
              </ListItemIcon>
              <ListItemText primary={"Help Center"} />
            </ListItemButton>
          </motion.div>
        </ListItem>

        <ListItem disablePadding style={{ width: "100%" }}>
          <motion.div
            style={{ width: "100%" }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.15,
              delay: 0.3,
              ease: [0.64, 0.03, 0.36, 1],
            }}
            whileHover={{ scale: 0.95, backgroundColor: theme.secondary }}
          >
            <ListItemButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleProductsClick}
            >
              <ListItemIcon>
                <Workspaces />
              </ListItemIcon>
              <ListItemText primary={"All Products"} />
            </ListItemButton>
          </motion.div>
        </ListItem>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={productsOpen}
          onClose={handleCloseProducts}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem disabled>
            <Typography variant="caption" style={{ fontWeight: "bold" }}>
              More From CUTI App
            </Typography>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => handleCloseProducts("bizzybook")}>
            <Avatar
              src={bizzyBookLogo}
              style={{ height: 32, width: 32, marginRight: 10 }}
            />{" "}
            BizzyBook
          </MenuItem>
          <MenuItem onClick={() => handleCloseProducts("bzzzrrrt")}>
            {" "}
            <Avatar
              src={bzrtLogo}
              style={{ height: 32, width: 32, marginRight: 10 }}
            />{" "}
            BZZZRRRT
          </MenuItem>
        </Menu>
        <Divider />
        <ListItem disablePadding style={{ width: "100%" }}>
          <motion.div
            style={{ width: "100%" }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.15,
              delay: 0.45,
              ease: [0.64, 0.03, 0.36, 1],
            }}
            whileHover={{ scale: 0.95, backgroundColor: theme.secondary }}
          >
            <ListItemButton onClick={() => console.log("click")}>
              <Avatar sx={{ width: 32, height: 32, marginRight: 2 }} />
              <ListItemText primary={"Profile"} />
            </ListItemButton>
          </motion.div>
        </ListItem>
      </List>
      {userData?.admin && (
        <ListItem key={"admin"} disablePadding>
          <ListItemButton
            onClick={() => {
              navigate("/admin-dashboard");
            }}
          >
            <ListItemIcon>
              <AdminPanelSettings />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ style: { fontWeight: "bold" } }}
            >
              Admin Panel
            </ListItemText>
          </ListItemButton>
        </ListItem>
      )}
    </Box>
  );

  const closeDrawer = (event) => {
    event.stopPropagation();
    setDrawerOpen(false);
  };

  const getUserData = async (user) => {
    const userRef = db.collection("users").doc(user.uid);
    const snapshot = await userRef.get();
    if (snapshot.exists) {
      dispatch(setUserData(snapshot.data()));
    } else {
      dispatch(setUserData(null));
    }
  };

  const handleLogin = (email, password) => {
    console.log("Log in clicked");
    auth
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log("this is the user", user);
        getUserData(user);
      })
      .then(() => setOpen(false))
      .catch((error) => {
        if (error.code === "auth/wrong-password") {
          console.log(error.code);
          setMessage("It seems the password is wrong.");
        } else if (error.code === "auth/user-not-found") {
          console.log(error.code);
          setMessage("We can't find a user with that email address.");
        }
      });
  };

  const handleProductsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseProducts = (site) => {
    setAnchorEl(null);
    if (site === "bizzybook") {
      window.open("https://cuti-app-7c963.web.app", "_blank");
      // window.open("https://bizzybook.app", "_blank");
    } else if (site === "bzzzrrrt") {
      window.open("https://bzzzrrrt.com", "_blank");
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        getUserData(user);
      } else {
        dispatch(clearCurrentUser());
      }
    });
    return unsubscribe;
  }, [dispatch]);

  useEffect(() => {
    if (userData && userDataSets === null) {
      console.log("We are here");

      const getDatasetsAndPresets = async () => {
        await db
          .collection("users")
          .doc(userData.uid)
          .collection("DataSets")
          .get()
          .then((snapshot) => {
            if (snapshot.empty) {
              dispatch(setUserDataSets([]));
            } else {
              const dataSets = snapshot.docs.map((doc) => {
                let data = doc.data();
                return { ...data, id: doc.id };
              });
              dispatch(setUserDataSets(dataSets));
            }
          });

        await db
          .collection("users")
          .doc(userData.uid)
          .collection("Presets")
          .get()
          .then((snapshot) => {
            if (snapshot.empty) {
              dispatch(setUserPresets([]));
            } else {
              const presets = snapshot.docs.map((doc) => {
                let data = doc.data();
                return { ...data, id: doc.id };
              });
              dispatch(setUserPresets(presets));
            }
          });
      };

      getDatasetsAndPresets();
    }

    console.log("user presets from App", userDataSets);
  }, [userData, userDataSets]);

  return (
    <div className="App" style={{ height: "100dvh", position: "relative" }}>
      {/* <Grid
        item
        container
        xs={12}
        style={{ height: 60, backgroundColor: "lightgrey" }}
      ></Grid> */}
      <AppBar
        open={open}
        setOpen={setOpen}
        handleLogin={handleLogin}
        setSubModalOpen={setSubModalOpen}
        subModalOpen={subModalOpen}
        settingsModalOpen={settingsModalOpen}
        setSettingsModalOpen={setSettingsModalOpen}
        setDrawerOpen={setDrawerOpen}
      />
      <Routes>
        <Route
          path="/"
          element={
            <LandingPage
              open={open}
              setOpen={setOpen}
              navigate={navigate}
              handleLogin={handleLogin}
              getUserData={getUserData}
              userData={userData}
              subModalOpen={subModalOpen}
              setSubModalOpen={setSubModalOpen}
            />
          }
        />
        <Route
          path="/dashboard"
          element={
            <Dashboard
              navigate={navigate}
              userData={userData}
              subModalOpen={subModalOpen}
              setSubModalOpen={setSubModalOpen}
              open={open}
              setOpen={setOpen}
              settingsModalOpen={settingsModalOpen}
              setSettingsModalOpen={setSettingsModalOpen}
            />
          }
        />
        <Route path="/my-data" element={<MyData navigate={navigate} />} />
        <Route
          path="/organization"
          element={<Organization navigate={navigate} />}
        />
        <Route path="/billing" element={<BillingPage navigate={navigate} />} />
        <Route
          path="/settings"
          element={<SettingsView navigate={navigate} />}
        />
        <Route
          path="/profile"
          element={<ProfileSettings navigate={navigate} />}
        />
        <Route
          path="/claim-account/:claimAcctId"
          element={<ClaimAccount navigate={navigate} />}
        />
      </Routes>

      <Drawer
        anchor={"left"}
        open={!!drawerOpen}
        elevation={16}
        onClose={closeDrawer}
        onClick={() => {
          setDrawerOpen(false);
        }}
        sx={{
          color: theme.primary,
          "& .MuiDrawer-paper": {
            backgroundColor: "white",
          },
        }}
      >
        {list("left")}
      </Drawer>

      <AuthModal
        open={open}
        setOpen={setOpen}
        userData={userData}
        navigate={navigate}
        handleLogin={handleLogin}
        getUserData={getUserData}
      />

      <SupportForm
        supportModalOpen={supportModalOpen}
        setSupportModalOpen={setSupportModalOpen}
      />
    </div>
  );
}

export default App;
