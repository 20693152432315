import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Grid,
  Container,
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  FormGroup,
  FormControlLabel,
  Link,
  IconButton,
  CircularProgress,
  Checkbox,
  Select,
  MenuItem,
  InputAdornment,
  Chip,
  InputLabel,
  Tooltip,
  Snackbar,
  Alert,
  Avatar,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Import the checkmark icon from Material-UI
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { DataGrid, GridCellEditStopReasons } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import {
  Add,
  Article,
  ArticleOutlined,
  Category,
  Check,
  Close,
  Delete,
  Edit,
  FilePresentRounded,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Save,
  SaveAlt,
  Upload,
  UploadFile,
} from "@mui/icons-material";
import { theme as defaultTheme } from "../global/theme";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import firebase, { db, functions, storage } from "../config/firebaseConfig";
import { setUserData } from "../global/authActions";

const Organization = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.userData);
  const theme = userData ? userData.theme : defaultTheme;
  const [open, setOpen] = useState(false);
  const [org, setOrg] = useState(null);
  const [orgName, setOrgName] = useState(userData ? userData.org[0].name : "");
  const [isOrgAdmin, setIsOrgAdmin] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [nameInput, setNameInput] = useState("");
  const [role, setRole] = useState("Member");

  const handleModalOpen = () => {
    setOpen(!open);
    setEmailInput("");
    setNameInput("");
    setRole("Member");
  };

  const handleAddMember = () => {
    console.log("ADD MEMBER");
    db.collection("claimAccounts")
      .add({
        claimed: false,
        name: nameInput,
        email: emailInput,
        org: org,
        role,
        adminName: userData.name,
      })
      .then(() => {
        // toast.success("Member added successfully");
        setEmailInput("");
        setNameInput("");
        setRole("Member");
        // setOpen(false);
      });
  };

  const updateCompanyName = () => {
    const orgId = org.id;
    db.collection("organizations")
      .doc(orgId)
      .update({
        companyName: orgName,
      })
      .then(async () => {
        //update every liscenseHolders Org name
        await org.licenseHolders.forEach(async (member) => {
          let updatedOrgs = await db
            .collection("users")
            .doc(member.userId)
            .get()
            .then((doc) => {
              let matchingOrg = doc.data().org.find((org) => org.uid === orgId);
              let updated = { ...matchingOrg, name: orgName };
              let otherOrgs = doc.data().org.filter((org) => org.uid !== orgId);
              return [...otherOrgs, updated];
            });
          if (updatedOrgs) {
            await db.collection("users").doc(member.userId).update({
              org: updatedOrgs,
            });
          }
        });

        await db
          .collection("users")
          .doc(userData.uid)
          .get()
          .then((doc) => {
            dispatch(setUserData({ ...doc.data() }));
          });

        console.log("Company name updated successfully");
      });
  };

  //! USEEFFECTS
  // * Get the organization data from the database
  useEffect(() => {
    if (userData && !org) {
      console.log("USER DATA, ", userData);
      db.collection("organizations")
        .doc(userData?.org[0]?.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log("ORG, ", doc.data());
            let data = doc.data();
            setOrg({ ...data, id: doc.id });
            setOrgName(data.companyName);
            if (data.admin.find((admin) => admin.userId === userData.uid)) {
              setIsOrgAdmin(true);
            }
          }
        });
    }

    console.log("ORG, ", org);
  }, [userData, org]);

  useEffect(() => {
    console.log("DEBUGGER:", userData);
  }, [userData]);

  return (
    <>
      {org ? (
        <Grid
          item
          container
          xs={12}
          //   style={{ padding: 20 }}
          sx={{ padding: { xs: 0, md: "1% 10%" } }}
        >
          <Typography
            variant="h5"
            style={{
              width: "100%",
              fontWeight: "bold",
              borderBottom: "1px solid #c6c6c6",
              textAlign: "left",
              paddingLeft: "2%",
            }}
          >
            Organization
          </Typography>

          {org?.admin[0]?.userId === userData.uid && (
            <Grid item container xs={12} style={{ padding: "20px" }}>
              <Grid item container xs={12} md={6} style={{}}>
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "bold",
                    fontWeight: "bold",
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Details
                </Typography>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontWeight: "bold",
                    width: "100%",
                    textAlign: "left",
                    marginTop: 10,
                  }}
                >
                  Comany Name
                </Typography>
                <Typography
                  variant="caption"
                  style={{ width: "100%", textAlign: "left" }}
                >
                  Human-friendly label for your organization, shown in the user
                  interface
                </Typography>
                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    padding: "10px 0px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    variant="outlined"
                    fullWidth
                    style={{ width: "80%" }}
                    value={orgName || ""}
                    onChange={(e) => {
                      setOrgName(e.target.value);
                    }}
                  />
                  <div>
                    {orgName?.trim() !== org?.companyName.trim() && (
                      <IconButton
                        onClick={() => {
                          setOrgName(org.companyName);
                        }}
                        style={{
                          backgroundColor: `${theme.danger}35`,
                          color: theme.danger,
                          marginRight: 5,
                        }}
                      >
                        <Close />
                      </IconButton>
                    )}
                    <IconButton
                      onClick={updateCompanyName}
                      disabled={
                        orgName?.trim() === org.companyName?.trim()
                          ? true
                          : false
                      }
                      style={{
                        backgroundColor: theme.primary,
                        color: theme.white,
                        opacity:
                          orgName?.trim() === org?.companyName.trim() ? 0.5 : 1,
                      }}
                    >
                      <Check />
                    </IconButton>
                  </div>
                </Grid>

                <Typography
                  style={{
                    fontWeight: "bold",
                    fontWeight: "bold",
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Organization ID
                </Typography>
                <Typography
                  variant="caption"
                  style={{ width: "100%", textAlign: "left" }}
                >
                  Used to verify membership of your organization
                </Typography>

                <TextField
                  variant="outlined"
                  fullWidth
                  style={{ width: "80%" }}
                  value={org.id}
                  disabled
                />
              </Grid>
            </Grid>
          )}

          <Grid item container xs={12} style={{ padding: "20px" }}>
            <Grid
              item
              container
              xs={12}
              style={{
                justifyContent: "space-between",
                alignItems: "baseline",
                borderBottom: "1px solid #c6c6c6",
                paddingBottom: 10,
              }}
            >
              <Typography
                align="left"
                variant="h6"
                style={{ fontWeight: "bold" }}
              >
                Members
              </Typography>

              {isOrgAdmin && (
                <Button
                  style={{ background: theme.secondary, color: theme.primary }}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <Add style={{ marginRight: 5 }} />
                  Add Member
                </Button>
              )}
            </Grid>
            <Typography
              variant="caption"
              align="left"
              style={{
                width: "100%",
                marginBottom: 20,
                color:
                  org.availableLicenses > org.licenseHolders.length
                    ? "grey"
                    : theme.danger,
              }}
            >
              ({org.availableLicenses - org.licenseHolders.length}) Available
              licenses
            </Typography>

            {org.licenseHolders.map((member) => {
              const { name, userId, email } = member;
              const adminUser = org.admin.find(
                (user) => user.userId === userId
              );
              const isMe = userId === userData.uid;
              return (
                <motion.div
                  key={userId}
                  whileHover={{
                    backgroundColor: `${theme.accentOne}35`,
                  }}
                  item
                  container
                  xs={12}
                  style={{
                    padding: "10px",
                    border: "1px solid #c6c6c6",
                    borderRadius: 5,
                    marginBottom: 10,
                    width: "100%",
                    display: "flex",
                    flexFlow: "row",
                  }}
                >
                  <Grid item container xs={9} style={{}}>
                    <Grid item container xs={3} md={2} xl={1}>
                      <Avatar src={member.photoURL} />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={9}
                      md={10}
                      xl={11}
                      style={{ flexFlow: "column", textAlign: "left" }}
                    >
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold" }}
                      >
                        {name}
                        {isMe && (
                          <span
                            style={{
                              marginLeft: "2%",
                              padding: "4px",
                              borderRadius: 5,
                              background: theme.secondary,
                              color: theme.primary,
                            }}
                          >
                            You
                          </span>
                        )}
                        {!isMe && adminUser ? "(Admin)" : ""}
                      </Typography>
                      <Typography variant="caption" style={{}}>
                        {email}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={3}
                    style={{ justifyContent: "flex-end" }}
                  >
                    {isMe && (
                      <Button
                        style={{
                          background: theme.secondary,
                          color: theme.primary,
                        }}
                        onClick={() => console.log("clicked leave")}
                      >
                        Leave
                      </Button>
                    )}
                    {isOrgAdmin && !isMe && (
                      <Button
                        style={{
                          background: `${theme.danger}35`,
                          color: theme.danger,
                          marginLeft: 5,
                        }}
                        onClick={() => console.log("clicked remove")}
                      >
                        Remove
                      </Button>
                    )}
                  </Grid>
                </motion.div>
              );
            })}
          </Grid>

          <Modal open={!!open} onClose={() => handleModalOpen()}>
            <Grid
              item
              container
              xs={11}
              md={6}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: 20,
                background: "white",
                borderRadius: 10,
                outline: "none",
                textAlign: "left",
                justifyContent: "flex-end",
              }}
            >
              <Grid item container xs={12} style={{ position: "relative" }}>
                <IconButton
                  style={{
                    position: "absolute",
                    right: -25,
                    top: -25,
                    background: theme.secondary,
                    color: theme.accentOne,
                  }}
                  onClick={() => handleModalOpen()}
                >
                  <Close />
                </IconButton>

                <Typography
                  variant="h6"
                  align="center"
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  Add Member
                </Typography>
                <Grid item container xs={12} style={{ marginBottom: 20 }}>
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    Email
                  </Typography>
                  <TextField
                    variant="outlined"
                    value={emailInput}
                    onChange={(e) => setEmailInput(e.target.value)}
                    fullWidth
                  />

                  <Typography
                    variant="body2"
                    style={{ fontWeight: "bold", marginTop: 10 }}
                  >
                    Full Name <span style={{ color: "grey" }}> (optional)</span>
                  </Typography>
                  <TextField
                    variant="outlined"
                    value={nameInput}
                    onChange={(e) => setNameInput(e.target.value)}
                    fullWidth
                  />

                  <Typography
                    variant="body2"
                    style={{ fontWeight: "bold", marginTop: 10 }}
                  >
                    Role
                  </Typography>
                  <Select
                    variant="outlined"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    fullWidth
                  >
                    {["Member", "Admin"].map((x) => {
                      return (
                        <MenuItem key={x} value={x}>
                          {x}
                        </MenuItem>
                      );
                    })}
                  </Select>

                  <Button
                    disabled={!emailInput || !nameInput}
                    onClick={handleAddMember}
                    style={{
                      background: theme.primary,
                      color: "white",
                      marginTop: 10,
                      opacity: !emailInput || !nameInput ? 0.5 : 1,
                    }}
                  >
                    Add User to Team
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Modal>
        </Grid>
      ) : (
        <Grid
          item
          container
          xs={12}
          style={{
            height: "calc(100vh - 60px)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: theme.primary }} />
        </Grid>
      )}
    </>
  );
};

export default Organization;
